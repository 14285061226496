import axios from "axios"
import AxiosInstance from "./../utils/AxiosInstance"
import { getActualizarCatalogoUrl, getCatalogosFilterUrl, getCatalogosPorIdUrl, getCatalogosPorPadreUrl, getCatalogosUrl, getEliminarCatalogoUrl, getInsertarCatalogoUrl } from './../utils/urls'
import { getCulture } from "../utils/Internationalization"

export default class CatalogoService {
    getPaises = () => this.getPorPadre({ catalogo: { catValor: "CAT_PAISES" } })
    getModalidad = () => this.getPorPadre({ catalogo: { catValor: "CAT_MODALIDAD" } })
    getCursos = () => this.getPorPadre({ catalogo: { catValor: "CAT_CURSOS" } })
    getGeneros = () => this.getPorPadre({ catalogo: { catValor: "CAT_SEXO" } })
    getTrainingStatus = () => this.getPorPadre({ catalogo: { catValor: "CAT_EST_ENT" } })
    getReportType = () => this.getPorPadre({ catalogo: { catValor: "CAT_TIP_INF" } })
    getReportStatus = () => this.getPorPadre({ catalogo: { catValor: "CAT_EST_INF" } })
    getStates = () => this.getPorPadre({ catalogo: { catValor: "CAT_EST_REG" } })
    getEducationLevel = () => this.getPorPadre({ catalogo: { catValor: "CAT_EDU_LEV" } })
    getTypePerson = () => this.getPorPadre({ catalogo: { catValor: "CAT_TYP_PER" } })
    getRegion = () => this.getPorPadre({ catalogo: { catValor: "CAT_REGIONS" } })
    gets = (props) => {
        const { navigate } = props
        return AxiosInstance(navigate).get(getCatalogosUrl())
    }
    getsFiltro = (props) => {
        const { navigate, filtros } = props
        return AxiosInstance(navigate).post(getCatalogosFilterUrl(), { ...filtros })
    }
    getPorPadre = (props) => {
        const { catalogo } = props
        const axiosInstance = axios.create({ headers: { "Accept-Language": getCulture() } })
        return axiosInstance.post(getCatalogosPorPadreUrl(), catalogo)
    }
    getCatalogo = (props) => {
        const { catalogo } = props
        const axiosInstance = axios.create({ headers: { "Accept-Language": getCulture() } })
        return axiosInstance.post(getCatalogosPorPadreUrl(), catalogo)
    }
    get = (props) => {
        const { navigate, catalogo } = props
        return AxiosInstance(navigate).post(getCatalogosPorIdUrl(), catalogo)
    }
    set = (props) => {
        const { navigate, catalogo } = props
        return AxiosInstance(navigate).post(catalogo.catId !== undefined && catalogo.catId !== null && catalogo.catId !== '' ? getActualizarCatalogoUrl() : getInsertarCatalogoUrl(), catalogo)
    }
    del = (props) => {
        const { navigate, catalogo } = props
        return AxiosInstance(navigate).post(getEliminarCatalogoUrl(), catalogo)
    }
}