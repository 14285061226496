import axios from "axios"
import { getAutenticarUrl, getAutorizarUrl, updatePassword, recoveryPassword, validateUserUuid } from "../utils/urls"
import { getCulture } from "../utils/Internationalization"

export default class CuentaService {
    autenticar = (props) => {
        const { credenciales } = props
        return axios.post(getAutenticarUrl(), credenciales, { headers: { "Accept-Language": getCulture() } })
    }
    autorizar = (props) => {
        const { credenciales } = props
        return axios.post(getAutorizarUrl(), credenciales, { headers: { "Accept-Language": getCulture() } })
    }
    validateResetPassword = ({ datos }) => (axios.post(validateUserUuid(), datos, { headers: { "Accept-Language": getCulture() } }))
    recoveryPassword = (props) => {
        const { datos } = props
        return axios.post(recoveryPassword(), datos, { headers: { "Accept-Language": getCulture() } })
    }
    updatePassword = (props) => {
        const { datos } = props
        return axios.patch(updatePassword(), datos, { headers: { "Accept-Language": getCulture() } })
    }
}