import AxiosInstance from './../utils/AxiosInstance'
import { getPerfilesUrl, getActualizarPerfilUrl, getInsertarPerfilUrl, getEliminarPerfilUrl, getPerfilPorIdUrl } from './../utils/urls'
export default class PerfilService {
    gets = (props) => {
        const { navigate } = props
        return AxiosInstance(navigate).get(getPerfilesUrl())
    }
    get = (props) => {
        const { navigate, perfil } = props
        return AxiosInstance(navigate).post(getPerfilPorIdUrl(), perfil)
    }
    set = (props) => {
        const { navigate, perfil } = props
        return AxiosInstance(navigate).post(perfil.perId !== undefined && perfil.perId !== null && perfil.perId !== '' ? getActualizarPerfilUrl() : getInsertarPerfilUrl(), perfil)
    }
    del = (props) => {
        const { navigate, perfil } = props
        return AxiosInstance(navigate).post(getEliminarPerfilUrl(), perfil)
    }
}