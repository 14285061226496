import React, { useState } from 'react'
import { Button, Form, Input, Space, Typography } from 'antd'
import PasswordChecklist from 'react-password-checklist'
import toast from 'react-hot-toast'
import md5 from 'md5'

import { getLabel, labels } from '../../utils/Internationalization'

import UsuarioService from '../../service/UsuarioService'
const usuarioService = new UsuarioService()

const { Paragraph, Text } = Typography

const RestorePasswordUser = ({ user, navigate }) => {

    const [passwordNew, setPasswordNew] = useState('')
    const [passwordNewConfirm, setPasswordNewConfirm] = useState('')
    const [isReseting, setIsReseting] = useState(false)
    const [validForm, setValidForm] = useState(false)

    const resetPassword = async (values) => {
        const usuario = {
            usuId: values.usuId,
            oldpassword: md5(values.password),
            password: md5(values.passwordNew)
        }
        setIsReseting(true)
        try {
            const { data } = await usuarioService.resetPassword({ navigate, usuario })
            if (data)
                toast.success(getLabel(labels.passwordResetSuccessfully))
            setTimeout(() => {
                localStorage.removeItem("mgeToken")
                localStorage.removeItem("mgeDatosUsuario")
                localStorage.removeItem("mgeTrainings")
                localStorage.removeItem("mgeInformList")
                navigate.push("/login")
                setIsReseting(false)
            }, 3000)
        } catch (error) {
            setIsReseting(false)
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        }
    }

    const cancelResetPassword = () => {
        window.history.go(-1)
    }

    return (
        <>
            <Typography>
                <Paragraph>
                    <Text strong>
                        {user.nombres} {user.apellidos}
                    </Text>
                    <Text type='secondary'>
                        <br />{user.email}
                    </Text>
                </Paragraph>
            </Typography>
            <Form
                layout={'vertical'}
                initialValues={
                    { ...user }
                }
                onFinish={resetPassword}
                disabled={isReseting}
            >
                <Form.Item
                    style={{ display: 'none' }}
                    name='usuId'
                    label={getLabel(labels.idNumber)}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='password'
                    label={getLabel(labels.password)}
                    rules={[
                        {
                            required: true,
                            message: getLabel(labels.completePassword)
                        }
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name='passwordNew'
                    label={getLabel(labels.newPassword)}
                    rules={[
                        {
                            required: true,
                            message: getLabel(labels.completeNewPassword)
                        }
                    ]}
                >
                    <Input.Password onChange={e => setPasswordNew(e.target.value)} />
                </Form.Item>
                <Form.Item
                    name='passwordNewConfirm'
                    label={getLabel(labels.confirmNewPassword)}
                    rules={[
                        {
                            required: true,
                            message: getLabel(labels.confirmNewPassword)
                        }
                    ]}
                >
                    <Input.Password onChange={e => setPasswordNewConfirm(e.target.value)} />
                </Form.Item>
                <PasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital", "match"]}
                    minLength={8}
                    value={passwordNew}
                    valueAgain={passwordNewConfirm}
                    messages={{
                        minLength: getLabel(labels.passwordHasMore8Characters),
                        specialChar: getLabel(labels.passwordHasSpecialCharacters),
                        number: getLabel(labels.passwordHasNumber),
                        capital: getLabel(labels.passwordHasCapitalLetter),
                        match: getLabel(labels.passwordsMatch)
                    }}
                    onChange={(isValid) => { setValidForm(isValid) }}
                    style={{
                        padding: '0 0 20px 0',
                        display: validForm ? 'none' : 'block'
                    }}
                />
                <Space>
                    <Button onClick={cancelResetPassword}
                        disabled={isReseting}
                    >{getLabel(labels.cancel)}</Button>
                    <Button type="primary"
                        htmlType='submit'
                        disabled={isReseting}
                        loading={isReseting}>
                        {
                            isReseting ? getLabel(labels.resetting) : getLabel(labels.resetPassword)
                        }
                    </Button>
                </Space>
            </Form >
        </>
    )
}

export default RestorePasswordUser