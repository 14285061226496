import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Col, Row, Typography } from 'antd'

import RestorePasswordUser from '../components/usuario/RestorePasswordUser'
import AlertWait from './AlertWait'
import UsuarioService from '../service/UsuarioService'

import { getDatosUsuario } from '../utils/Utils'
import { getLabel, labels } from '../utils/Internationalization'

const usuarioService = new UsuarioService()

const { Title } = Typography

const ResetPasswordUser = () => {
    const id = getDatosUsuario() && getDatosUsuario().usuarioId
    const navigate = useHistory()
    const [user, setUser] = useState(null)
    const [load, setLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const fetchUser = async () => {
        setLoad(false)
        setIsLoading(true)
        try {
            const { data } = await usuarioService.get({ navigate, usuario: { usuId: id } })
            const usuario = data.listObject[0]
            setUser(usuario)
        }
        catch (error) {

        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (load) {
            fetchUser()
        }
    })

    return (
        <Row justify='center' gutter={[15, 20]}>
            <Col span={24}>
                <div className='mgeCard' style={{ padding: '15px 20px 10px 20px' }}>
                    <Typography>
                        <Title level={3}>
                            <span className='mge-darkGreen'>
                                {
                                    getLabel(labels.resetPassword).split(' ').map(l => `${l.substring(0, 1).toUpperCase()}${l.substring(1)}`).join(' ')
                                }
                            </span>
                        </Title>
                    </Typography>
                </div>
            </Col>
            <Col xs={22} sm={18} md={16} lg={14} xl={10} xxl={8}>
                {
                    isLoading && <AlertWait isLoading={isLoading} message={getLabel(labels.pleaseWait)} />
                }
                {
                    user && <RestorePasswordUser user={user} navigate={navigate}></RestorePasswordUser>
                }
            </Col>
        </Row>
    )


}

export default ResetPasswordUser