import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Input, Form, Button, Row, Col, Card, Image, Typography, Divider } from 'antd'
import AlertWait from './AlertWait'
import md5 from 'md5'
import CuentaService from '../service/CuentaService'
import CatalogoService from '../service/CatalogoService'

import './main.css'
import { getDatosUsuario } from '../utils/Utils'
import { getCulture, getLabel, labels, setCulture } from '../utils/Internationalization'

const cuentaService = new CuentaService()
const catalogoService = new CatalogoService()

const Login = () => {

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)
    const [datosUsuario, setDatosUsuario] = useState(null)
    const [showPerfiles, setShowPerfiles] = useState(false)
    const [form] = Form.useForm()

    const autorizar = (credenciales) => {
        setError("")
        setIsLoading(true)
        cuentaService.autorizar({ credenciales })
            .then((response) => {
                setIsLoading(false)
                const { data, headers } = response
                const { authorization } = headers
                localStorage.setItem(
                    'mgeToken',
                    authorization.replace("Bearer", "").trim()
                )
                const visible = true
                const { opciones } = data.listObject[0]
                const opcionesVisible = opciones.map(o => (o.actionOpcion.indexOf('/beneficiaries/') >= 0 ? { ...o, visible: false } : { ...o, visible }))
                localStorage.setItem(
                    'mgeDatosUsuario',
                    JSON.stringify({ ...getDatosUsuario(), opciones: opcionesVisible })
                )
                // Get Catalogos
                const requests = []
                requests.push(catalogoService.getPaises())
                requests.push(catalogoService.getCursos())
                requests.push(catalogoService.getModalidad())
                requests.push(catalogoService.getGeneros())
                requests.push(catalogoService.getTrainingStatus())
                requests.push(catalogoService.getReportType())
                requests.push(catalogoService.getReportStatus())
                requests.push(catalogoService.getStates())
                requests.push(catalogoService.getEducationLevel())
                requests.push(catalogoService.getTypePerson())
                requests.push(catalogoService.getRegion())
                let mgeCatalogos = []
                Promise.all(requests)
                    .then((results) => {
                        if (results.length > 0) {
                            mgeCatalogos =
                            {
                                paises: results[0].data.listObject,
                                cursos: results[1].data.listObject,
                                modalidades: results[2].data.listObject,
                                generos: results[3].data.listObject,
                                trainingStatus: results[4].data.listObject,
                                reportType: results[5].data.listObject,
                                reportStatus: results[6].data.listObject,
                                states: results[7].data.listObject,
                                educationLevels: results[8].data.listObject,
                                typePersons: results[9].data.listObject,
                                regions: results[10].data.listObject
                            }
                        }
                        localStorage.setItem("mgeCatalogos", JSON.stringify(mgeCatalogos))
                    })
                    .catch(error => {
                        const { response } = error
                        const msgError = response.status === 409 ? response.data[0].messageText : response.data
                        setError(msgError)
                    })
                history.push(getDatosUsuario().opciones[0].actionOpcion || '/')
            })
            .catch((error) => {
                setIsLoading(false)
                const { response } = error
                if (response.status === 500) {
                    setError(response.statusText)
                } else if (response.status === 404) {
                    setError(getLabel(labels.resourceNotAvailable))
                } else {
                    setError(response.data[0].messageText)
                }
            })
    }

    const login = (values) => {
        setError("")
        const credenciales = { ...values, password: md5(values.password) }
        setIsLoading(true)
        cuentaService
            .autenticar({ credenciales })
            .then((response) => {
                setIsLoading(false)
                const { data } = response
                const usuario = data.listObject[0]
                localStorage.setItem(
                    'mgeDatosUsuario',
                    JSON.stringify(usuario)
                )
                setDatosUsuario(usuario)
                if (localStorage.getItem('mgeCulture') === null) setCulture({ culture: usuario.cultura || 'en_US' })
                if (usuario.perfiles && usuario.perfiles.length > 0) {
                    if (usuario.perfiles.length === 1) {
                        const credenciales = { usuId: usuario.usuarioId, perId: usuario.perfiles[0].perId }
                        autorizar(credenciales)
                    }
                    else if (usuario.perfiles.length > 1) {
                        setShowPerfiles(true)
                    }
                }
            })
            .catch((error) => {
                setIsLoading(false)
                const { response } = error
                if (response.status === 500) {
                    setError(response.statusText)
                } else if (response.status === 404) {
                    setError(getLabel(labels.resourceNotAvailable))
                } else {
                    setError(response.data[0].messageText)
                }
            })
    }

    const recuperarPassword = () => {
        history.push('/recover')
    }

    const renderPerfil = (p) => {
        const { perId, perNombre } = p
        return (
            <Col key={perId} xs={12} sm={12} md={12} lg={12} xl={12}>
                <Card
                    hoverable
                    onClick={() => autorizar({ usuId: datosUsuario.usuarioId, perId })}
                    actions={[
                        <Typography.Title level={5} style={{ color: "#333" }}>
                            {getLabel(labels.login)}
                        </Typography.Title>
                    ]
                    }
                    style={
                        {
                            backgroundColor: '#F0F0F0',
                        }
                    }
                >
                    <Row justify='center'>
                        <Col>
                            {/* <Image src={"./assets/" + perNombre + "_1.png"} preview={false} /> */}
                            <Typography.Title level={5} style={{ color: "#333" }}>
                                {perNombre}
                            </Typography.Title>
                        </Col>
                    </Row>
                </Card>
            </Col>
        )
    }

    return (
        <>
            <div className="wrap-login100">
                <div className="login100-form validate-form">
                    <Row>
                        <Col span={24} style={{ textAlign: 'center', padding: '0 20px 50px 20px' }}>
                            <Image alt='' src={`./../assets/logo_${getCulture()}.png`} preview={false} />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center', padding: '0 20px 20px 20px' }}>
                            <Typography.Title level={2}>
                                <span className="mge-darkGreen">
                                    {getLabel(labels.welcomeBack)}
                                </span>
                            </Typography.Title>
                            <Typography.Title level={4}>
                                <span className="mge-green">
                                    {getLabel(labels.loginAccess)}
                                </span>
                            </Typography.Title>
                        </Col>
                    </Row>
                    {
                        datosUsuario && datosUsuario.perfiles.length > 0 && showPerfiles ?
                            <Row gutter={[10, 10]}>
                                <Col span={24}>
                                    <Divider><span style={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
                                        {
                                            getLabel(labels.loginAs)
                                        }
                                    </span></Divider>
                                </Col>
                                {
                                    datosUsuario.perfiles.length > 0 && datosUsuario.perfiles.map(p => renderPerfil(p))
                                }
                            </Row>
                            :
                            <Row>
                                <Col span={24}>
                                    <Form
                                        layout={'vertical'}
                                        form={form}
                                        onFinish={login}
                                    >
                                        <Form.Item label={getLabel(labels.emailLogin)}
                                            name='email'
                                            rules={[
                                                {
                                                    type: 'email',
                                                    message: getLabel(labels.emailNotValid)
                                                },
                                                {
                                                    required: true,
                                                    message: getLabel(labels.enterEmail),
                                                },
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Form.Item label={getLabel(labels.passwordLogin)}
                                            name='password'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: getLabel(labels.enterPassword)
                                                }
                                            ]}>
                                            <Input.Password />
                                        </Form.Item>
                                        <Form.Item>
                                            <Button style={{ backgroundColor: '#385A28', borderColor: '#385A28' }} type='primary' htmlType='submit' loading={isLoading} disabled={isLoading} block>
                                                {getLabel(labels.login)}
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </Col>
                            </Row>
                    }
                    <AlertWait error={error} isLoading={isLoading} />
                    <Row justify='center' style={{ paddingTop: '15px' }}>
                        <Col>
                            <Button type='link' onClick={recuperarPassword}>
                                {
                                    getLabel(labels.forgotPassword)
                                }
                            </Button>
                        </Col>
                    </Row>
                </div>


                <div className="login100-more" style={
                    {
                        backgroundImage: 'url(./assets/fondoLogin.png)',
                        backgroundColor: '#70B450'
                    }}>
                </div>
            </div>
        </>
    )
}

export default Login