import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Typography, Button, Card, Statistic, Table, Form, Spin, Tooltip, Tag, Popconfirm } from 'antd'
import * as anticons from '@ant-design/icons'

import * as apiURLs from '../../utils/urls'
import { getCatalogos, getToken, getTypeContent } from '../../utils/Utils'
import { getCulture, getLabel, labels } from '../../utils/Internationalization'
import $ from 'jquery'
import { saveAs } from 'file-saver'

import UsuarioService from '../../service/UsuarioService'
import toast from 'react-hot-toast'
import AlertWait from '../../pages/AlertWait'

const usuarioService = new UsuarioService()

const tableProps = {
    bordered: false,
    loading: false,
    title: '',
    showHeader: true,
    size: 'medium',
    pagination: {
        position: ['none', 'bottomRight']
    }
}

const estilos = [
    { background: '#54873C' },
    { background: '#70B450' },
    { background: '#385A28' },
    { background: '#636466' },
    { background: '#70B450' },
    { background: '#54873C' },
    { background: '#385A28' },
]

const { Paragraph, Text } = Typography

const Dashboard = () => {

    const navigate = useHistory()
    const [load, setLoad] = useState(true)
    const [loadTotals, setLoadTotals] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [isLoadingTotals, setIsLoadingTotals] = useState(false)
    const [totals, setTotals] = useState(null)
    const [nombres, setNombres] = useState([])
    const [apellidos, setApellidos] = useState([])
    const [paises, setPaises] = useState([])
    const [estados, setEstados] = useState([])
    const [perfiles, setPerfiles] = useState([])
    const [selectedProfiles, setSelectedProfiles] = useState([])
    const [currentDataSource, setCurrentDataSource] = useState(null)
    const [isDownloading, setIsDownloading] = useState(false)

    const columnas = [
        {
            title: '',
            render: (_, r) => (r.imagen && <img alt='' src={`/images/${r.imagen.split('/')[r.imagen.split('/').length - 1]}`} width={32} height={32} />) || <img alt='' src='./assets/user.png' width={32} height={32} />
        },
        {
            title: getLabel(labels.firstName),
            dataIndex: 'nombres',
            filters: nombres.map(n => ({
                value: n,
                text: n
            })),
            filterMode: 'tree',
            onFilter: (value, { nombres }) => nombres === value,
            render: (_, r) => `${r.nombres}`
        },
        {
            title: getLabel(labels.lastName),
            dataIndex: 'apellidos',
            filters: apellidos.map(a => ({
                value: a,
                text: a
            })),
            filterMode: 'tree',
            onFilter: (value, { apellidos }) => apellidos === value,
            render: (_, r) => `${r.apellidos}`
        },
        {
            title: getLabel(labels.country),
            dataIndex: 'pais',
            filters: paises.map(p => ({
                value: p.catId,
                text: <><img
                    src={`https://flagcdn.com/32x24/${p.catDescripcion.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/32x24/${p.catDescripcion.toLowerCase()}.png 2x,https://flagcdn.com/96x72/${p.catDescripcion.toLowerCase()}.png 3x`}
                    width="32"
                    height="24"
                    alt={p.catDescripcion} />&nbsp;{p.catNombre}</>
            })),
            filterMode: 'tree',
            onFilter: (value, { pais }) => pais.catId === value,
            render: (_, { pais }) => <div style={{ textAlign: 'center' }}>
                <Tooltip title={pais.catDescripcion}>
                    <img
                        src={`https://flagcdn.com/32x24/${pais.catDescripcion.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/32x24/${pais.catDescripcion.toLowerCase()}.png 2x,https://flagcdn.com/96x72/${pais.catDescripcion.toLowerCase()}.png 3x`}
                        width="32"
                        height="24"
                        alt={pais.catDescripcion}></img>
                </Tooltip>
            </div>,
            responsive: ['md', 'lg', 'xl', 'xxl']
        },
        {
            title: <div style={{ textAlign: 'center' }}>
                {getLabel(labels.state)}
            </div>,
            dataIndex: 'estado',
            filters: estados.map(p => ({
                value: p.catId,
                text: p.catNombre
            })),
            filterMode: 'tree',
            onFilter: (value, { estado }) => estado.catId === value,
            render: (_, { estado }) => <div style={{ textAlign: 'center' }
            }>
                {estado.catNombre}
            </div >,
            responsive: ['md', 'lg', 'xl', 'xxl']
        },
        {
            title: <div style={{ textAlign: 'center' }}>
                {getLabel(labels.profile)}
            </div>,
            dataIndex: 'role',
            filters: perfiles.map(p => ({
                value: p.perId,
                text: p.perNombre
            })),
            filterMode: 'tree',
            onFilter: (value, { perfiles }) => perfiles[perfiles.length - 1].perId === value,
            render: (_, { perfiles }) => <div style={{ textAlign: 'center' }
            }>
                {perfiles[perfiles.length - 1].perNombre}
            </div >,
            responsive: ['md', 'lg', 'xl', 'xxl']
        },
        {
            title: getLabel(labels.actions),
            render: (_, record) => {
                console.log(record)
                return <Button type='primary' href={`/#/user/${record.usuId}/${record.perfiles[record.perfiles.length - 1].perId}`}>
                    {getLabel(labels.view)}
                </Button>
            }
        }
    ]

    const setProfile = (profile) => {
        let profiles = []
        if (selectedProfiles.filter(p => p.perId === profile.perId).length < 1)
            profiles = [...selectedProfiles, profile]
        else
            profiles = selectedProfiles.filter(p => p.perId !== profile.perId)
        setSelectedProfiles(profiles)
        const requests = []
        profiles.forEach(profile => {
            requests.push(usuarioService.getUsersListByProfile({ navigate, profile }))
        })
        if (requests.length > 0) {
            setIsLoading(true)
            setCurrentDataSource(null)
            setUsers([])
            setPerfiles([])
            Promise.all(requests)
                .then((results) => {
                    setIsLoading(false)
                    if (results.length > 0) {
                        let n = []
                        let a = []
                        let c = []
                        let s = []
                        let p = []
                        let u = []
                        results.forEach(r => {
                            const { config } = r
                            const profile = JSON.parse(config.data)
                            const perfiles = [profile]
                            const usuarios = r.data.listObject.map(u => ({ ...u, perfiles }))
                            usuarios.forEach(u => {
                                if (n.filter(p => p === u.nombres).length < 1)
                                    n = [...n, u.nombres]
                            })
                            usuarios.forEach(u => {
                                if (a.filter(p => p === u.apellidos).length < 1)
                                    a = [...a, u.apellidos]
                            })
                            usuarios.forEach(u => {
                                if (c.filter(p => p.catId === u.pais.catId).length < 1)
                                    c = [...c, u.pais]
                            })
                            usuarios.forEach(u => {
                                if (s.filter(s => s.catId === u.estado.catId).length < 1)
                                    s = [...s, u.estado]
                            })
                            usuarios.forEach(u => {
                                if (p.filter(p => p.perId === u.perfiles[u.perfiles.length - 1].perId).length < 1)
                                    p = [...p, u.perfiles[u.perfiles.length - 1]]
                            })
                            usuarios.forEach(usuario => {
                                if (u.filter(u => u.usuId === usuario.usuId).length < 1)
                                    u = [...u, usuario]
                            })
                        })
                        setNombres(n)
                        setApellidos(a)
                        setPaises(c)
                        setEstados(s)
                        setPerfiles(p)
                        setUsers(u)
                    }
                })
                .catch(error => {
                    const { response } = error
                    const msgError = response.status === 409 ? response.data[0].messageText : response.data
                    toast.error(msgError)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        } else {
            fetchData()
        }
    }

    const renderTotal = ({ id, name, total, props, data, description }) =>
        <Col
            key={id}
            xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}
        >
            <Card
                {...props}
                onClick={
                    () => setProfile({ perId: id, perNombre: name, total })
                }
            >
                <Statistic
                    {...data}
                    valueStyle={{
                        color: '#ffffff',
                    }}
                />
                {description}
            </Card>
        </Col>

    const downloadReport = ({ id, name }) => {
        const fileName = `${name}.xlsx`
        const metaData = getTypeContent(fileName)
        var xhrOverride = new XMLHttpRequest();
        xhrOverride.responseType = 'arraybuffer';
        setIsDownloading(true)
        $.ajax({
            async: true,
            crossDomain: true,
            url: id === 0 ? apiURLs.downloadBeneficiariesReport() : apiURLs.downloadUsersReport(),
            method: 'GET',
            headers: {
                Accept: "*/*",
                "Accept-Language": getCulture(),
                Authorization: `Bearer ${getToken()}`
            },
            xhr: function () {
                return xhrOverride;
            }
        }).then(function (response) {
            setIsDownloading(false)
            const blob = new Blob([response], { type: metaData })
            saveAs(blob, fileName)
        });
    }

    const renderTotalExportar = ({ id, name, props, data, description }) =>
        <Col
            key={id}
            xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}
        >
            <Popconfirm
                title={id === 0 ? getLabel(labels.sureDownloadBeneficiariesReport) : getLabel(labels.sureDownloadUsersReport)}
                okText={getLabel(labels.yes)}
                cancelText={getLabel(labels.no)}
                onConfirm={
                    () =>
                        downloadReport({ id, name })
                }
                okButtonProps={{
                    loading: isDownloading
                }}
            >
                <Card
                    {...props}
                >
                    <Statistic
                        {...data}
                        valueStyle={{
                            color: '#ffffff',
                        }}
                    />
                    {description}
                </Card>
            </Popconfirm>
        </Col>

    const fetchDataTotals = async () => {
        setLoadTotals(false)
        setIsLoadingTotals(true)
        try {
            const { data } = await usuarioService.getTotalUsersByProfile({ navigate })
            if (data) {
                const totalesCompleto = data.listObject
                const totales = totalesCompleto.filter(t => t.perNombre.indexOf('ADMIN') < 0 && t.perNombre.indexOf('BENEFI') < 0)
                let profiles = []
                totales.forEach(t => {
                    if (profiles.filter(p => p.perId === t.perId).length < 1) {
                        const initialValue = 0;
                        const initialValueActives = 0;
                        profiles = [...profiles,
                        {
                            perId: t.perId,
                            perNombre: t.perNombre,
                            total: totalesCompleto.filter(a => a.perId === t.perId).reduce(
                                (accumulator, currentValue) => accumulator + currentValue.total,
                                initialValue,
                            ),
                            activos: totalesCompleto.filter(a => a.perId === t.perId && a.estado === getCatalogos().states.filter(s => s.catValor === 'ACTIVO' || s.catValor === 'ACTIVE')[0].catId).reduce(
                                (accumulator, currentValue) => accumulator + currentValue.total,
                                initialValueActives,
                            ) || 0
                        }
                        ]
                    }
                })
                const initialValue = 0;
                setTotals(
                    [...profiles,
                    totalesCompleto.filter(t => t.perNombre.indexOf('BENEFI') >= 0)[0],
                    {
                        "perId": 999,
                        "perNombre": getLabel(labels.users).toUpperCase(),
                        "total": totalesCompleto.filter(t => t.perNombre.indexOf('BENEFI') < 0).reduce(
                            (accumulator, currentValue) => accumulator + currentValue.total,
                            initialValue,
                        ) || 0 || 0
                    }]
                )
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoadingTotals(false)
        }
    }

    const fetchData = async () => {
        setLoad(false)
        setIsLoading(true)
        setSelectedProfiles([])
        setCurrentDataSource(null)
        setPerfiles([])
        setUsers([])
        try {
            const { data } = await usuarioService.getLessPostulants({ navigate })
            if (data) {
                const usuarios = data.listObject
                let n = []
                let a = []
                let c = []
                let s = []
                let p = []
                usuarios.forEach(u => {
                    if (n.filter(p => p.nombres === u.nombres).length < 1)
                        n = [...n, u.nombres]
                })
                usuarios.forEach(u => {
                    if (a.filter(p => p.apellidos === u.apellidos).length < 1)
                        a = [...a, u.apellidos]
                })
                usuarios.forEach(u => {
                    if (c.filter(p => p.catId === u.pais.catId).length < 1)
                        c = [...c, u.pais]
                })
                usuarios.forEach(u => {
                    if (s.filter(s => s.catId === u.estado.catId).length < 1)
                        s = [...s, u.estado]
                })
                usuarios.forEach(u => {
                    if (p.filter(p => p.perId === u.perfiles[u.perfiles.length - 1].perId).length < 1)
                        p = [...p, u.perfiles[u.perfiles.length - 1]]
                })
                setNombres(n)
                setApellidos(a)
                setPaises(c)
                setEstados(s)
                setPerfiles(p)
                setUsers(usuarios)
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (load) fetchData()
        if (loadTotals) fetchDataTotals()
    })

    return (
        <>
            {
                isLoadingTotals ?
                    <AlertWait isLoading={isLoadingTotals} message={getLabel(labels.loading)} />
                    :
                    totals && <Row gutter={[15, 15]}>
                        {
                            totals && totals.filter(t => t.perId !== 0 && t.perId !== 999).map((t, index) => (
                                {
                                    id: t.perId,
                                    name: t.perNombre,
                                    total: t.total,
                                    props: {
                                        bordered: true,
                                        hoverable: true,
                                        style: {
                                            height: '100%'
                                            , color: '#f0f0f0'
                                            , ...estilos[index]
                                        }
                                    },
                                    data: {
                                        title: <Typography.Text style={{ color: '#ffffff' }}>{t.perNombre}</Typography.Text>,
                                        value: t.perId !== 0 && t.perId !== 999 ? Math.round(t.activos * 100.00 / t.total, 1) : t.total,
                                        precision: 0,
                                        prefix: <anticons.UserOutlined />,
                                        suffix: t.perId !== 0 && t.perId !== 999 && '%'
                                    },
                                    description: t.perId !== 0 && t.perId !== 999 && <>{t.activos} {getLabel(labels.of)} {t.total} {getLabel(labels.users)}</>
                                }
                            )).map(s => renderTotal(s))
                        }
                        {
                            totals && totals.filter(t => t.perId === 0 || t.perId === 999).map((t, index) => (
                                {
                                    id: t.perId,
                                    name: t.perNombre,
                                    total: t.total,
                                    props: {
                                        bordered: true,
                                        hoverable: true,
                                        style: {
                                            height: '100%'
                                            , color: '#f0f0f0'
                                            , ...estilos[index]
                                        }
                                    },
                                    data: {
                                        title: <Typography.Text style={{ color: '#ffffff' }}>{t.perNombre}</Typography.Text>,
                                        value: t.perId !== 0 && t.perId !== 999 ? Math.round(t.activos * 100.00 / t.total, 1) : t.total,
                                        precision: 0,
                                        prefix: <anticons.UserOutlined />,
                                        suffix: t.perId !== 0 && t.perId !== 999 && '%'
                                    },
                                    description: t.perId !== 0 && t.perId !== 999 && <>{t.activos} {getLabel(labels.of)} {t.total} {getLabel(labels.users)}</>
                                }
                            )).map(s => renderTotalExportar(s))
                        }
                    </Row>
            }
            <Row justify='end'>
                <Col style={{ padding: '15px 0' }}>
                    <Form
                        layout='inline'
                    >
                        <Form.Item>
                            <Button type='default'
                                onClick={() => fetchData()}
                                loading={isLoading || isDownloading}
                            >
                                {getLabel(labels.refresh)}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
            {
                isLoading ?
                    <Row style={{ padding: '30px 10px' }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Spin size='large' tip={
                                <Typography.Paragraph style={{ padding: '10px 0' }}>
                                    {getLabel(labels.loading)}...
                                </Typography.Paragraph>
                            } />
                        </Col>
                    </Row >
                    :
                    <Row>
                        {
                            selectedProfiles &&
                            <Col span={24}>
                                <Row gutter={[15, 15]}>
                                    {
                                        selectedProfiles.map(p =>
                                            <Col
                                                key={p.perId}
                                            >
                                                <Tag
                                                    onClose={(_) => setProfile(p)}
                                                    closeIcon={<i className="fa-solid fa-circle-xmark"></i>}
                                                    closable
                                                    style={{
                                                        padding: '5px 15px',
                                                        borderRadius: '15px',
                                                        fontSize: '1em'
                                                    }}
                                                >
                                                    {`${p.perNombre} (${p.total})`}
                                                </Tag>
                                            </Col>
                                        )
                                    }
                                </Row>
                            </Col>
                        }
                        <Col span={24} style={{ textAlign: 'end' }}>
                            <Typography>
                                <Paragraph>
                                    <Text type='secondary'>
                                        {`${getLabel(labels.filtered)} ${currentDataSource || users.length} ${getLabel(labels.of)} ${users.length} ${getLabel(labels.users)}`}
                                    </Text>
                                </Paragraph>
                            </Typography>
                        </Col>
                        <Col span={24}>
                            {
                                perfiles && <Table
                                    {...tableProps}
                                    rowKey='usuId'
                                    columns={columnas}
                                    dataSource={users}
                                    onChange={(pagination, filters, sorter, extra) => {
                                        const { currentDataSource } = extra
                                        setCurrentDataSource(currentDataSource.length)
                                    }}
                                >
                                </Table>
                            }
                        </Col>
                    </Row >
            }
        </>
    )
}

export default Dashboard