import AxiosInstance from '../utils/AxiosInstance'
import { insertEvidence } from '../utils/urls'

export class EvidenceService {
    put = (props) => {
        const { navigate, evidence } = props
        return AxiosInstance(navigate).put(insertEvidence(), evidence)
    }
}

export default EvidenceService