import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Form, Input, Select, Tag, Button, DatePicker, Space, Radio, Image, Alert } from 'antd'
import * as anticons from '@ant-design/icons'
import toast from 'react-hot-toast'

import TrainingService from '../../service/TrainingService'
import PostulantService from '../../service/PostulantService'
import CountryTrainingService from '../../service/CountryTrainingService'
import CourseService from '../../service/CourseService'
import { getCatalogos, getCultures, getDatosUsuario } from '../../utils/Utils'
import { getCulture, getLabel, labels } from '../../utils/Internationalization'

const baseTraining = {
  entCultura: getCulture()
}

const { Option } = Select
const trainingService = new TrainingService()
const postulantService = new PostulantService()
const countryTrainingService = new CountryTrainingService()
const courseService = new CourseService()

const Course = () => {

  const navigate = useHistory()
  const [formPostulante] = Form.useForm()
  const [isSaving, setIsSaving] = useState(false)
  const [postulants, setPostulants] = useState([])
  const [paises, setPaises] = useState([])
  const [courses, setCourses] = useState([])
  const [message, setMessage] = useState({ type: 'warning', message: null })
  const [selectedModality, setSelectedModality] = useState(0)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [formCourse] = Form.useForm()

  const disabledDateStart = (current) => {
    if (endDate === null) return
    return current && current >= endDate.startOf('day');
  }

  const disabledDateEnd = (current) => {
    if (startDate === null) return
    return current && current <= startDate.endOf('day');
  }

  const renderPaisValor = e => <Option key={e.catValor} value={e.catId}>
    <>
      <img
        src={`https://flagcdn.com/24x18/${e.catDescripcion.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/48x36/${e.catDescripcion.toLowerCase()}.png 2x,https://flagcdn.com/72x54/${e.catDescripcion.toLowerCase()}.png 3x`}
        width="24"
        height="18"
        alt={e.catNombre}></img>
      &nbsp;{
        e.catNombre
      }
    </>
  </Option>

  const renderModality = ({ catId, catNombre, catValor }) =>
    <Radio.Button
      key={catId}
      value={catId}
      onChange={e => setSelectedModality(catId)}
      style={{
        height: '100%',
        margin: '0 20px 20px 0',
      }}
    >
      <Row>
        <Col style={{
          padding: '20px 10px',
        }}>
          <Image preview={false} src={`./../../assets/${catValor}${catId === selectedModality ? '_W' : ''}.png`} width={55} height={50} />
        </Col>
        <Col style={{
          padding: '30px 10px',
          width: '100px'
        }}>
          {catNombre}
        </Col>
      </Row>
    </Radio.Button>

  // const renderCourse = ({ catId, catNombre, catValor }) =>
  //   <Col key={catId} span={24}>
  //     <Form.Item
  //       name={[catValor]}
  //       label={`Number of beneficiaries for ${catNombre}`}
  //       rules={[
  //         {
  //           required: true,
  //           message: `Complete number of beneficiaries for ${catNombre}`
  //         },
  //       ]}
  //     >
  //       <InputNumber />
  //     </Form.Item>
  //   </Col>

  const renderApplicants = (posEmail) =>
    <Col key={posEmail}>
      <Tag key={posEmail} closeIcon={<anticons.CloseOutlined />} onClose={(e) => { removePostulant(posEmail) }}
        closable
        style={{
          padding: '5px 15px',
          borderRadius: '15px',
          fontSize: '1em'
        }}
      >
        {posEmail}
      </Tag>
    </Col>

  const addPostulant = (values) => {
    const { postulant } = values
    formPostulante.resetFields()
    if (postulants.filter(p => p === postulant).length > 0) {
      setMessage({
        type: 'warning',
        message: `${getLabel(labels.thenEmail)} ${postulant} ${getLabel(labels.alreadyExists)}.`
      })
      setTimeout(() => setMessage({ type: 'warning', message: null }), 5000)
      return
    }
    const newPostulantsList = [...postulants.filter(p => p !== postulant), postulant]
    setPostulants(newPostulantsList)
    setMessage({
      type: 'success',
      message: `${getLabel(labels.email)} ${postulant} ${getLabel(labels.successfullyAdded)}.`
    })
    setTimeout(() => setMessage({ type: 'warning', message: null }), 5000)
  }

  const removePostulant = (email) => {
    const newPostulantsList = postulants.filter(p => p !== email)
    setMessage({
      type: 'success',
      message: `${getLabel(labels.email)} ${email} ${getLabel(labels.successfullyRemoved)}.`
    })
    setTimeout(() => setMessage({ type: 'warning', message: null }), 5000)
    setPostulants(newPostulantsList)
  }

  const save = async (values) => {
    const training = {
      idRegion: values.idRegion,
      entCultura: values.entCultura,
      idModalidad: values.idModalidad,
      entFechaIni: values.entFechaIni,
      entFechaFin: values.entFechaFin,
      idEstado: getCatalogos().trainingStatus.filter(s => s.catValor === 'DRAFT')[0].catId,
      idUsuario: getDatosUsuario().usuarioId
    }
    setIsSaving(true)
    try {
      const { data } = await trainingService.put({ navigate, training })
      if (data) {
        // Asignar Paises
        const requests = []
        if (paises.length > 0) {
          paises.forEach(p => {
            requests.push(countryTrainingService.put({
              navigate, countryTraining: {
                "idPais": p.value,
                "idEntrenamiento": data.listObject[0].entId
              }
            }))
          });
        }
        // Asignar cursos
        if (courses.length > 0) {
          courses.forEach(p => {
            requests.push(courseService.put({
              navigate, course: {
                "idEntrenamiento": data.listObject[0].entId,
                "idNombreCurso": p.catId
              }
            }))
          });
        }
        // Asignar postulantes
        if (postulants.length > 0) {
          postulants.forEach(p => {
            requests.push(postulantService.put({
              navigate, postulant: {
                "idEntrenamiento": data.listObject[0].entId,
                "posEmail": p,
                "idInvitadoPor": getDatosUsuario().usuarioId
              }
            }))
          });
        }
        if (requests.length > 0) {
          Promise.all(requests)
            .then((results) => {
              toast.success(`${getLabel(labels.training)} ${data.listObject[0].entNombre} ${getLabel(labels.successfullyCreated)}`)
              navigate.push(`/trainingedit/${data.listObject[0].entId}`)
            }).catch(error => {
              const { response } = error
              const msgError = response.status === 409 ? response.data[0].messageText : response.data
              toast.error(`${getLabel(labels.notPossibleAddApplicant)}: ${msgError}`)
            })
        } else {
          toast.success(`${getLabel(labels.training)} ${data.listObject[0].entNombre} ${getLabel(labels.successfullyCreated)}`)
          navigate.push(`/trainingedit/${data.listObject[0].entId}`)
        }
      } else {
        toast.error(getLabel(labels.errorOcurred))
      }
    } catch (error) {
      const { response } = error
      toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <>
      <Form
        form={formCourse}
        layout='vertical'
        initialValues={
          baseTraining
        }
        onFinish={save}
        disabled={isSaving}
      >
        <div className="grid">
          <div className="col-12">
            <div className="mgeCard">
              <div className="grid">
                <div className="col-4">
                  <Space direction='horizontal'>
                    <Button icon={<i className="fa-solid fa-chevron-left"></i>}
                      onClick={() => {
                        navigate.push('/training')
                      }}
                    ></Button>
                    <h3 style={{ padding: '0', margin: '0' }} className='mge-darkGreen'>
                      {getLabel(labels.newTraining)}
                    </h3>
                  </Space>
                </div>
                <div className="col-8" style={{ textAlign: 'right' }}>
                  <Space>
                    <Button
                      type='default'
                      htmlType='submit'
                      loading={isSaving}
                      disabled={isSaving}
                    >{getLabel(labels.saveDraft)}</Button>
                    <Button
                      type='primary'
                      loading={isSaving}
                      disabled
                    >{getLabel(labels.publish)}</Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Row justify='center'>
          <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
            <Row gutter={[25, 25]}>
              <Col span={12}>

                <Form.Item
                  name='idRegion'
                  label={getLabel(labels.region)}
                  rules={[
                    {
                      required: true,
                      message: getLabel(labels.selectRegion),
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: '100%',
                      display: 'inline-block'
                    }}
                    fieldNames={{
                      label: 'catNombre',
                      value: 'catId'
                    }}
                    options={getCatalogos().regions}
                    showSearch
                    optionFilterProp="catNombre"
                    onChange={(_, o) => {
                      if (o.catValor === 'CAT_REG_AND') {
                        const entCultura = getCultures().filter(c => c.id === 'es_EC')[0].id
                        formCourse.setFieldValue('entCultura', entCultura)
                      }
                      else if (o.catValor === 'CAT_REG_CAR') {
                        const entCultura = getCultures().filter(c => c.id === 'en_US')[0].id
                        formCourse.setFieldValue('entCultura', entCultura)
                      } else {
                        const entCultura = getCultures().filter(c => c.id === 'en_US')[0].id
                        formCourse.setFieldValue('entCultura', entCultura)
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='entCultura'
                  label={getLabel(labels.language)}
                  rules={[
                    {
                      required: true,
                      message: getLabel(labels.selectLanguage),
                    },
                  ]}
                >
                  <Select
                    disabled
                    style={{
                      width: '100%',
                      display: 'inline-block'
                    }}
                    fieldNames={{
                      label: 'value',
                      value: 'id'
                    }}
                    options={getCultures()}
                    showSearch
                    optionFilterProp="value"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[25, 25]}>
              <Col span={24}>
                <Form.Item
                  name='idPais'
                  label={getLabel(labels.country)}
                  rules={[
                    {
                      required: true,
                      message: getLabel(labels.selectCountry),
                    },
                  ]}
                >
                  <Select
                    name='idPais'
                    mode='multiple'
                    onChange={(_, option) => {
                      setPaises(option)
                    }}
                  >
                    {
                      getCatalogos().paises.map(p => renderPaisValor(p))
                    }
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[25, 25]}>
              <Col span={24}>
                <Form.Item
                  name='idModalidad'
                  label={getLabel(labels.modality)}
                  rules={[
                    {
                      required: true,
                      message: getLabel(labels.selectModality),
                    },
                  ]}
                  style={{ width: '100%', textAlign: 'center' }}
                >
                  <Radio.Group buttonStyle='solid'>
                    {
                      getCatalogos().modalidades.map(m => renderModality(m))
                    }
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[25, 25]}>
              <Col span={12}>
                <Form.Item
                  name='entFechaIni'
                  label={getLabel(labels.startDate)}
                  rules={[
                    {
                      required: true,
                      message: getLabel(labels.selectStartDate),
                    },
                  ]}
                >
                  <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }} onChange={setStartDate} disabledDate={disabledDateStart} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='entFechaFin'
                  label={getLabel(labels.endDate)}
                  rules={[
                    {
                      required: true,
                      message: getLabel(labels.selectEndDate),
                    },
                  ]}
                >
                  <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }} onChange={setEndDate} disabledDate={disabledDateEnd} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item
                  name={['courses']}
                  label={getLabel(labels.courses)}
                  rules={[
                    {
                      required: true,
                      message: getLabel(labels.selectCourses),
                    },
                  ]}
                >
                  <Select
                    style={{
                      width: '100%',
                      display: 'inline-block'
                    }}
                    mode='multiple'
                    fieldNames={{
                      label: 'catNombre',
                      value: 'catId'
                    }}
                    options={getCatalogos().cursos}
                    showSearch
                    optionFilterProp="catNombre"
                    onChange={(_, option) => {
                      setCourses(option)
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Row justify='center'>
        <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
          <Row>
            <Form
              form={formPostulante}
              layout='vertical'
              style={{ width: '100%' }}
              initialValues={{ postulant: '' }}
              onFinish={addPostulant}
            >
              <Col span={24}>
                <Form.Item
                  name='postulant'
                  label={getLabel(labels.applicantsEmails)}
                  extra={getLabel(labels.pressEnterAddEmail)}
                  rules={[
                    {
                      required: true,
                      message: getLabel(labels.addApplicants),
                    },
                    {
                      type: 'email',
                      message: getLabel(labels.emailNotValid)
                    }
                  ]}
                >
                  <Input
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                {
                  message.message &&
                  <Alert {...message} showIcon closable />
                }
              </Col>
            </Form>
            <Col span={24} style={{ paddingTop: '10px' }}>
              <Row gutter={[15, 15]}>
                {
                  postulants && postulants.map(a => renderApplicants(a))
                }
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default Course