import React from "react";

const AppRightPanel = (props) => {
  return <></>;
//   return (
//     <div className="layout-rightpanel" onClick={props.onRightPanelClick}>
//       <div className="rightpanel-wrapper">
//         <div className="rightpanel-section weather-section">
//           <div className="section-header">
//             <h6>Today</h6>
//           </div>
//           <div className="weather">
//             <img
//               src="assets/demo/images/rightpanel/icon-sun.svg"
//               alt="freya-layout"
//             />
//             <div className="weather-info">
//               <h6>Ankara, 22 May</h6>
//               <h1>24º</h1>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
};

export default AppRightPanel;
