import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Layout, Row, Col, Image, Form, Input, Select, DatePicker, Switch, Button, Typography, Tooltip, Space, Spin, Result, Upload } from 'antd'
import * as anticons from '@ant-design/icons'
import PasswordChecklist from 'react-password-checklist'
import md5 from 'md5'
import { getCulture, getLabel, labels } from '../utils/Internationalization'
import { disabledDate, getCatalogos } from '../utils/Utils'

import CatalogoService from '../service/CatalogoService'
import UsuarioService from '../service/UsuarioService'
import PostulantService from '../service/PostulantService'
import toast from 'react-hot-toast'

const { Option } = Select
const usuarioService = new UsuarioService()
const postulantService = new PostulantService()
const catalogoService = new CatalogoService()

const renderPaisValor = e => <Option key={e.catValor} value={e.catId}>
    <>
        <img
            src={`https://flagcdn.com/24x18/${e.catDescripcion.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/48x36/${e.catDescripcion.toLowerCase()}.png 2x,https://flagcdn.com/72x54/${e.catDescripcion.toLowerCase()}.png 3x`}
            width="24"
            height="18"
            alt={e.catNombre}></img>
        &nbsp;{
            e.catNombre
        }
    </>
</Option>

const renderPaisCode = e => <Option key={e.catValor} value={e.catId}>
    <>
        +({e.catValor})&nbsp;
        <img
            src={`https://flagcdn.com/24x18/${e.catDescripcion.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/48x36/${e.catDescripcion.toLowerCase()}.png 2x,https://flagcdn.com/72x54/${e.catDescripcion.toLowerCase()}.png 3x`}
            width="24"
            height="18"
            alt={e.catDescripcion}></img>
    </>
</Option>

const renderError = (e) => <div key={e.messageCode}>{e.messageText}</div>

const Signup = () => {
    const { search } = useLocation()
    const { id, uuid } = JSON.parse('{"' + search.substring(1, search.length).replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
    const [photoFile, setPhotoFile] = useState();

    const [fileList, setFileList] = useState([]);

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            setPhotoFile(null);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            setPhotoFile(URL.createObjectURL(file));
            return false;
        },
        fileList,
    }

    const navigate = useHistory()
    const [loadCatalogos, setLoadCatalogos] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isUploading, setIsUploading] = useState(false)
    const [isRegister, setIsRegister] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [currentlyWorking, setCurrentlyWorking] = useState(false)
    const [validForm, setValidForm] = useState(false)
    const [validPostulant, setValidPostulant] = useState(null)

    const validate = async () => {
        setIsLoading(true)
        const postulant = {
            posId: id,
            posUuid: uuid
        }
        try {
            const { data } = await postulantService.validate({ postulant })
            if (data) {
                const validPostulantInfo = data.listObject[0]
                const { posEmail, idInvitadoPor, posId, posEstado } = validPostulantInfo
                const postulantInfo = { email: posEmail, invitadopor: idInvitadoPor, idPostulante: posId, posEstado }
                setValidPostulant(postulantInfo)
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoading(false)
        }
    }

    const fetchCatalogos = async () => {
        setLoadCatalogos(false)
        setIsLoading(true)
        // Get Catalogos
        const requests = []
        requests.push(catalogoService.getPaises())
        requests.push(catalogoService.getCursos())
        requests.push(catalogoService.getModalidad())
        requests.push(catalogoService.getGeneros())
        requests.push(catalogoService.getTrainingStatus())
        requests.push(catalogoService.getReportType())
        requests.push(catalogoService.getReportStatus())
        requests.push(catalogoService.getStates())
        requests.push(catalogoService.getEducationLevel())
        requests.push(catalogoService.getTypePerson())
        requests.push(catalogoService.getRegion())
        let mgeCatalogos = []
        Promise.all(requests)
            .then((results) => {
                if (results.length > 0) {
                    mgeCatalogos =
                    {
                        paises: results[0].data.listObject,
                        cursos: results[1].data.listObject,
                        modalidades: results[2].data.listObject,
                        generos: results[3].data.listObject,
                        trainingStatus: results[4].data.listObject,
                        reportType: results[5].data.listObject,
                        reportStatus: results[6].data.listObject,
                        states: results[7].data.listObject,
                        educationLevels: results[8].data.listObject,
                        typePersons: results[9].data.listObject,
                        regions: results[9].data.listObject
                    }
                    validate()
                }
                localStorage.setItem("mgeCatalogos", JSON.stringify(mgeCatalogos))
            })
            .catch(error => {
                const { response } = error
                const msgError = response.status === 409 ? response.data[0].messageText : response.data
                toast.error(msgError)
                setIsLoading(false)
            })
    }

    const handleUpload = async ({ usuId }) => {
        const datos = new FormData();
        datos.append('file', fileList[0])
        datos.append('usuId', usuId)
        setIsUploading(true)
        try {
            const { data } = await usuarioService.upLoadPhoto({ datos })
            if (data && data.listObject.length > 0 && data.listObject[0].usuId) {
                toast.success(getLabel(labels.accountCreatedSuccessfully))
                setTimeout(() => {
                    navigate.push('/login')
                }, 3000);
            }
        } catch (_) {
            toast.error(getLabel(labels.uploadFailed))
        } finally {
            setIsRegister(false)
            setIsUploading(false)
            setFileList([])
        }
    }

    const registrar = async (values) => {
        const usuario = {
            ...values,
            password: md5(values.password),
            passwordConfirm: md5(values.passwordConfirm),
            trabaja: currentlyWorking,
            perId: 5,
            cultura: getCulture(),
            ...validPostulant
        }
        setIsRegister(true)
        try {
            const { data } = await usuarioService.put({ usuario })
            if (data && data.listObject.length > 0 && data.listObject[0].usuId) {
                const usuario = data.listObject[0]
                handleUpload(usuario)
            }
        } catch (error) {
            setIsRegister(false)
            const { status, data } = error.response
            switch (status) {
                case 409:
                    toast.error(data.map(e => renderError(e)))
                    break
                case 500:
                    toast.error(data)
                    break
                default:
                    break
            }
        }
    }

    useEffect(() => {
        if (loadCatalogos) {
            fetchCatalogos()
        }
    })

    return (
        <>
            <Row>
                <Col span={24}>
                    <div className="notification" style={{
                        borderRadius: '0px',
                        textAlign: 'center'
                    }}>
                        <Image alt='' src={`./../assets/logo_${getCulture()}.png`} preview={false} />
                    </div>
                </Col>
            </Row>

            <Layout style={{
                padding: '40px 20px',
                backgroundColor: '#F9F9F9'
            }}>
                <div className='mgeBG' style={{ opacity: '0.3', height: '100vh', position: 'fixed', width: '100%', bottom: '0', right: '0' }}>&nbsp;</div>
                {
                    isLoading ?
                        <Row style={{ padding: '30px 10px' }}>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Spin size='large' tip={
                                    <Typography.Paragraph style={{ padding: '10px 0' }}>
                                        {getLabel(labels.loading)}...
                                    </Typography.Paragraph>
                                } />
                            </Col>
                        </Row>
                        :
                        validPostulant &&
                        <>
                            {
                                validPostulant.posEstado.catValor === 'EST_INV' ? <>
                                    <Row>
                                        <Col span={24} style={{ padding: '0 0 30px 0' }}>
                                            <Typography.Paragraph style={{ textAlign: 'center' }}>
                                                <Typography.Text className="mge-darkGreen"
                                                    style={{
                                                        display: 'block',
                                                        fontSize: '2em',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    {getLabel(labels.welcomeMyGreenEnterprise)}
                                                </Typography.Text>
                                                <Typography.Text className="mge-green"
                                                    style={{
                                                        display: 'block',
                                                        fontSize: '1.5em'
                                                    }}
                                                >
                                                    {getLabel(labels.completeInformationActiveAccount)}
                                                </Typography.Text>
                                            </Typography.Paragraph>
                                        </Col>
                                    </Row>
                                    <Row justify='center'>
                                        <Col xs={24} sm={22} md={24} lg={20} xl={18} xxl={12}>
                                            <Form
                                                layout={'vertical'}
                                                initialValues={
                                                    validPostulant
                                                }
                                                onFinish={registrar}
                                                disabled={isRegister}
                                            >
                                                <Row gutter={[10, 0]}>
                                                    <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                                        <Form.Item
                                                            name='file'
                                                            label={getLabel(labels.uploadProfilePicture)}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: getLabel(labels.selectProfilePicture)
                                                                }
                                                            ]}
                                                        >
                                                            <Row gutter={[0, 10]}>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    {
                                                                        photoFile ?
                                                                            <Image src={photoFile} width={128} height={128} />
                                                                            :
                                                                            <Image src='./../assets/photo_profile.png' width={128} height={128} />
                                                                    }
                                                                </Col>
                                                                <Col span={24} style={{ textAlign: 'center' }}>
                                                                    <Upload
                                                                        multiple={false}
                                                                        {...props}
                                                                    >
                                                                        <Button
                                                                            type='primary'
                                                                            icon={<anticons.UploadOutlined />}
                                                                            disabled={
                                                                                fileList.length > 0
                                                                            }
                                                                        >
                                                                            {getLabel(labels.selectPhoto)}
                                                                        </Button>
                                                                    </Upload>
                                                                </Col>
                                                            </Row>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                                                        <Row gutter={[15, 0]}>
                                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                <Form.Item
                                                                    name='nombres'
                                                                    label={getLabel(labels.firstName)}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: getLabel(labels.completeFirstName),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                <Form.Item
                                                                    name='apellidos'
                                                                    label={getLabel(labels.lastName)}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: getLabel(labels.completeLastName),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                <Form.Item
                                                                    name={['sexoId']}
                                                                    label={getLabel(labels.sex)}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: getLabel(labels.selectSex),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        style={{
                                                                            width: '100%',
                                                                            display: 'inline-block'
                                                                        }}
                                                                        fieldNames={{
                                                                            label: 'catNombre',
                                                                            value: 'catId'
                                                                        }}
                                                                        showSearch
                                                                        options={getCatalogos().generos}
                                                                        optionFilterProp="catNombre"
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                                <Form.Item
                                                                    name='fechanac'
                                                                    label={getLabel(labels.dateBirth)}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: getLabel(labels.selectDateBirth),
                                                                        },
                                                                    ]}
                                                                >
                                                                    <DatePicker format={'YYYY-MM-DD'} style={{ width: '100%' }} disabledDate={disabledDate} />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                        <Form.Item
                                                            name='documento'
                                                            label={
                                                                <Space direction='horizontal'>
                                                                    <>{getLabel(labels.idNumber)}</>
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                {getLabel(labels.uniqueIdentificationCountry)}
                                                                            </>
                                                                        }
                                                                        placement='right'
                                                                    >
                                                                        <anticons.InfoCircleFilled />
                                                                    </Tooltip>
                                                                </Space>
                                                            }
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: getLabel(labels.completeID),
                                                                },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                        <Form.Item
                                                            name='paisId'
                                                            label={getLabel(labels.country)}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: getLabel(labels.selectCountry),
                                                                },
                                                            ]}
                                                        >
                                                            <Select name='paisId'>
                                                                {
                                                                    getCatalogos().paises.map(p => renderPaisValor(p))
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                        <Form.Item
                                                            name='ciudad'
                                                            label={getLabel(labels.city)}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: getLabel(labels.completeCity),
                                                                },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                        <Form.Item
                                                            name='codigotel'
                                                            label={
                                                                <span style={{ padding: '0 0 2px 0' }}>
                                                                    {getLabel(labels.countryCode)}
                                                                </span>
                                                            }
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: getLabel(labels.selectCountryCode),
                                                                }
                                                            ]}
                                                        >
                                                            <Select name='codigotel'>
                                                                {
                                                                    getCatalogos().paises.map(p => renderPaisCode(p))
                                                                }
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                        <Form.Item
                                                            name='celular'
                                                            label={
                                                                <span style={{ padding: '0 0 2px 0' }}>
                                                                    {getLabel(labels.phoneNumber)}
                                                                </span>
                                                            }
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: getLabel(labels.completePhoneNumber),
                                                                }
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                                        <Form.Item
                                                            name='lugartrabajo'
                                                            label={
                                                                <Space direction='horizontal'>
                                                                    <>
                                                                        {getLabel(labels.currentyWorking)}
                                                                    </>
                                                                    <Tooltip
                                                                        title={
                                                                            <>
                                                                                {getLabel(labels.completeNameInstitution)}
                                                                            </>
                                                                        }
                                                                        placement='right'
                                                                    >
                                                                        <anticons.InfoCircleFilled />
                                                                    </Tooltip>
                                                                    <Switch
                                                                        onChange={(c) => { setCurrentlyWorking(c) }}
                                                                    />
                                                                </Space>
                                                            }
                                                            rules={[
                                                                {
                                                                    required: currentlyWorking,
                                                                    message: getLabel(labels.ifYesCompleteNameInstitution),
                                                                },
                                                            ]}
                                                        >
                                                            <Input name='lugartrabajo' disabled={!currentlyWorking} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='email'
                                                            label={getLabel(labels.email)}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: getLabel(labels.completeEmail),
                                                                },
                                                                {
                                                                    type: 'email',
                                                                    message: getLabel(labels.emailNotValid)
                                                                }
                                                            ]}
                                                        >
                                                            <Input disabled />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='email2'
                                                            label={getLabel(labels.additionalEmail)}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: getLabel(labels.completeAdditionalEmail),
                                                                },
                                                                {
                                                                    type: 'email',
                                                                    message: getLabel(labels.additionalEmailNotValid)
                                                                }
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='password'
                                                            label={getLabel(labels.createYourPassword)}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: getLabel(labels.createYourPassword),
                                                                },
                                                            ]}
                                                        >
                                                            <Input.Password id="password" name="password" onChange={e => setPassword(e.target.value)} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='passwordConfirm'
                                                            label={getLabel(labels.confirmYourPassword)}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: getLabel(labels.confirmYourPassword),
                                                                },
                                                            ]}
                                                        >
                                                            <Input.Password id="passwordConfirm" name="passwordConfirm" onChange={e => setPasswordConfirm(e.target.value)} />
                                                        </Form.Item>
                                                    </Col>
                                                    {
                                                        validForm === false &&
                                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}
                                                        >
                                                            <PasswordChecklist
                                                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                                                minLength={8}
                                                                value={password}
                                                                valueAgain={passwordConfirm}
                                                                messages={{
                                                                    minLength: getLabel(labels.passwordHasMore8Characters),
                                                                    specialChar: getLabel(labels.passwordHasSpecialCharacters),
                                                                    number: getLabel(labels.passwordHasNumber),
                                                                    capital: getLabel(labels.passwordHasCapitalLetter),
                                                                    match: getLabel(labels.passwordsMatch)
                                                                }}
                                                                onChange={(isValid) => {
                                                                    setValidForm(isValid)
                                                                }}
                                                                style={{
                                                                    padding: '0 0 20px 0'
                                                                }}
                                                            />
                                                        </Col>
                                                    }
                                                    <Col span={24} style={{ textAlign: 'center' }}>
                                                        <Form.Item>
                                                            <Button type='primary' htmlType='submit'
                                                                loading={isLoading || isUploading}
                                                                disabled={isLoading || isUploading}
                                                                block>{getLabel(labels.register)}</Button>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </>
                                    :
                                    <Result
                                        title={getLabel(labels.accountPreviouslyCreated)}
                                        extra={
                                            <Button type="primary" key="console"
                                                onClick={() => {
                                                    navigate.push('/login')
                                                }}
                                            >
                                                {getLabel(labels.login)}
                                            </Button>
                                        }
                                    />
                            }
                        </>
                }
            </Layout>
        </>
    )
}

export default Signup