import axios from "axios"
import AxiosInstance from "./../utils/AxiosInstance"
import { enrollUsuario, getActualizarUsuarioUrl, getUsersLessPostulants, getUsuarioPorIdUrl, getUsuariosFilterUrl, getUsuariosUrl, insertUsuario, resetPassword, uploadPhotoUser, getTotalUsersByProfile, getUsersListByProfile, downloadUsersReport, uploadSignatureUser } from "./../utils/urls"
import { getCulture } from "../utils/Internationalization"

export default class UsuarioService {
    gets = (props) => {
        const { navigate } = props
        return AxiosInstance(navigate).get(getUsuariosUrl())
    }
    getsFiltro = (props) => {
        const { navigate, filtros } = props
        return AxiosInstance(navigate).post(getUsuariosFilterUrl(), { ...filtros })
    }
    get = (props) => {
        const { navigate, usuario } = props
        return AxiosInstance(navigate).post(getUsuarioPorIdUrl(), usuario)
    }
    new = (props) => {
        const { navigate, usuario } = props
        return AxiosInstance(navigate).put(insertUsuario(), usuario)
    }
    set = (props) => {
        const { navigate, usuario } = props
        return AxiosInstance(navigate).patch(getActualizarUsuarioUrl(), usuario)
    }
    put = (props) => {
        const { usuario } = props
        const axiosInstance = axios.create({ headers: { "Accept-Language": getCulture() } })
        return axiosInstance.put(enrollUsuario(), usuario)
    }
    upLoadPhoto = (props) => {
        const { datos } = props
        return axios.put(uploadPhotoUser(), datos, { headers: { "Accept-Language": getCulture() } })
    }
    uploadSignature = (props) => {
        const { navigate, datos } = props
        return AxiosInstance(navigate).put(uploadSignatureUser(), datos)
    }
    getLessPostulants = (props) => {
        const { navigate } = props
        return AxiosInstance(navigate).get(getUsersLessPostulants())
    }
    getUsersListByProfile = (props) => {
        const { navigate, profile } = props
        return AxiosInstance(navigate).post(getUsersListByProfile(), profile)
    }
    getTotalUsersByProfile = (props) => {
        const { navigate } = props
        return AxiosInstance(navigate).get(getTotalUsersByProfile())
    }
    resetPassword = (props) => {
        const { navigate, usuario } = props
        return AxiosInstance(navigate).patch(resetPassword(), usuario)
    }
    downloadUsersReport = (props) => {
        const { navigate } = props
        return AxiosInstance(navigate).get(downloadUsersReport())
    }
}