import React from 'react'
import { Row, Col, Space, Alert, Spin, Typography } from 'antd'
import PropTypes from 'prop-types'

const AlertWait = (props) => {
    const { error, isLoading, message } = props
    return (
        <Row justify='center'>
            <Col>
                <Space direction='vertical'
                    style={{
                        display: 'flex',
                    }}
                    size='large'
                >
                    {
                        error &&
                        <Alert message={error} type='warning' showIcon />
                    }
                    {
                        isLoading &&
                        <Row justify='center'>
                            <Col style={{ textAlign: 'center' }}>
                                <Spin size='large'></Spin>
                                {
                                    message && <Typography.Paragraph>
                                        {message}
                                    </Typography.Paragraph>
                                }
                            </Col>
                        </Row>
                    }
                </Space>
            </Col>
        </Row>
    )
}

AlertWait.propTypes = {
    error: PropTypes.any,
    message: PropTypes.any,
    isLoading: PropTypes.bool.isRequired
}

export default AlertWait