// SOLO PARA DESARROLLO Y PRUEBAS SE DEBE AGREGAR EL SIGUIENTE PROXY EN ARCHIVO package.json
// QA
// "proxy": "http://20.65.110.204:44613",
// SET NODE_OPTIONS=--openssl-legacy-provider && 
// PRODUCCION
// "proxy": "http://20.65.110.204:8080",
// "proxy": "https://20.65.110.204:8081",

const apiURL = '/uprime-mge-web/uprime/api'

//SERVICIOS MGE

export const getInsertarCatalogoUrl = () => `${apiURL}/CatalogServiceRest/insertCatalog`
export const getCatalogosFilterUrl = () => `${apiURL}/CatalogServiceRest/getCatalogByFilter`
export const getActualizarCatalogoUrl = () => `${apiURL}/CatalogServiceRest/updateCatalog`
export const getEliminarCatalogoUrl = () => `${apiURL}/CatalogServiceRest/deleteCatalog`
export const getCatalogosPorIdUrl = () => `${apiURL}/CatalogServiceRest/getCatalogById`
export const getCatalogosPorPadreUrl = () => `${apiURL}/CatalogServiceRest/getCatalogsListByFather`
export const getCatalogosUrl = () => `${apiURL}/CatalogServiceRest/getCatalogsList`

export const getInsertarOpcionUrl = () => `${apiURL}/OptionServiceRest/insertOption`
export const getEliminarOpcionUrl = () => `${apiURL}/OptionServiceRest/deleteOption`
export const getActualizarOpcionUrl = () => `${apiURL}/OptionServiceRest/updateOption`
export const getOpcionesPorPadreUrl = () => `${apiURL}/OptionServiceRest/getOptionsListByFather`
export const getOpcionesUrl = () => `${apiURL}/OptionServiceRest/getOptionsList`
export const getOpcionPorIdUrl = () => `${apiURL}/OptionServiceRest/getOptionById`

export const getInsertarPerfilUrl = () => `${apiURL}/ProfileServiceRest/insertProfile`
export const getActualizarPerfilUrl = () => `${apiURL}/ProfileServiceRest/updateProfile`
export const getEliminarPerfilUrl = () => `${apiURL}/ProfileServiceRest/deleteProfile`
export const getPerfilesUrl = () => `${apiURL}/ProfileServiceRest/getProfilesList`
export const getPerfilPorIdUrl = () => `${apiURL}/ProfileServiceRest/getProfileById`

export const getOpcionesPorPerfilUrl = () => `${apiURL}/ProfileOptionServiceRest/getOptionsByProfileList`
export const getInsertarPerfilOpcionUrl = () => `${apiURL}/ProfileOptionServiceRest/insertProfileOption`
export const getActualizarPerfilOpcionUrl = () => `${apiURL}/PerfilOptionServiceRest/updateProfileOption`
export const getEliminarPerfilOpcionUrl = () => `${apiURL}/ProfileOptionServiceRest/deleteProfileOption`

export const getUsuariosFilterUrl = () => `${apiURL}/UserServiceRest/getUsersListByFilter`
export const getUsuarioPorIdUrl = () => `${apiURL}/UserServiceRest/getUserById`
export const getUsersLessPostulants = () => `${apiURL}/UserServiceRest/getUsersLessPostulants`
export const getUsersListByProfile = () => `${apiURL}/UserServiceRest/getUsersListByProfile`
export const getAutenticarUrl = () => `${apiURL}/UserServiceRest/authenticateUser`
export const getAutorizarUrl = () => `${apiURL}/UserServiceRest/authorizeUser`
export const updatePassword = () => `${apiURL}/UserServiceRest/updatePassword`
export const resetPassword = () => `${apiURL}/UserServiceRest/resetPassword`
export const recoveryPassword = () => `${apiURL}/UserServiceRest/recoveryPassword`
export const validateUserUuid = () => `${apiURL}/UserServiceRest/validateUserUuid`
export const addProfileUser = () => `${apiURL}/UserServiceRest/addProfileUser`
export const insertUsuario = () => `${apiURL}/UserServiceRest/insertUser`
export const getActualizarUsuarioUrl = () => `${apiURL}/UserServiceRest/updateUser`
export const enrollUsuario = () => `${apiURL}/UserServiceRest/enrollPostulant`
export const deletePerfilUsuario = () => `${apiURL}/UserServiceRest/deletePerfilUsuario`
export const getUsuariosUrl = () => `${apiURL}/UserServiceRest/getUsersList`
export const uploadPhotoUser = () => `${apiURL}/UserServiceRest/uploadPhotoUser`
export const getTotalUsersByProfile = () => `${apiURL}/UserServiceRest/getTotalUsersByProfile`
export const downloadUsersReport = () => `${apiURL}/UserServiceRest/downloadUsersReport`
export const uploadSignatureUser = () => `${apiURL}/UserServiceRest/uploadSignatureUser`

export const insertTraining = () => `${apiURL}/TrainingServiceRest/insertTraining`
export const updateTraining = () => `${apiURL}/TrainingServiceRest/updateTraining`
export const getTrainingById = () => `${apiURL}/TrainingServiceRest/getTrainingById`
export const getsTrainingByTrainer = () => `${apiURL}/TrainingServiceRest/getTrainingByTrainer`
export const getsTrainingList = () => `${apiURL}/TrainingServiceRest/getTrainingList`

export const insertPostulant = () => `${apiURL}/PostulantServiceRest/insertPostulant`
export const sendEmailRegister = () => `${apiURL}/PostulantServiceRest/sendEmailRegistry`
export const sendEmailEnroll = () => `${apiURL}/PostulantServiceRest/sendEmailEnroll`
export const getPostulantByTraining = () => `${apiURL}/PostulantServiceRest/getPostulantByTraining`
export const getTrainingByPostulant = () => `${apiURL}/PostulantServiceRest/getTrainingByPostulant`
export const deletePostulant = () => `${apiURL}/PostulantServiceRest/deletePostulant`
export const validatePostulant = () => `${apiURL}/PostulantServiceRest/validatePostulant`

export const insertCountryTraining = () => `${apiURL}/CountryTrainingServiceRest/insertCountryTraining`
export const getCountryTrainingByTraining = () => `${apiURL}/CountryTrainingServiceRest/getCountryTrainingByTraining`
export const deleteCountryTraining = () => `${apiURL}/CountryTrainingServiceRest/deleteCountryTraining`

export const insertCourse = () => `${apiURL}/CourseServiceRest/insertCourse`
export const getCourseByTraining = () => `${apiURL}/CourseServiceRest/getCoursesByTraining`
export const deleteCourse = () => `${apiURL}/CourseServiceRest/deleteCourse`

export const insertAttendance = () => `${apiURL}/AssistanceServiceRest/insertAssistance`
export const getAttendanceById = () => `${apiURL}/AssistanceServiceRest/getAssistanceById`
export const updateAttendance = () => `${apiURL}/AssistanceServiceRest/updateAssistance`

export const insertInform = () => `${apiURL}/ReportServiceRest/insertReport`
export const updateInform = () => `${apiURL}/ReportServiceRest/updateReport`
export const getInformById = () => `${apiURL}/ReportServiceRest/getReportById`
export const downloadInformByName = () => `${apiURL}/ReportServiceRest/downloadReportByName`
export const downloadWorkplan = () => `${apiURL}/ReportServiceRest/downloadWorkPlan`
export const downloadFinalReport = () => `${apiURL}/ReportServiceRest/downloadFinalReport`
export const sendEmailLoadInform = () => `${apiURL}/ReportServiceRest/sendEmailLoadReport`
export const sendEmailRateInform = () => `${apiURL}/ReportServiceRest/sendEmailRateReport`

export const insertEvidence = () => `${apiURL}/EvidenceServiceRest/insertEvidence`

export const generateCertificate = () => `${apiURL}/CertificateServiceRest/generateCertificate`
export const sendEmailCertificate = () => `${apiURL}/CertificateServiceRest/sendEmailCertificate`
export const downloadCertificateByName = () => `${apiURL}/CertificateServiceRest/downloadCertificateByName`
export const downloadCertificateByUuid = () => `${apiURL}/CertificateServiceRest/downloadCertificateByUuid`

export const insertBeneficiary = () => `${apiURL}/BeneficiaryServiceRest/insertBeneficiary`
export const updateBeneficiary = () => `${apiURL}/BeneficiaryServiceRest/updateBeneficiary`
export const getBeneficiaryById = () => `${apiURL}/BeneficiaryServiceRest/getBeneficiaryById`
export const getBeneficiariesByPostulant = () => `${apiURL}/BeneficiaryServiceRest/getBeneficiariesByPostulant`
export const deleteBeneficiary = () => `${apiURL}/BeneficiaryServiceRest/deleteBeneficiary`
export const validateBeneficiary = () => `${apiURL}/BeneficiaryServiceRest/validateBeneficiary`
export const downloadBeneficiariesReport = () => `${apiURL}/BeneficiaryServiceRest/downloadBeneficiariesReport`