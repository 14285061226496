import React, { useState } from 'react'
import { Col, Row, Space, Button, Form, Select, InputNumber, Input, Switch, Alert } from 'antd'
import { getCatalogos } from '../../utils/Utils'
import { getLabel, labels } from '../../utils/Internationalization'

const { Option } = Select

const renderPaisValor = e => <Option key={e.catValor} value={e.catId}>
    <>
        <img
            alt={e.catNombre}
            src={`https://flagcdn.com/24x18/${e.catDescripcion.substr(0, 2).toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/48x36/${e.catDescripcion.substr(0, 2).toLowerCase()}.png 2x,https://flagcdn.com/72x54/${e.catDescripcion.substr(0, 2).toLowerCase()}.png 3x`}
            width="24"
            height="18"
        ></img>
        &nbsp;{
            e.catNombre
        }
    </>
</Option>

const Beneficiary = ({ type, beneficiary, saveBeneficiary, isSaving, closeBeneficiary }) => {

    const [totalEmpleados, setTotalEmpleados] = useState(beneficiary.benNumPer || 0)
    const [totalEmpleadosH, setTotalEmpleadosH] = useState(beneficiary.benHombres || 0)
    const [totalEmpleadosM, setTotalEmpleadosM] = useState(beneficiary.benMujeres || 0)

    const totalValido = () => totalEmpleados === totalEmpleadosH + totalEmpleadosM

    const [formBeneficiary] = Form.useForm()

    if (type === 1)
        return (
            <>
                {
                    beneficiary &&
                    <>
                        <Form
                            form={formBeneficiary}
                            layout={'vertical'}
                            initialValues={beneficiary}
                            onFinish={saveBeneficiary}
                            disabled={isSaving || beneficiary.editDisabled}
                        >
                            <div style={{ display: 'none' }}>
                                <Form.Item
                                    name={['benId']}
                                    label='ID'
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={['idTipo']}
                                    label='Type Person'
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={['idCurso']}
                                    label='Course'
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={['idPostulante']}
                                    label='Postulante'
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <Form.Item
                                name={['benDocumento']}
                                label={getLabel(labels.idNumber)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeID)
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['benNombres']}
                                label={getLabel(labels.name)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeFirstName)
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['idPais']}
                                label={getLabel(labels.country)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.selectCountry)
                                    },
                                ]}
                            >
                                <Select name='idPais'>
                                    {
                                        getCatalogos().paises.map(p => renderPaisValor(p))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={['benCiudad']}
                                label={getLabel(labels.city)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeCity)
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['idSexo']}
                                label={getLabel(labels.sex)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.selectSex)
                                    },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: '100%',
                                        display: 'inline-block'
                                    }}
                                    fieldNames={{
                                        label: 'catNombre',
                                        value: 'catId'
                                    }}
                                    showSearch
                                    options={getCatalogos().generos}
                                    optionFilterProp="catNombre"
                                />
                            </Form.Item>
                            <Form.Item
                                name='benTrabaja'
                                label={getLabel(labels.currentyWorking)}
                                valuePropName='checked'
                                rules={[
                                    {
                                        required: true
                                    },
                                ]}
                            >
                                <Switch />
                            </Form.Item>
                            <Form.Item
                                name={['idNivelInt']}
                                label={getLabel(labels.instructionLevel)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeInstructionLevel)
                                    },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: '100%',
                                        display: 'inline-block'
                                    }}
                                    fieldNames={{
                                        label: 'catNombre',
                                        value: 'catId'
                                    }}
                                    showSearch
                                    options={getCatalogos().educationLevels}
                                    optionFilterProp="catNombre"
                                />
                            </Form.Item>
                        </Form>
                        <Space>
                            <Button onClick={closeBeneficiary}
                                disabled={isSaving}
                            >{getLabel(labels.cancel)}</Button>
                            <Button type="primary"
                                disabled={isSaving || beneficiary.editDisabled}
                                loading={isSaving}
                                onClick={() => {
                                    formBeneficiary.submit()
                                }}>
                                {
                                    isSaving ? getLabel(labels.saving) : getLabel(labels.save)
                                }
                            </Button>
                        </Space>
                    </>
                }
            </>
        )

    if (type === 2)
        return (
            <>
                {
                    beneficiary &&
                    <>
                        <Form
                            form={formBeneficiary}
                            layout={'vertical'}
                            initialValues={beneficiary}
                            onFinish={saveBeneficiary}
                            disabled={isSaving || beneficiary.editDisabled}
                        >
                            <div style={{ display: 'none' }}>
                                <Form.Item
                                    name={['benId']}
                                    label='ID'
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={['idTipo']}
                                    label='Type Person'
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={['idCurso']}
                                    label='Course'
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    name={['idPostulante']}
                                    label='Postulante'
                                    rules={[
                                        {
                                            required: true
                                        }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <Form.Item
                                name={['benDocumento']}
                                label={getLabel(labels.idNumber)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeID)
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['benNombres']}
                                label={getLabel(labels.name)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeFirstName)
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['idPais']}
                                label={getLabel(labels.country)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.selectCountry)
                                    },
                                ]}
                            >
                                <Select name='idPais'>
                                    {
                                        getCatalogos().paises.map(p => renderPaisValor(p))
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={['benCiudad']}
                                label={getLabel(labels.city)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeCity)
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['idSexo']}
                                label={getLabel(labels.sex)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.selectSex)
                                    },
                                ]}
                            >
                                <Select
                                    style={{
                                        width: '100%',
                                        display: 'inline-block'
                                    }}
                                    fieldNames={{
                                        label: 'catNombre',
                                        value: 'catId'
                                    }}
                                    showSearch
                                    options={getCatalogos().generos}
                                    optionFilterProp="catNombre"
                                />
                            </Form.Item>
                            <Form.Item
                                name={['benIdEmpresa']}
                                label={getLabel(labels.companyID)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeCompanyID)
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['benNomEmpresa']}
                                label={getLabel(labels.company)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeCompany)
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['benCargo']}
                                label={getLabel(labels.jobTitle)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeJobTitle)
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                name={['benNumPer']}
                                label={getLabel(labels.totalCollaborators)}
                                rules={[
                                    {
                                        required: true,
                                        message: getLabel(labels.completeTotalCollaborators)
                                    }
                                ]}
                            >
                                <InputNumber
                                    style={{
                                        width: '100%',
                                        display: 'inline-block'
                                    }}
                                    onChange={(e) => {
                                        setTotalEmpleados(e)
                                    }}
                                />
                            </Form.Item>
                            <Row gutter={[10]}>
                                <Col span={12}>
                                    <Form.Item
                                        name={['benHombres']}
                                        label={getLabel(labels.men)}
                                        rules={[
                                            {
                                                required: true,
                                                message: getLabel(labels.numberMen)
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            style={{
                                                width: '100%',
                                                display: 'inline-block'
                                            }}
                                            onChange={(e) => {
                                                setTotalEmpleadosH(e)
                                            }}
                                            min={0}
                                            max={(totalEmpleados || 0) - (totalEmpleadosM || 0)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name={['benMujeres']}
                                        label={getLabel(labels.women)}
                                        rules={[
                                            {
                                                required: true,
                                                message: getLabel(labels.numberWomen)
                                            }
                                        ]}
                                    >
                                        <InputNumber
                                            style={{
                                                width: '100%',
                                                display: 'inline-block'
                                            }}
                                            onChange={(e) => {
                                                setTotalEmpleadosM(e)
                                            }}
                                            min={0}
                                            max={(totalEmpleados || 0) - (totalEmpleadosH || 0)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {
                                !totalValido() &&
                                <Row gutter={[10]}>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Alert type='warning' showIcon message={`${getLabel(labels.theSum)} ${getLabel(labels.men)} + ${getLabel(labels.women)} ${getLabel(labels.mustEqual)} ${getLabel(labels.totalCollaborators)}`} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                        </Form>
                        <Space>
                            <Button onClick={closeBeneficiary}
                                disabled={isSaving}
                            >{getLabel(labels.cancel)}</Button>
                            <Button type="primary"
                                disabled={isSaving || beneficiary.editDisabled || !totalValido()}
                                loading={isSaving}
                                onClick={() => {
                                    formBeneficiary.submit()
                                }}>
                                {
                                    isSaving ? getLabel(labels.saving) : getLabel(labels.save)
                                }
                            </Button>
                        </Space>
                    </>
                }
            </>
        )

}

export default Beneficiary