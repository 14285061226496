import AxiosInstance from "../utils/AxiosInstance";
import { getsTrainingByTrainer, getTrainingById, insertTraining, updateTraining } from "../utils/urls";

export default class TrainingService {
    getByTraining = (props) => {
        const { navigate, trainer } = props
        return AxiosInstance(navigate).post(getsTrainingByTrainer(), trainer)
    }
    getById = (props) => {
        const { navigate, training } = props
        return AxiosInstance(navigate).post(getTrainingById(), training)
    }
    put = (props) => {
        const { navigate, training } = props
        return AxiosInstance(navigate).put(insertTraining(), training)
    }
    set = (props) => {
        const { navigate, training } = props
        return AxiosInstance(navigate).patch(updateTraining(), training)
    }
    pub = (props) => {
        const { navigate, training } = props
        return AxiosInstance(navigate).patch(updateTraining(), training)
    }
}