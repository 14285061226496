import { format } from 'date-fns'
import { es } from 'date-fns/esm/locale'
import locale from "antd/es/date-picker/locale/es_ES"
import dayjs from 'dayjs'

export const disabledDate = (current) => {
    return current && current >= dayjs().startOf('day')
}
export const getFormatoFecha = () => "YYYY-MM-DD"
export const formatoMoneda = (v) => {
    const formater = new Intl.NumberFormat('es-EC', {
        style: 'currency',
        currency: 'USD'
    })
    return formater.format(v)
}
export const formatoFechas = f => {
    const formater = new Intl.DateTimeFormat('es-EC', {
        year: 'numeric',
        month: 'long',
        day: '2-digit'
    })
    return formater.format(new Date(f.toString().substring(0, 10).split('-')[0], f.toString().substring(0, 10).split('-')[1] - 1, f.toString().substring(0, 10).split('-')[2]))
}
export const toCapitalize = (o) => o.toLowerCase().split(' ').map(r => `${r.substring(0, 1).toUpperCase()}${r.substring(1, r.length)} `)
export const formatoFecha = (f) => format(new Date(f.toString().substring(0, 10).split('-')[0], f.toString().substring(0, 10).split('-')[1] - 1, f.toString().substring(0, 10).split('-')[2]), "dd MMMM yyyy", { locale: es })
export const formatoFechaDate = (f) => format(f, "dd/MM/yyyy", { locale: es })
export const getToken = () => localStorage.getItem('mgeToken') || ''
export const getDatosUsuario = () => localStorage.getItem('mgeDatosUsuario') ? JSON.parse(localStorage.getItem('mgeDatosUsuario')) : { opciones: [] }
export const getCatalogos = () => localStorage.getItem('mgeCatalogos') ? JSON.parse(localStorage.getItem('mgeCatalogos')) : {}
export const setTrainingInforms = (trainings) => localStorage.setItem('mgeTrainings', JSON.stringify(trainings))
const getTrainingInforms = () => localStorage.getItem('mgeTrainings') ? JSON.parse(localStorage.getItem('mgeTrainings')) : []
// export const setInforms = (informs) => localStorage.setItem('mgeInformList', JSON.stringify(informs))
// const getInforms = () => localStorage.getItem('mgeInformList') ? JSON.parse(localStorage.getItem('mgeInformList')) : []
const getInforms = () => {
    let informs = []
    getTrainingInforms().forEach(t => t.informeList.forEach(i => informs.push(i)))
    return informs
}
const getOpciones = (opciones, opcion) => {
    return opciones
        .filter(o => o.idOpcionPadre === opcion.idOpcion).length > 0 ?
        opciones
            .filter(o => o.idOpcionPadre === opcion.idOpcion)
            .map(o => ({ label: toCapitalize(o.etiquetaOpcion), to: o.actionOpcion, icon: o.icono }))
        : null
}
export const getOpcionesMenu = () => {
    const informs = getInforms()
    let registry = false
    let qualification = false
    let opciones = getDatosUsuario().opciones || []

    //  Si para cada curso de cada entranamiento existe al menos un informe (WP o FR) se muestran las opciones de beneficiarios para registro y calificación.
    if (informs.length > 1) {

        // Si para cada curso de cada entrenamiento existe al menos un WP aprobado y no existe FR aprobado se habilita el registro de beneficiarios.
        getTrainingInforms().forEach(t => {
            t.entId.cursoList.forEach(c => {
                if (!registry && t.informeList.filter(i => i.idCurso === c.curId).filter(w => w.infTipo.catValor === 'TYP_INF_WPL' && w.infEstado.catValor === 'EST_INF_APR').length > 0
                    && t.informeList.filter(i => i.idCurso === c.curId).filter(w => w.infTipo.catValor === 'TYP_INF_FIR' && w.infEstado.catValor === 'EST_INF_APR').length < 1) {
                    registry = true
                }
            })
        })
        opciones = getDatosUsuario().opciones.map(o => (o.actionOpcion.indexOf('/beneficiaries/registry') >= 0 ? { ...o, visible: registry } : { ...o }))

        // Si para cada curso de cada entrenamiento existe al menos un WP aprobado y un FR aprobado se la calificación de beneficiarios.
        getTrainingInforms().forEach(t => {
            t.entId.cursoList.forEach(c => {
                if (!qualification && t.informeList.filter(i => i.idCurso === c.curId).filter(w => w.infTipo.catValor === 'TYP_INF_WPL' && w.infEstado.catValor === 'EST_INF_APR').length > 0
                    && t.informeList.filter(i => i.idCurso === c.curId).filter(w => w.infTipo.catValor === 'TYP_INF_FIR' && w.infEstado.catValor === 'EST_INF_APR').length > 0) {
                    qualification = true
                }
            })
        })
        opciones = opciones.map(o => (o.actionOpcion.indexOf('/beneficiaries/qualification') >= 0 ? { ...o, visible: qualification } : { ...o }))

    }

    const opcionesSubOpciones = opciones
        .filter(o => o.idOpcionPadre === undefined && o.visible === true)
        .map(o => ({ ...o, items: getOpciones(opciones, o) }))
        .map(o => ({ label: toCapitalize(o.etiquetaOpcion), to: o.actionOpcion, icon: o.icono, items: o.items }))
    return opcionesSubOpciones ? opcionesSubOpciones : []
}
export const getLocale = () => locale
export const getCultures = () => [
    {
        id: 'es_EC',
        value: 'Español'
    },
    {
        id: 'en_US',
        value: 'English'
    }
]
export const getSortInforms = (informs) => informs.sort((a, b) => {
    if (a.infFechaAprRec > b.infFechaAprRec) {
        return 1;
    }
    if (a.infFechaAprRec < b.infFechaAprRec) {
        return -1;
    }
    return 0;
})
export const getInformStatus = (informs) => {
    const sortInforms = getSortInforms(informs)
    return sortInforms[sortInforms.length - 1].infEstado.catValor === 'EST_INF_ENV' ? 'send.png' : sortInforms[sortInforms.length - 1].infEstado.catValor === 'EST_INF_APR' ? 'accept.png' : sortInforms[sortInforms.length - 1].infEstado.catValor === 'EST_INF_REC' ? 'cancel.png' : 'minus.png'
}
export const getTypeContent = (fileName) => fileName.indexOf('.doc') > 0 ? 'application/msword'
    : fileName.indexOf('.docx') > 0 ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        : fileName.indexOf('.xls') > 0 ? 'application/vnd.ms-excel'
            : fileName.indexOf('.xlsx') > 0 ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                : fileName.indexOf('.jpeg') > 0 ? 'image/jpeg'
                    : fileName.indexOf('.jpg') > 0 ? 'image/jpeg'
                        : fileName.indexOf('.png') > 0 ? 'image/png'
                            : fileName.indexOf('.pdf') > 0 ? 'application/pdf'
                                : fileName.indexOf('.rar') > 0 ? 'application/x-rar-compressed'
                                    : 'application/octet-stream'