import React, { useEffect, useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Col, Row, Button, Result, Form, Input, Checkbox, Spin, Typography, Divider, message, Popconfirm, Space, InputNumber, Table, Switch } from 'antd'
import * as anticons from '@ant-design/icons'

import { getLabel, labels } from '../../utils/Internationalization'

import PerfilService from '../../service/PerfilService'
import OpcionService from '../../service/OpcionService'
import PerfilOpcionService from '../../service/PerfilOpcionService'

const perfilService = new PerfilService()
const opcionService = new OpcionService()
const perfilOpcionService = new PerfilOpcionService()

const Perfil = () => {

  const navigate = useHistory()
  const { id } = useParams()
  const [load, setLoad] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingOpciones, setIsLoadingOpciones] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false);
  const [perfil, setPerfil] = useState({})
  const [error, setError] = useState(null)
  const [form] = Form.useForm();

  const [opciones, setOpciones] = useState([])

  const columns = [
    {
      title: 'Option',
      dataIndex: 'opcNombre',
      key: 'idOpcion',
    },
    {
      title: 'Read',
      dataIndex: 'opcLectura',
      render: (text, record, index) => (
        <Switch onChange={() => onChangePerfil(text, record, 'opcLectura')} defaultChecked={record.opcLectura} />
      )
    }
    ,
    {
      title: 'Write',
      dataIndex: 'opcEscritura',
      render: (text, record, index) => (
        <Switch onChange={() => onChangePerfil(text, record, 'opcEscritura')} defaultChecked={record.opcEscritura} />
      )
    }
  ];

  const onChangePerfil = async (text, record, operacion) => {
    if (operacion === 'opcLectura') {
      record.opcLectura = !record.opcLectura
    } else if (operacion === 'opcEscritura') {
      record.opcEscritura = !record.opcEscritura
    }
    setIsSaving(true)
    try {
      const { data } = await perfilOpcionService.set({ navigate, perfilOpcion: record })
      setIsSaving(false)
      const perfilOpcionActualizado = data.listObject[0]
      message.success(`${getLabel(labels.option)} ${perfilOpcionActualizado.pfoOpcId.opcNombre} ${getLabel(labels.successfullyUpdated)}`)
    }
    catch (error) {
      setIsSaving(false)
      setError(getLabel(labels.errorOcurred))
    }
  }

  const onChange = (e) => {
    setPerfil({ ...perfil, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
  }

  const onChangePerfilNivel = (e) => {
    setPerfil({ ...perfil, perNivel: e })
  }

  const getPerfil = async () => {
    setLoad(false)
    setIsLoading(true)
    try {
      const { data } = await perfilService.get({ navigate, perfil: { perId: id } })
      if (data) {
        setPerfil(data.listObject[0])
        setError(null)
        getOpcionesPerfil()
      } else {
        setError(getLabel(labels.errorOcurred))
      }
    } catch (error) {
      const { response } = error
      setError(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    } finally {
      setIsLoading(false)
    }
  }

  const getOpcionesPerfil = async () => {
    setIsLoadingOpciones(true)
    try {
      const { data } = await perfilOpcionService.gets({ navigate })
      if (data) {
        var _opcionesPerfil = data.listObject[0].filter(o => o.idPerfil === parseInt(id))
        setError(null)

        try {
          const { data } = await opcionService.gets({ navigate })
          if (data) {
            const _opciones = data.listObject.filter(o => o.opcNivel === 1)
              .map(o => (
                {
                  key: `${o.opcId}`,
                  opcNombre: o.opcNombre,
                  ..._opcionesPerfil.filter(op => op.idOpcion === o.opcId)[0],
                }
              ))
            // .map(o => (
            //   {
            //     key: `${o.opcId}`,
            //     opcNombre: o.opcNombre,
            //     ..._opcionesPerfil.filter(op => op.idOpcion === o.opcId)[0],
            //     children: o.opcHijos.map(o => ({
            //       key: `${o.opcId}`,
            //       opcNombre: o.opcNombre,
            //       ..._opcionesPerfil.filter(op => op.idOpcion === o.opcId)[0]
            //     }))
            //   }
            // ))
            setOpciones(_opciones)
            setError(null)
            setIsLoadingOpciones(false)
          } else {
            setIsLoadingOpciones(false)
            setError(getLabel(labels.errorOcurred))
          }
        } catch (error) {
          setIsLoadingOpciones(false)
          setError(getLabel(labels.errorOcurred))
        }

      } else {
        setError(getLabel(labels.errorOcurred))
      }
    } catch (error) {
      const { response } = error
      setError(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    } finally {
      setIsLoadingOpciones(false)
    }
  }

  const guardar = async (e) => {
    setIsSaving(true)
    try {
      const { data } = await perfilService.set({ navigate, perfil })
      setIsSaving(false)
      const perfilActualizado = data.listObject[0]
      setPerfil(perfilActualizado)
      message.success(getLabel(labels.profileSavedSuccessfully))
    }
    catch (error) {
      setIsSaving(false)
      setError(getLabel(labels.errorOcurred))
    }
  }

  const eliminar = async (e) => {
    setIsDeleting(true)
    try {
      const { data } = await perfilService.del({ navigate, perfil })
      setIsDeleting(false)
      if (data === true) {
        message.success(getLabel(labels.profileDeletedSuccessfully))
        navigate.push('/perfiles')
      } else {
        message.error(getLabel(labels.errorOcurred))
      }
    } catch (error) {
      setIsDeleting(false)
      const { response } = error
      setError(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    }
  }

  useEffect(() => {
    if (load) {
      getPerfil()
    }
  })

  if (error) {
    return (
      <Result
        status="warning"
        title={''}
        subTitle={''}
        extra={
          <>
            <h5>{error}</h5>
            <Link to={'/perfiles'}>{getLabel(labels.backHome)}</Link>
          </>
        }
      />
    )
  }

  return (
    <>
      <Row align='middle' gutter={10}>
        <Col>
          <Link to={'/perfiles'}><anticons.ArrowLeftOutlined /></Link>
        </Col>
        <Col>
          <Typography.Title
            level={4}
            style={{
              margin: 0,
            }}
          >
            {getLabel(labels.profile)} {perfil && perfil.perNombre}
          </Typography.Title>
        </Col>
      </Row>
      <Divider></Divider>
      {
        isLoading ?
          <div className="p-10 text-center">
            <Spin size="large" /><br />
            <Typography.Text>{getLabel(labels.loading)}...</Typography.Text>
          </div> :
          <Form
            layout={'vertical'}
            form={form}
            initialValues={{
              layout: 'vertical',
            }}
          >
            <Row gutter={10}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label={getLabel(labels.name)}>
                  <Input id="perNombre" name="perNombre" type='text' value={perfil.perNombre} onChange={onChange} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label={getLabel(labels.level)}>
                  <InputNumber id="perNivel" name="perNivel" min={1} max={50} value={perfil.perNivel} onChange={onChangePerfilNivel} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form.Item label={getLabel(labels.state)}>
                  <Checkbox id='perEstado' name='perEstado' onChange={onChange} checked={perfil.perEstado}>
                    {
                      perfil.perEstado ? getLabel(labels.active) : getLabel(labels.inactive)
                    }
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            <Divider>{getLabel(labels.options)}</Divider>
            <Row>
              <Col>
                <Table
                  columns={columns}
                  dataSource={opciones}
                  loading={isLoadingOpciones || isSaving}
                />
              </Col>
            </Row>
            <Row justify='end'>
              <Col>
                <Form.Item>
                  <Space>

                    <Button type="primary" onClick={(e) => guardar(e)}
                      disabled={isSaving || isDeleting}
                      loading={isSaving}
                    >
                      {isSaving ? getLabel(labels.saving) : getLabel(labels.save)}
                    </Button>
                    <Popconfirm title={getLabel(labels.sureDeleteProfile)} okText={getLabel(labels.yes)} cancelText={getLabel(labels.no)} onConfirm={(e) => eliminar(e)}
                      okButtonProps={{
                        loading: isDeleting,
                      }}
                    >
                      <Button type="danger"
                        disabled={isDeleting || isSaving}
                        loading={isDeleting}
                      >
                        {isDeleting ? getLabel(labels.deleting) : getLabel(labels.delete)}
                      </Button>
                    </Popconfirm>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
          </Form>
      }
    </>
  )
}

export default Perfil