import React, { useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import AppMenu from "./AppMenu";
import { useHistory } from "react-router-dom";
import { Row, Col, Typography } from "antd";
import { getDatosUsuario } from "./utils/Utils";
import { getCulture, getLabel, labels } from "./utils/Internationalization";

const AppTopbar = (props) => {
  const history = useHistory();
  const searchPanelDesktop = useRef(null);
  const searchPanelMobile = useRef(null);

  useEffect(() => {
    if (props.searchActive) {
      if (window.innerWidth >= 576) searchPanelDesktop.current.focus();
      else searchPanelMobile.current.focus();
    }
  }, [props.searchActive]);

  const datosUsuario = (e) => {
    history.push("/myaccount");
  };

  const goResetPassword = () => history.push("/resetPassword");

  const goLanguagePreferences = () => history.push("/language");

  const cerrarSesion = (e) => {
    localStorage.removeItem("mgeToken");
    localStorage.removeItem("mgeDatosUsuario");
    localStorage.removeItem("mgeTrainings");
    localStorage.removeItem("mgeInformList");
    history.push("/login");
  };

  const logo = {
    fontSize: "1.5em",
    padding: 0,
    margin: 0,
  };

  return (
    <div className="layout-topbar">
      <div className="layout-topbar-wrapper">
        <div className="layout-topbar-left">
          <button
            tabIndex="0"
            className="menu-button p-link"
            onClick={props.onMenuButtonClick}
          >
            <i className="pi pi-bars"></i>
          </button>
          <div id="logo-link" className="layout-topbar-logo">
            <span className="mge mge-bold mge-lightGreen" style={logo}>
              {getCulture() === "en_US" ? "MG" : "MN"}
            </span>
            <span className="mge mge-bold mge-green" style={logo}>
              {getCulture() === "en_US" ? "E" : "V"}
            </span>
          </div>
        </div>

        <AppMenu
          menuMode={props.menuMode}
          sidebarActive={props.sidebarActive}
          sidebarStatic={props.sidebarStatic}
          model={props.menu}
          menuActive={props.menuActive}
          onRootMenuItemClick={props.onRootMenuItemClick}
          onMobileMenuActive={props.onMobileMenuActive}
          onMenuItemClick={props.onMenuItemClick}
          onSidebarMouseOver={props.onSidebarMouseOver}
          onSidebarMouseLeave={props.onSidebarMouseLeave}
          onToggleMenu={props.onToggleMenu}
          resetActiveIndex={props.resetActiveIndex}
          onMenuClick={props.onMenuClick}
        />

        <div className="layout-topbar-right">
          <ul className="layout-topbar-actions">
            <li
              className={classNames("topbar-item user-profile", {
                "active-topmenuitem fadeInDown": props.topbarUserMenuActive,
              })}
            >
              <button
                className="p-link"
                style={{ cursor: "pointer" }}
                onClick={props.onTopbarUserMenuClick}
              >
                {getDatosUsuario() && (
                  <Row>
                    <Col
                      style={{
                        paddingRight: "15px",
                        paddingTop: "5px",
                        textAlign: "right",
                        lineHeight: "1em",
                      }}
                    >
                      <Typography.Text style={{ fontSize: "1.2em" }}>
                        {`${getDatosUsuario().nombres || ""} ${getDatosUsuario().apellidos || ""
                          }`}
                        {getDatosUsuario() && getDatosUsuario().perfiles && (
                          <>
                            <br />
                            <Typography.Text
                              type="secondary"
                              style={{ fontSize: "0.6em", lineHeight: "1em" }}
                            >
                              {`${getDatosUsuario().perfiles[0].perNombre}`}
                            </Typography.Text>
                          </>
                        )}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <img alt="Profile" src="./assets/account.png" />
                    </Col>
                  </Row>
                )}
              </button>
              <ul className="fadeInDown">
                <li onClick={datosUsuario}>
                  <Row gutter={[10, 0]}>
                    <Col>
                      <i
                        className="fa-solid fa-user-gear"
                        style={{ fontSize: "1.5em" }}
                      ></i>
                    </Col>
                    <Col>
                      <button className="p-link">
                        <span>{getLabel(labels.myAccount)}</span>
                      </button>
                    </Col>
                  </Row>
                </li>
                <li onClick={goResetPassword}>
                  <Row gutter={[10, 0]}>
                    <Col>
                      <i
                        className="fa-solid fa-key"
                        style={{ fontSize: "1.5em" }}
                      ></i>
                    </Col>
                    <Col>
                      <button className="p-link">
                        <span>{getLabel(labels.resetPassword)}</span>
                      </button>
                    </Col>
                  </Row>
                </li>
                <li onClick={goLanguagePreferences}>
                  <Row gutter={[10, 0]}>
                    <Col>
                      <i
                        className="fa-solid fa-language"
                        style={{ fontSize: "1.5em" }}
                      ></i>
                    </Col>
                    <Col>
                      <button className="p-link">
                        <span>{getLabel(labels.languagePreferences)}</span>
                      </button>
                    </Col>
                  </Row>
                </li>
                <li onClick={cerrarSesion}>
                  <Row gutter={[10, 0]}>
                    <Col>
                      <i
                        className="fa-solid fa-right-from-bracket"
                        style={{ fontSize: "1.5em" }}
                      ></i>
                    </Col>
                    <Col>
                      <button className="p-link">
                        <span>{getLabel(labels.signOut)}</span>
                      </button>
                    </Col>
                  </Row>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AppTopbar;
