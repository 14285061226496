import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Form, Input, Select, DatePicker, Switch, Button, Typography, Tooltip, Space, Spin, Upload } from 'antd'
import * as anticons from '@ant-design/icons'

import { getLabel, labels } from '../../utils/Internationalization'
import { disabledDate, getCatalogos, getFormatoFecha } from './../../utils/Utils'

import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import moment from 'moment'


import UsuarioService from '../../service/UsuarioService'
const usuarioService = new UsuarioService()

const { Option } = Select

const User = (props) => {

  const { user, navigate } = props
  const { id } = user
  const [formUser] = Form.useForm()

  const [load, setLoad] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [usuario, setUsuario] = useState(null)
  const [currentlyWorking, setCurrentlyWorking] = useState(true)

  const [photoFile, setPhotoFile] = useState();
  const [signatureFile, setSignatureFile] = useState();

  const [fileList, setFileList] = useState([])
  const [fileSignatureList, setSignatureFileList] = useState([])
  const [photoFormatValid, setPhotoFormatValid] = useState(2)

  const propsUpload = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setPhotoFile(null);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      setPhotoFile(URL.createObjectURL(file));
      return false;
    },
    fileList,
  }

  const propsSignature = {
    onRemove: (file) => {
      const index = fileSignatureList.indexOf(file);
      const newFileList = fileSignatureList.slice();
      newFileList.splice(index, 1);
      setSignatureFileList(newFileList);
      setSignatureFile(null);
      setPhotoFormatValid(2)
    },
    beforeUpload: (file) => {
      setSignatureFileList([...fileSignatureList, file]);
      setSignatureFile(URL.createObjectURL(file));
      const isPNG = file.type === 'image/png';
      setPhotoFormatValid(isPNG ? 1 : 0);
      return false;
    },
    fileSignatureList,
  }

  const renderPaisValor = e => <Option key={e.catValor} value={e.catId}>
    <>
      <img
        src={`https://flagcdn.com/24x18/${e.catDescripcion.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/48x36/${e.catDescripcion.toLowerCase()}.png 2x,https://flagcdn.com/72x54/${e.catDescripcion.toLowerCase()}.png 3x`}
        width="24"
        height="18"
        alt={e.catNombre}></img>
      &nbsp;{
        e.catNombre
      }
    </>
  </Option>

  const renderPaisCode = e => <Option key={e.catValor} value={e.catValor}>
    <>
      +({e.catValor})&nbsp;
      <img
        src={`https://flagcdn.com/24x18/${e.catDescripcion.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/48x36/${e.catDescripcion.toLowerCase()}.png 2x,https://flagcdn.com/72x54/${e.catDescripcion.toLowerCase()}.png 3x`}
        width="24"
        height="18"
        alt={e.catDescripcion}></img>
    </>
  </Option>

  const saveCurrentlyWorking = (value) => {
    setUsuario({ ...usuario, trabaja: value })
    setCurrentlyWorking(value)
  }

  const update = async (values) => {
    const usuario = {
      ...values
      , trabaja: currentlyWorking
      , codigotel: values.countryCode.catValor
      , sexoId: values.sexo.catId
      , paisId: values.pais.catId
      , estadoId: values.estado.catId
      , lugartrabajo: currentlyWorking ? values.lugartrabajo : ''
    }
    setIsSaving(true)
    try {
      const { data } = await usuarioService.set({ navigate, usuario })
      const usuarioActualizado = data.listObject[0]
      setUsuario(usuarioActualizado)
      if (fileList.length > 0 || fileSignatureList.length > 0) {
        const requests = []

        let datos = new FormData();

        if (fileList.length > 0) {
          datos.append('file', fileList[0])
          datos.append('usuId', usuarioActualizado.usuId)
          requests.push(usuarioService.upLoadPhoto({ datos }))
        }

        datos = new FormData();
        if (fileSignatureList.length > 0) {
          datos.append('file', fileSignatureList[0])
          datos.append('usuId', usuarioActualizado.usuId)
          requests.push(usuarioService.uploadSignature({ navigate, datos }))
        }

        if (requests.length > 0) {
          Promise.all(requests)
            .then((results) => {
              if (results.length > 0) {
                setIsSaving(false)
                toast.success(getLabel(labels.userUpdatedSuccessfully))
              }
            })
            .catch(error => {
              setIsSaving(false)
              const { response } = error
              const msgError = response.status === 409 ? response.data[0].messageText : response.data
              toast.error(msgError)
            })
        }
      } else {
        setIsSaving(false)
        toast.success(getLabel(labels.accountUpdatedSuccessfully))
      }
    }
    catch (error) {
      setIsSaving(false)
      toast.error(getLabel(labels.errorOcurred))
    }
  }

  const fetchData = async () => {
    setLoad(false)
    setIsLoading(true)
    try {
      const { data } = await usuarioService.get({ navigate, usuario: { usuId: id } })
      if (data) {
        const usuario = data.listObject[0]
        const newUsuario = {
          ...usuario
          , countryCode: {
            catValor: usuario.codigotel.replace('+', '')
          }
          , fechanac: moment(dayjs(usuario.fechanac.substring(0, 10), getFormatoFecha()).$d)
        }
        setUsuario(newUsuario)
        setCurrentlyWorking(newUsuario.trabaja)
      } else {
        toast.error(getLabel(labels.errorOcurred))
      }
    } catch (error) {
      const { response } = error
      toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (load) {
      fetchData()
    }
  })

  return (
    <>
      <div className="grid">
        <div className="col-12">
          <div className="mgeCard">
            <div className="grid">
              <div className="col-4">
                <h3 style={{ padding: '0', margin: '0' }} className='mge-darkGreen'>
                  {getLabel(labels.myAccount)}
                </h3>
              </div>
              <div className="col-8" style={{ textAlign: 'right' }}>
                <Space>
                  <Button
                    type='default'
                    disabled={isLoading || isSaving}
                    onClick={() => {
                      window.history.go(-1)
                    }}
                  >
                    {getLabel(labels.cancel)}
                  </Button>
                  <Button
                    type='primary'
                    loading={isSaving}
                    disabled={isLoading || isSaving || (photoFormatValid === 0)}
                    onClick={() => { formUser.submit() }}>
                    {getLabel(labels.save)}
                  </Button>
                </Space>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        isLoading ?
          <Row style={{ padding: '30px 10px' }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Spin size='large' tip={
                <Typography.Paragraph style={{ padding: '10px 0' }}>
                  {getLabel(labels.loading)}...
                </Typography.Paragraph>
              } />
            </Col>
          </Row>
          :
          usuario && <Row justify='center' style={{ paddingTop: '20px' }}>
            <Col xs={24} sm={24} md={22} lg={20} xl={18} xxl={16}>
              <Form
                form={formUser}
                layout={'vertical'}
                initialValues={usuario}
                onFinish={update}
                disabled={isSaving}
              >
                <Row gutter={[10, 0]}>
                  <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='file'
                      label={getLabel(labels.uploadProfilePicture)}
                    >
                      <Row gutter={[0, 10]}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                          {
                            photoFile ?
                              <Image src={photoFile} width={128} height={128} />
                              :
                              usuario.imagen ?
                                <Image src={
                                  `/images/${usuario.imagen.split('/')[usuario.imagen.split('/').length - 1]}`
                                } width={128} height={128} />
                                :
                                <Image src='./../assets/photo_profile.png' width={128} height={128} />
                          }
                        </Col>
                        <Col span={24} style={{ textAlign: 'center' }}>
                          <Upload
                            multiple={false}
                            {...propsUpload}
                          >
                            <Button
                              type='primary'
                              icon={<anticons.UploadOutlined />}
                              disabled={
                                fileList.length > 0
                              }
                            >
                              {getLabel(labels.selectPhoto)}
                            </Button>
                          </Upload>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={16} md={16} lg={16} xl={16} xxl={16}>
                    <Row gutter={[15, 0]}>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name='usuId'
                          label={getLabel(labels.idNumber)}
                          rules={[
                            {
                              required: true,
                              message: getLabel(labels.completeID),
                            },
                          ]}
                          style={{ display: 'none' }}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          name='nombres'
                          label={getLabel(labels.firstName)}
                          rules={[
                            {
                              required: true,
                              message: getLabel(labels.completeFirstName),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name='apellidos'
                          label={getLabel(labels.lastName)}
                          rules={[
                            {
                              required: true,
                              message: getLabel(labels.completeLastName),
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name={['sexo', 'catId']}
                          label={getLabel(labels.sex)}
                          rules={[
                            {
                              required: true,
                              message: getLabel(labels.selectSex),
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: '100%',
                              display: 'inline-block'
                            }}
                            fieldNames={{
                              label: 'catNombre',
                              value: 'catId'
                            }}
                            showSearch
                            options={getCatalogos().generos}
                            optionFilterProp="catNombre"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                        <Form.Item
                          name='fechanac'
                          label={getLabel(labels.dateBirth)}
                          rules={[
                            {
                              required: true,
                              message: getLabel(labels.selectDateBirth),
                            },
                          ]}
                        >
                          <DatePicker style={{ width: '100%' }} disabledDate={disabledDate} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='documento'
                      label={
                        <Space direction='horizontal'>
                          <>{getLabel(labels.idNumber)}</>
                          <Tooltip
                            title={
                              <>
                                {getLabel(labels.uniqueIdentificationCountry)}
                              </>
                            }
                            placement='right'
                          >
                            <anticons.InfoCircleFilled />
                          </Tooltip>
                        </Space>
                      }
                      rules={[
                        {
                          required: true,
                          message: getLabel(labels.completeID),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name={['pais', 'catId']}
                      label={getLabel(labels.country)}
                      rules={[
                        {
                          required: true,
                          message: getLabel(labels.selectCountry)
                        },
                      ]}
                    >
                      <Select name='paisId'>
                        {
                          getCatalogos().paises.map(p => renderPaisValor(p))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='ciudad'
                      label={getLabel(labels.city)}
                      rules={[
                        {
                          required: true,
                          message: getLabel(labels.completeCity),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name={['countryCode', 'catValor']}
                      label={
                        <span style={{ padding: '0 0 2px 0' }}>
                          {getLabel(labels.countryCode)}
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: getLabel(labels.selectCountryCode),
                        }
                      ]}
                    >
                      <Select name='codigotel'>
                        {
                          getCatalogos().paises.map(p => renderPaisCode(p))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='celular'
                      label={
                        <span style={{ padding: '0 0 2px 0' }}>
                          {getLabel(labels.phoneNumber)}
                        </span>
                      }
                      rules={[
                        {
                          required: true,
                          message: getLabel(labels.completePhoneNumber),
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                    <Form.Item
                      name='lugartrabajo'
                      label={
                        <Space direction='horizontal'>
                          <>{getLabel(labels.currentyWorking)}</>
                          <Tooltip
                            title={
                              <>
                                {getLabel(labels.completeNameInstitution)}
                              </>
                            }
                            placement='right'
                          >
                            <anticons.InfoCircleFilled />
                          </Tooltip>
                          <Switch
                            checked={usuario.trabaja}
                            onClick={(c) => {
                              saveCurrentlyWorking(c);
                            }}
                          />
                        </Space>
                      }
                      rules={[
                        {
                          required: usuario.trabaja,
                          message: getLabel(labels.ifYesCompleteNameInstitution)
                        },
                      ]}
                    >
                      <Input disabled={!usuario.trabaja} />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      name='email'
                      label={getLabel(labels.email)}
                      rules={[
                        {
                          required: true,
                          message: getLabel(labels.completeEmail),
                        },
                        {
                          type: 'email',
                          message: getLabel(labels.emailNotValid)
                        }
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Form.Item
                      name='email2'
                      label={getLabel(labels.additionalEmail)}
                      rules={[
                        {
                          required: true,
                          message: getLabel(labels.completeAdditionalEmail),
                        },
                        {
                          type: 'email',
                          message: getLabel(labels.additionalEmailNotValid)
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12} style={{ display: 'none' }}>
                    <Form.Item
                      name={['estado', 'catId']}
                      label={getLabel(labels.state)}
                      rules={[
                        {
                          required: true,
                          message: getLabel(labels.selectState),
                        },
                      ]}
                    >
                      <Select
                        style={{
                          width: '100%',
                          display: 'inline-block'
                        }}
                        fieldNames={{
                          label: 'catNombre',
                          value: 'catId'
                        }}
                        showSearch
                        options={getCatalogos().states}
                        optionFilterProp="catNombre"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={4}>
                    <Form.Item
                      name='file'
                      label={
                        <Typography.Paragraph>
                          {
                            getLabel(labels.uploadSignature)
                          }
                          {
                            usuario.firma === true ? <Typography.Text type='secondary' strong><br />{getLabel(labels.signatureAlreadyUploaded)}</Typography.Text> : <></>
                          }
                        </Typography.Paragraph>
                      }
                    >
                      <Row gutter={[0, 10]}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                          {
                            photoFormatValid !== 2 ?
                              photoFormatValid === 0 && <Typography.Text type='danger'>{getLabel(labels.signatureMustBePNG)}</Typography.Text> : <></>
                          }
                          {
                            signatureFile && photoFormatValid === 1 && <Image src={signatureFile} width={128} height={128} />
                          }
                        </Col>
                        <Col span={24} style={{ textAlign: 'center' }}>
                          <Upload
                            multiple={false}
                            {...propsSignature}
                          >
                            <Button
                              type='primary'
                              icon={<anticons.UploadOutlined />}
                              disabled={
                                fileSignatureList.length > 0
                              }
                            >{getLabel(labels.selectPhoto)}</Button>
                          </Upload>
                        </Col>
                      </Row>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row >
      }
    </>
  )
}

export default User