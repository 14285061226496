import React, { useState } from 'react'
import { Button, Checkbox, Form, Input, Select, Space, Typography } from 'antd'
import PasswordChecklist from 'react-password-checklist'
import { getLabel, labels } from '../../utils/Internationalization'

const { Paragraph, Text } = Typography

const RestorePassword = ({ user, resetPassword, isReseting, closeResetPassword }) => {

    const [password, setPassword] = useState('')
    const [validForm, setValidForm] = useState(false)
    const [sendNotification, setSendNotification] = useState(false)

    return (
        <>
            <Typography>
                <Paragraph>
                    <Text strong>
                        {user.nombres} {user.apellidos}
                    </Text>
                    <Text type='secondary'>
                        <br />{user.email}
                    </Text>
                </Paragraph>
            </Typography>
            <Form
                layout={'vertical'}
                initialValues={
                    { ...user, sendNotification }
                }
                onFinish={resetPassword}
                disabled={isReseting}
            >
                <Form.Item
                    style={{ display: 'none' }}
                    name='usuId'
                    label='Usuario'
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name='password'
                    label={getLabel(labels.password)}
                    rules={[
                        {
                            required: true,
                            message: getLabel(labels.completePassword)
                        }
                    ]}
                >
                    <Input.Password onChange={e => setPassword(e.target.value)} />
                </Form.Item>
                <PasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital"]}
                    minLength={8}
                    value={password}
                    messages={{
                        minLength: getLabel(labels.passwordHasMore8Characters),
                        specialChar: getLabel(labels.passwordHasSpecialCharacters),
                        number: getLabel(labels.passwordHasNumber),
                        capital: getLabel(labels.passwordHasCapitalLetter),
                    }}
                    onChange={(isValid) => { setValidForm(isValid) }}
                    style={{
                        padding: '0 0 20px 0',
                        display: validForm ? 'none' : 'block'
                    }}
                />
                <Form.Item
                    name='sendNotification'
                    valuePropName='checked'
                >
                    <Checkbox defaultChecked={false} onChange={e => setSendNotification(e.target.checked)}>
                        {getLabel(labels.sendNotification)}
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    name='email'
                    label={getLabel(labels.email)}
                    rules={[
                        {
                            required: sendNotification,
                            message: getLabel(labels.completeEmail)
                        }
                    ]}
                >
                    <Select
                        fieldNames={{
                            value: 'id',
                            label: 'value'
                        }}
                        showSearch
                        options={[
                            {
                                id: user.email,
                                value: user.email
                            },
                            {
                                id: user.email2,
                                value: user.email2
                            }
                        ]}
                    />
                </Form.Item>
                <Space>
                    <Button onClick={closeResetPassword}
                        disabled={isReseting}
                    >
                        {getLabel(labels.cancel)}
                    </Button>
                    <Button type="primary"
                        htmlType='submit'
                        disabled={isReseting}
                        loading={isReseting}>
                        {
                            isReseting ? getLabel(labels.resetting) : getLabel(labels.resetPassword)
                        }
                    </Button>
                </Space>
            </Form >
        </>
    )
}

export default RestorePassword