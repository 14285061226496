import React, { useState, useEffect, useRef } from "react";
import { classNames } from "primereact/utils";
import { Route, useLocation } from "react-router-dom";

import AppTopbar from "./AppTopbar";
import AppFooter from "./AppFooter";
import AppRightPanel from "./AppRightPanel";

import { Tooltip } from "primereact/tooltip";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";

import Usuarios from "./components/usuario";
import Usuario from "./components/usuario/Usuario";
import Opciones from "./components/opcion";
import Perfiles from "./components/perfil";
import Perfil from "./components/perfil/Perfil";

import Dashboard from "./components/dashboard/Dashboard";
import UserInfo from "./components/dashboard/UserInfo";
import UsuarioNew from "./components/usuario/UsuarioNew";
import MiCuenta from "./components/cuenta/MiCuenta";
import Courses from "./components/courses/Courses";
import Course from "./components/courses/Course";
import Applicants from "./components/Applicant/Applicants";
import Applicant from "./components/Applicant/Applicant";
import WorkPlan from "./components/workplan/WorkPlan";
import FinalReport from "./components/finalReport/FinalReport";
import Certificate from "./components/certificate/Certificate";
import Classes from "./components/classes/Classes";
import Training from "./components/training/Training";

import { getDatosUsuario, getOpcionesMenu } from "./utils/Utils";
import TrainingInfo from "./components/dashboard/TrainingInfo";
import TrainingApplicant from "./components/dashboard/TrainingApplicant";
import Beneficiaries from "./components/beneficiary/Beneficiaries";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ResetPasswordUser from "./pages/ResetPasswordUser";
import Language from "./pages/Language";

const App = (props) => {
  const [resetActiveIndex, setResetActiveIndex] = useState(null);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [sidebarStatic, setSidebarStatic] = useState(true);
  const [sidebarActive, setSidebarActive] = useState(true);
  const [menuActive, setMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);
  const [rightPanelActive, setRightPanelActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const copyTooltipRef = useRef();
  const location = useLocation();

  const menu = getOpcionesMenu();

  let menuClick;
  let rightPanelClick;
  let configClick;
  let searchClick;
  let topbarUserMenuClick;

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  useEffect(() => {
    setResetActiveIndex(true);
    setMenuActive(false);
  }, []);

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setResetActiveIndex(true);
      hideOverlayMenu();
    }
    if (!event.item.items && (isSlim() || isHorizontal())) {
      setMenuActive(false);
    }
  };

  const onMenuClick = (event) => {
    if (menuActive && event.target.className === "layout-menu-container") {
      setResetActiveIndex(true);
      setMenuActive(false);
    }
    menuClick = true;
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setRightPanelActive(false);

    if (isMobile()) {
      setStaticMenuMobileActive(
        (prevStaticMenuMobileActive) => !prevStaticMenuMobileActive
      );
      if (staticMenuMobileActive) {
        blockBodyScroll();
      } else {
        unblockBodyScroll();
      }
    }
    event.preventDefault();
  };

  const isMobile = () => {
    return window.innerWidth <= 991;
  };

  const isHorizontal = () => {
    return "sideBar" === "horizontal";
  };

  const isSlim = () => {
    return "sideBar" === "slim";
  };

  const hideOverlayMenu = () => {
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const onRightPanelClick = () => {
    rightPanelClick = true;
  };

  const onRightPanelButtonClick = () => {
    setRightPanelActive((prevState) => !prevState);
    rightPanelClick = true;
  };

  const onTopbarSearchToggle = () => {
    setSearchActive((prevState) => !prevState);
    searchClick = true;
  };

  const onTopbarSearchClick = () => {
    searchClick = true;
  };

  const onTopbarUserMenuClick = () => {
    setTopbarUserMenuActive((prevState) => !prevState);
    topbarUserMenuClick = true;
  };

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      setSearchActive(false);
      searchClick = false;
    }

    if (!topbarUserMenuClick && topbarUserMenuActive) {
      setTopbarUserMenuActive(false);
      topbarUserMenuClick = false;
    }

    if (!rightPanelClick && rightPanelActive) {
      setRightPanelActive(false);
    }

    if (!configClick && configActive) {
      setConfigActive(false);
    }

    if (!menuClick) {
      if (isSlim() || isHorizontal()) {
        setResetActiveIndex(true);
        setMenuActive(false);
      }

      if (staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    searchClick = false;
    topbarUserMenuClick = false;
    rightPanelClick = false;
    configClick = false;
    menuClick = false;
  };

  const onSidebarMouseOver = () => {
    setSidebarActive(!isMobile());
  };

  const onSidebarMouseLeave = () => {
    setSidebarActive(false);
  };

  const onToggleMenu = (event) => {
    menuClick = true;
    setSidebarStatic((prevState) => !prevState);

    event.preventDefault();
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const layoutClassName = classNames(
    "layout-wrapper",
    {
      "layout-sidebar": "sidebar",
      "layout-static": "sidebar" && sidebarStatic,
      "layout-horizontal": "sidebar" === "horizontal",
      "layout-rightpanel-active": rightPanelActive,
      "layout-slim": "sidebar" === "slim",
      "layout-mobile-active": staticMenuMobileActive,
      "p-input-filled": "filled",
      "p-ripple-disabled": false,
    },
    "layout-menu-light layout-topbar-light"
  );

  return (
    <div
      className={layoutClassName}
      onClick={onDocumentClick}
      style={{ backgroundColor: "#F0F0F0" }}
    >
      <Tooltip
        ref={copyTooltipRef}
        target=".block-action-copy"
        position="bottom"
        content="Copied to clipboard"
        event="focus"
      />

      <AppTopbar
        topbarScheme={"light"}
        onRightPanelButtonClick={onRightPanelButtonClick}
        searchActive={searchActive}
        onTopbarSearchToggle={onTopbarSearchToggle}
        onTopbarSearchClick={onTopbarSearchClick}
        topbarUserMenuActive={topbarUserMenuActive}
        onTopbarUserMenuClick={onTopbarUserMenuClick}
        menu={menu}
        menuActive={menuActive}
        onRootMenuItemClick={onRootMenuItemClick}
        mobileMenuActive={staticMenuMobileActive}
        onMenuItemClick={onMenuItemClick}
        menuMode={"sidebar"}
        sidebarStatic={sidebarStatic}
        sidebarActive={sidebarActive}
        onSidebarMouseOver={onSidebarMouseOver}
        onSidebarMouseLeave={onSidebarMouseLeave}
        onToggleMenu={onToggleMenu}
        onMenuButtonClick={onMenuButtonClick}
        resetActiveIndex={resetActiveIndex}
        onMenuClick={onMenuClick}
      />

      <AppRightPanel onRightPanelClick={onRightPanelClick} />

      <div className="layout-main">
        <div
          className="mgeBG"
          style={{
            opacity: "0.3",
            height: "100vh",
            position: "fixed",
            width: "100%",
            bottom: "0",
            right: "0",
          }}
        >
          &nbsp;
        </div>
        <div className="layout-content">
          <Route
            exact
            path="/"
            render={() => {
              return getDatosUsuario() &&
                getDatosUsuario().opciones &&
                getDatosUsuario().opciones.length > 0 ? (
                <Redirect to={getDatosUsuario().opciones[0].actionOpcion} />
              ) : (
                <Redirect to="/login" />
              );
            }}
          />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/user/:id/:profile" exact component={UserInfo} />
          <Route path="/traininginfo/:id" exact component={TrainingInfo} />
          <Route
            path="/trainingapplicant/:id/:idTraining"
            exact
            component={TrainingApplicant}
          />

          <Route path="/users" exact component={Usuarios} />
          <Route path="/usernew" exact component={UsuarioNew} />
          <Route path="/users/:id" exact component={Usuario} />
          <Route path="/resetPassword" exact component={ResetPasswordUser} />
          <Route path="/myaccount" exact component={MiCuenta} />
          <Route path="/language" exact component={Language} />

          <Route path="/opciones" exact component={Opciones} />
          <Route path="/perfiles" exact component={Perfiles} />
          <Route path="/perfil/:id/:nombre" exact component={Perfil} />

          <Route path="/training" exact component={Courses} />
          <Route path="/training/:id" exact component={Applicants} />
          <Route path="/trainingedit/:id" exact component={Training} />
          <Route path="/trainingnew" exact component={Course} />

          <Route path="/applicants" exact component={Applicants} />
          <Route
            path="/applicants/:id/:idTraining"
            exact
            component={Applicant}
          />

          <Route path="/classes" exact component={Classes} />
          <Route path="/workplan" exact component={WorkPlan} />
          <Route
            path="/beneficiaries/:operation"
            exact
            component={Beneficiaries}
          />
          <Route path="/finalreport" exact component={FinalReport} />
          <Route path="/certificate" exact component={Certificate} />
        </div>

        <AppFooter />
      </div>
      <div className="layout-mask modal-in"></div>
    </div>
  );
};

export default App;
