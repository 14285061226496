import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Typography, Spin } from 'antd'
import toast from 'react-hot-toast'

import { getLabel, labels } from '../../utils/Internationalization'
import { getDatosUsuario, setTrainingInforms } from '../../utils/Utils'

import PostulantService from '../../service/PostulantService'
const postulantService = new PostulantService()

const Classes = () => {
    const navigate = useHistory()
    const [load, setLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const fetchData = async () => {
        setLoad(false)
        setIsLoading(true)
        try {
            const { data } = await postulantService.getTraining({ navigate, postulant: { idUsuario: getDatosUsuario().usuarioId } })
            const trainings = data.listObject
            setTrainingInforms(trainings)
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (load) fetchData()
    })

    return (
        <>
            <Row>
                <Col span={24}>
                    <div style={
                        {
                            backgroundColor: '#ffffff',
                            padding: '15px 20px 6px 20px',
                            borderRadius: '15px',
                            marginBottom: '20px'
                        }
                    }>
                        <Row>
                            <Col>
                                <Row>
                                    <Col>
                                        <Typography.Title level={3} type='secondary'>
                                            {getLabel(labels.trainings)}
                                        </Typography.Title>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={24}>
                    {
                        isLoading &&
                        <Row style={{ padding: '30px 10px' }}>
                            <Col span={24} style={{ textAlign: 'center' }}>
                                <Spin size='large' tip={
                                    <Typography.Paragraph style={{ padding: '10px 0' }}>
                                        {getLabel(labels.loading)}...
                                    </Typography.Paragraph>
                                } />
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </>
    )
}

export default Classes