import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Typography, Button, Result, Table, Row, Col, Divider, Input, Radio, Form, Space, Tooltip, Drawer, Tag } from 'antd'
import * as anticons from '@ant-design/icons'
import { getCatalogos } from '../../utils/Utils'
import toast from 'react-hot-toast'

import UsuarioService from '../../service/UsuarioService'
import { getLabel, labels } from '../../utils/Internationalization'
import RestorePassword from './RestorePassword'
import md5 from 'md5'
const usuarioService = new UsuarioService()

const Usuarios = () => {

  const navigate = useHistory()
  const [load, setLoad] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [usuarios, setUsuarios] = useState([])
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)

  const [documento, setDocumento] = useState(null)
  const [email, setEmail] = useState(null)
  const [nombres, setNombres] = useState(null)
  const [estado, setEstado] = useState(null)

  const [openResetPassword, setOpenResetPassword] = useState(false)
  const [isReseting, setIsReseting] = useState(false)

  const tableProps = {
    bordered: false,
    loading: false,
    title: '',
    showHeader: true,
    size: 'medium',
    pagination: {
      position: ['none', 'bottomRight']
    }
  }

  const columnas = [
    {
      title: '',
      render: (_, r) => (r.imagen && <img alt='' src={`/images/${r.imagen.split('/')[r.imagen.split('/').length - 1]}`} width={32} height={32} />) || <img alt='' src='./assets/user.png' width={32} height={32} />
    },
    {
      title: getLabel(labels.firstName),
      dataIndex: 'nombres',
      render: (_, r) =>
        <Space direction='horizontal'>
          <span>{r.nombres} {r.apellidos}</span>
          <span className=' mgeKey'>
            {
              (
                isReseting && <anticons.LoadingOutlined />
              ) ||
              <Tooltip
                title={getLabel(labels.resetPassword)}
                onClick={
                  () => {
                    setIsReseting(true)
                    setUser(null)
                    setTimeout(() => {
                      setIsReseting(false)
                      setUser(r)
                      setOpenResetPassword(true)
                    }, 250)
                  }
                }
              >
                <i
                  style={{ cursor: 'pointer' }}
                  className="fa-solid fa-key"></i>
              </Tooltip>
            }
          </span>
        </Space>
    },
    {
      title: getLabel(labels.idNumber),
      dataIndex: 'documento',
      responsive: ['md', 'lg', 'xl', 'xxl']
    },
    {
      title: getLabel(labels.email),
      dataIndex: 'email',
      responsive: ['xl', 'xxl']
    },
    {
      title: getLabel(labels.signatureUploaded),
      dataIndex: 'firma',
      render: (_, { firma }) => firma === true ? <Tag color="#108ee9">{getLabel(labels.yes)}</Tag> : <Tag color="#faad14">{getLabel(labels.no)}</Tag>,
      responsive: ['xl', 'xxl']
    },
    {
      title: getLabel(labels.phoneNumber),
      dataIndex: 'celular',
      render: (_, r) => `${r.codigotel} ${r.celular}`,
      responsive: ['xl', 'xxl']
    },
    {
      title: getLabel(labels.actions),
      render: (_, record) => <Button type='primary' href={`/#/users/${record.usuId}`}>{getLabel(labels.view)}</Button>
    }
  ]

  const resetPassword = async (values) => {
    setIsReseting(true)
    const usuario = {
      usuId: values.usuId,
      password: md5(values.password),
      ...(values.sendNotification && { email: values.email })
    }
    try {
      const { data } = await usuarioService.resetPassword({ navigate, usuario })
      if (data)
        toast.success(getLabel(labels.passwordResetSuccessfully))
    } catch (error) {
      const { response } = error
      toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    } finally {
      setIsReseting(false)
      closeResetPassword()
    }
  }

  const closeResetPassword = () => {
    setOpenResetPassword(false)
  }

  const fetchData = async () => {
    setLoad(false)
    setError(null)
    setIsLoading(true)
    setUsuarios([])
    try {
      let filtros = {
      }
      if (documento) {
        filtros = { ...filtros, documento }
      }
      if (email) {
        filtros = { ...filtros, email }
      }
      if (nombres) {
        filtros = { ...filtros, nombres }
      }
      if (estado !== null) {
        filtros = { ...filtros, estadoId: estado }
      }
      const { data } = await usuarioService.getsFiltro({ navigate, filtros })
      if (data) {
        const usuarios = data.listObject
        setUsuarios(usuarios)
      } else {
        setError(getLabel(labels.errorOcurred))
      }
    } catch (error) {
      const { response } = error
      toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (load) fetchData()
  })

  if (error) {
    return (
      <Result
        status="warning"
        title={''}
        subTitle={''}
        extra={
          <>
            <h5>{error}</h5>
            <Link to={'/'}>{getLabel(labels.backHome)}</Link>
          </>
        }
      />
    )
  }

  return (
    <>
      <Row>
        <Col span={24}>
          <Row justify='start'>
            <Typography.Title
              level={4}
              style={{
                margin: 0,
              }}
            >
              {getLabel(labels.usersManagement)}
            </Typography.Title>
          </Row>
        </Col>
      </Row>
      <Divider></Divider>
      <Form
        layout={'vertical'}
      >
        <Row gutter={10}>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Form.Item label={getLabel(labels.idNumber)}>
              <Input type='text' value={documento} onChange={(e) => setDocumento(e.target.value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Form.Item label={getLabel(labels.firstName)}>
              <Input type='text' value={nombres} onChange={(e) => setNombres(e.target.value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Form.Item label={getLabel(labels.email)}>
              <Input type='text' value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={6} xl={6}>
            <Form.Item label={getLabel(labels.state)}>
              <Radio.Group onChange={(e) => setEstado(e.target.value)} value={estado}>
                {
                  getCatalogos().states.map(s => <Radio key={s.catId} value={s.catId}>{s.catNombre}</Radio>)
                }
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end'>
          <Col>
            <Form.Item>
              <Space direction='horizontal'>
                <Button type='default' onClick={() => { navigate.push('/usernew') }} loading={isLoading} disabled={isLoading}>{getLabel(labels.new)}</Button>
                <Button type='primary' onClick={() => fetchData()} loading={isLoading} disabled={isLoading}>{getLabel(labels.search)}</Button>
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        {...tableProps}
        rowKey='usuId'
        columns={columnas}
        dataSource={usuarios}
        loading={isLoading}
        rowClassName='mgeChangePassword'
      />
      <Drawer title={getLabel(labels.resetPassword)} placement="right" onClose={closeResetPassword} open={openResetPassword}
        extra={
          <Space>
            <Button onClick={closeResetPassword}
              disabled={isLoading}
            >{getLabel(labels.cancel)}</Button>
          </Space>
        }
      >
        {
          user && <RestorePassword user={user} resetPassword={resetPassword} isReseting={isReseting} closeResetPassword={closeResetPassword} />
        }
      </Drawer>
    </>
  )
}

export default Usuarios