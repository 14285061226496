import React from 'react'
import { useHistory } from 'react-router-dom';
import { getDatosUsuario } from '../../utils/Utils'
import User from '../user/User';

const MiCuenta = () => {
  const id = getDatosUsuario() && getDatosUsuario().usuarioId
  const navigate = useHistory()
  return <User user={{ id }} navigate={navigate}></User>
}

export default MiCuenta