import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Typography, Button, Result, Space, Row, Col, Divider, Drawer, Form, message, Input, Popconfirm, Checkbox, Tree } from 'antd'
import * as anticons from '@ant-design/icons'

import { getLabel, labels } from '../../utils/Internationalization'

import OpcionService from './../../service/OpcionService'
const opcionService = new OpcionService()

const Opciones = () => {

  const navigate = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingOpcion, setIsLoadingOpcion] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [opciones, setOpciones] = useState([])
  const [opcionesSeleccionados, setOpcionesSeleccionados] = useState([])
  const [opcion, setOpcion] = useState({})
  const [error, setError] = useState(null)
  const [opcionAbierto, setOpcionAbierto] = useState(false);
  const [formOpcion] = Form.useForm();

  const onChangeOpcion = (e) => {
    setOpcion({ ...opcion, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
  }

  const onSelect = (selectedKeys, e) => {
    const opciones = e.selectedNodes
    setOpcionesSeleccionados(opciones)
  }

  useEffect(() => {
    if (isLoading) {
      const fetchData = async () => {
        try {
          const { data } = await opcionService.gets({ navigate })
          if (data) {
            const _opciones = data.listObject.filter(o => o.opcNivel === 1).map(o => (
              {
                title: o.opcNombre,
                key: `${o.opcId}_${o.opcNombre}_${o.opcNivel}`,
                children: o.opcHijos ? o.opcHijos.map(o => (
                  {
                    title: o.opcNombre,
                    key: `${o.opcId}_${o.opcNombre}_${o.opcNivel}`
                  }
                )) : []
              }))
            setOpciones(_opciones)
            setError(null)
            setIsLoading(false)
          } else {
            setIsLoading(false)
            setError(getLabel(labels.errorOcurred))
          }
        } catch (error) {
          setIsLoading(false)
          setError(getLabel(labels.errorOcurred))
        }
      }
      fetchData()
    }
  }, [isLoading, navigate])

  const fetchOpciones = () => {
    setIsLoading(true)
  }

  const getNuevoOpcion = () => {
    const nodo = opcionesSeleccionados && opcionesSeleccionados.length > 0 ? { ...opcionesSeleccionados[0] } : {}
    const opcNivel = nodo.key ? parseInt(nodo.key.split('_')[2]) + 1 : 1
    setIsLoadingOpcion(false)
    return (
      {
        opcNivel,
        opcNombre: "",
        opcOrden: "",
        opcUrl: "/",
        opcEstado: true,
      }
    )
  }

  const abrirOpcion = (c) => {
    setOpcion({
      ...c
    })
    setOpcionAbierto(true);
  }

  const cerrarOpcion = () => {
    setOpcionAbierto(false);
  };

  const getOpcion = async () => {
    setIsLoadingOpcion(true)
    const idOpcion = opcionesSeleccionados[0].key.split('_')[0]
    try {
      const { data } = await opcionService.get({ navigate, opcion: { opcId: idOpcion } })
      if (data) {
        setOpcion(data.listObject[0])
        setError(null)
        setIsLoadingOpcion(false)
      } else {
        setIsLoadingOpcion(false)
        setError(getLabel(labels.errorOcurred))
      }
    } catch (error) {
      setIsLoadingOpcion(false)
      const { response } = error
      setError(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    }
  }

  const guardarOpcion = async () => {
    setIsSaving(true)
    try {
      const { data } = await opcionService.set({ navigate, opcion })
      setIsSaving(false)
      if (data) {
        setOpcion(data.listObject[0])
        message.success('Opción guardada correctamente')
        setIsLoading(true)
      }
    } catch (error) {
      setIsSaving(false)
      const { response } = error
      setError(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    }
  }

  const eliminarOpciones = (e) => {
    const requests = []
    opcionesSeleccionados.forEach(o => {
      requests.push(opcionService.del({ navigate, opcion: { opcId: o.key.split('_')[0], opcNombre: o.title } }))
    })
    setIsDeleting(true)
    Promise.all(requests)
      .then((results) => {
        setOpcionesSeleccionados([])
        setIsDeleting(false)
        setIsLoading(true)
      }).catch(error => {
        setIsDeleting(false)
        const { response } = error
        const msgError = response.status === 409 ? response.data[0].messageText : response.data
        message.error(`No fue posible eliminar la opción: ${msgError}`)
      })
  }

  const eliminarOpcion = async () => {
    setIsDeleting(true)
    try {
      const { data } = await opcionService.del({ navigate, opcion })
      setIsDeleting(false)
      if (data === true) {
        message.success('Opción eliminada correctamente')
        cerrarOpcion()
        setIsLoading(true)
      } else {
        message.error('No fue posible eliminar la opción')
      }
    } catch (error) {
      setIsDeleting(false)
      const { response } = error
      setError(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    }
  }

  if (error) {
    return (
      <Result
        status="warning"
        title={''}
        subTitle={''}
        extra={
          <>
            <h5>{error}</h5>
            <Link to={'/'}>Regresar al inicio</Link>
          </>
        }
      />
    )
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Row justify='start'>
            <Typography.Title
              level={4}
              style={{
                margin: 0,
              }}
            >
              Gestión de Opciones
            </Typography.Title>
          </Row>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Row justify='end'>
            <Space>
              <Button type='default' onClick={(e) => abrirOpcion(getNuevoOpcion())} icon={<anticons.PlusOutlined />}>
                Nuevo
              </Button>
              {
                opcionesSeleccionados && opcionesSeleccionados.length === 1 &&
                <Button type='default' onClick={(e) => abrirOpcion(getOpcion())}
                  icon={<anticons.EditFilled />}
                >
                  Editar
                </Button>
              }
              {
                opcionesSeleccionados && opcionesSeleccionados.length > 0 &&
                <Popconfirm title="¿Seguro(a) que desea eliminar las opciones seleccionados?" okText="Si, eliminar" cancelText="No" onConfirm={() => eliminarOpciones()}
                  okButtonProps={{
                    loading: isDeleting,
                  }}
                >
                  <Button type="danger"
                    disabled={isDeleting || isLoading}
                    loading={isDeleting}
                    icon={<anticons.DeleteFilled />}
                  >
                    {isDeleting ? 'Eliminando' : 'Eliminar'}
                  </Button>
                </Popconfirm>
              }
              <Button type="primary" onClick={fetchOpciones} loading={isLoading}
                icon={<anticons.SearchOutlined />}
              >Consultar</Button>
            </Space>
          </Row>
        </Col>
      </Row>
      <Divider></Divider>
      <Tree
        checkable={false}
        treeData={opciones}
        onSelect={onSelect}
      />
      <Drawer
        title={<>Opción</>}
        placement="right"
        closable={true}
        onClose={cerrarOpcion}
        open={opcionAbierto}
      >
        <Form
          layout={'vertical'}
          form={formOpcion}
          initialValues={{}}
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Nombre">
                <Input id="opcNombre" name="opcNombre" label='Nombre' type='text' value={opcion.opcNombre} onChange={onChangeOpcion} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="URL">
                <Input id="opcUrl" name="opcUrl" label='URL' type='text' value={opcion.opcUrl} onChange={onChangeOpcion} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Orden">
                <Input id="opcOrden" name="opcOrden" label='Orden' type='text' value={opcion.opcOrden} onChange={onChangeOpcion} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Icono">
                <Input id="opcIcono" name="opcIcono" label='Icono' type='text' value={opcion.opcIcono} onChange={onChangeOpcion} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label="Estado">
                <Checkbox id='opcEstado' name='opcEstado' onChange={onChangeOpcion} checked={opcion.opcEstado}>
                  {
                    opcion.opcEstado ? 'Habilitado' : 'Deshabilitado'
                  }
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Space>
                  <Button type="primary" onClick={(e) => guardarOpcion(e)}
                    disabled={isLoadingOpcion || isSaving || isDeleting}
                    loading={isSaving}
                    icon={<anticons.SaveFilled />}
                  >
                    {isSaving ? 'Guardando' : 'Guardar'}
                  </Button>
                  {
                    opcion && opcion.opcId &&
                    <Popconfirm title="¿Seguro(a) que desea eliminar la opción?" okText="Si, eliminar" cancelText="No" onConfirm={() => eliminarOpcion()}
                      okButtonProps={{
                        loading: isDeleting,
                      }}
                    >
                      <Button type="danger"
                        disabled={isLoadingOpcion || isDeleting || isSaving}
                        loading={isDeleting}
                        icon={<anticons.DeleteFilled />}
                      >
                        {isDeleting ? 'Eliminando' : 'Eliminar'}
                      </Button>
                    </Popconfirm>
                  }
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  )
}

export default Opciones