import React, { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Input, Form, Button, Row, Col, Divider, Layout, Image, Result } from 'antd'
import PasswordChecklist from 'react-password-checklist'
import md5 from 'md5'
import CuentaService from "../service/CuentaService"
import AlertWait from "./AlertWait"
import toast from "react-hot-toast"
import { getCulture, getLabel, labels } from "../utils/Internationalization"

const cuentaService = new CuentaService()

const ResetPassword = () => {

    const navigate = useHistory()
    const { search } = useLocation()
    const { id, uuid } = JSON.parse('{"' + search.substring(1, search.length).replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) })
    const [validate, setValidate] = useState(true)
    const [isValidating, setIsValidating] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [isResetting, setIsResetting] = useState(false)
    const [isReset, setIsReset] = useState(false)
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [okMsg, setOkMsg] = useState(null)

    const reset = async (values) => {
        setOkMsg(null)
        setIsResetting(true)
        try {
            const { data } = await cuentaService.updatePassword({ datos: { usuId: id, uuid, password: md5(values.password), passwordConfirm: md5(values.passwordConfirm) } })
            if (data) {
                setOkMsg(getLabel(labels.passwordSuccessfullyChanged))
                setIsReset(true)
            }
        } catch (error) {
            const { response } = error
            if (response.status === 500) {
                toast.error(response.statusText)
            } else if (response.status === 404) {
                toast.error(getLabel(labels.resourceNotAvailable))
            } else {
                toast.error(response.data[0].messageText)
            }
        } finally {
            setIsResetting(false)
        }
    }

    const validateRequest = async () => {
        setValidate(false)
        setIsValidating(true)
        try {
            const datos = { usuId: id, uuid }
            const { data } = await cuentaService.validateResetPassword({ datos })
            if (data) setIsValid(true)
        } catch (error) {
            const { response } = error
            if (response.status === 500) {
                toast.error(response.statusText)
            } else if (response.status === 404) {
                toast.error(getLabel(labels.resourceNotAvailable))
            } else if (response.status === 409) {
                setIsValid(false)
            } else {
                toast.error(response.data[0].messageText)
            }
        }
        finally {
            setIsValidating(false)
        }
    }

    useEffect(() => {
        if (validate) validateRequest()
    })

    return (
        <>
            <Row>
                <Col span={24}>
                    <div className="notification" style={{
                        borderRadius: '0px',
                        textAlign: 'center'
                    }}>
                        <Image alt='' src={`./../assets/logo_${getCulture()}.png`} preview={false} />
                    </div>
                </Col>
            </Row>

            <Layout style={{
                padding: '40px 20px',
                backgroundColor: '#F9F9F9'
            }}>
                <div className='mgeBG' style={{ opacity: '0.3', height: '100vh', position: 'fixed', width: '100%', bottom: '0', right: '0' }}>&nbsp;</div>
                <Row justify='center'>
                    <Col xs={24} sm={18} md={16} lg={12} xl={8} xxl={6}>
                        <Row>
                            <Col span={24}>

                                {
                                    isValidating ?
                                        <>
                                            <AlertWait isLoading={isValidating} message={getLabel(labels.loading)} />
                                        </>
                                        :
                                        <>
                                            {
                                                isValid ?
                                                    <>
                                                        {
                                                            isReset && okMsg ?
                                                                <>
                                                                    <Result
                                                                        status='success'
                                                                        title={getLabel(labels.passwordResetSuccessfully)}
                                                                        subTitle={okMsg}
                                                                        extra={
                                                                            <Button type="primary" key="console"
                                                                                onClick={() => {
                                                                                    navigate.push('/login')
                                                                                }}
                                                                            >
                                                                                {getLabel(labels.login)}
                                                                            </Button>
                                                                        }
                                                                    />
                                                                </>
                                                                : <>
                                                                    <Divider><span className="mge-darkGreen" style={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
                                                                        {getLabel(labels.resetPassword)}
                                                                    </span></Divider>
                                                                    <Form
                                                                        layout={'vertical'}
                                                                        onFinish={reset}
                                                                    >
                                                                        <Form.Item
                                                                            name='password'
                                                                            label={getLabel(labels.newPassword)}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: getLabel(labels.completeNewPassword),
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input.Password onChange={e => setPassword(e.target.value)} />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            name='passwordConfirm'
                                                                            label={getLabel(labels.confirmNewPassword)}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: getLabel(labels.confirmNewPassword),
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input.Password onChange={e => setPasswordConfirm(e.target.value)} />
                                                                        </Form.Item>
                                                                        <Form.Item>
                                                                            <PasswordChecklist
                                                                                rules={["minLength", "specialChar", "number", "capital", "match"]}
                                                                                minLength={8}
                                                                                value={password}
                                                                                valueAgain={passwordConfirm}
                                                                                messages={{
                                                                                    minLength: getLabel(labels.passwordHasMore8Characters),
                                                                                    specialChar: getLabel(labels.passwordHasSpecialCharacters),
                                                                                    number: getLabel(labels.passwordHasNumber),
                                                                                    capital: getLabel(labels.passwordHasCapitalLetter),
                                                                                    match: getLabel(labels.passwordsMatch),
                                                                                }}
                                                                                onChange={(isValid) => { }}
                                                                            />
                                                                        </Form.Item>
                                                                        <div style={{
                                                                            paddingTop: '10px'
                                                                        }}>
                                                                            <Form.Item>
                                                                                <Button
                                                                                    type='primary'
                                                                                    htmlType='submit'
                                                                                    loading={isResetting}
                                                                                    disabled={isResetting}
                                                                                    block>
                                                                                    {
                                                                                        isResetting ? getLabel(labels.resettingPassword) : getLabel(labels.resetPassword)
                                                                                    }
                                                                                </Button>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </Form>
                                                                </>
                                                        }
                                                    </>
                                                    :
                                                    <Result
                                                        status='404'
                                                        title={getLabel(labels.pageNotExist)}
                                                        subTitle={getLabel(labels.pageVisitedNotExist)}
                                                        extra={
                                                            <Button type="primary" key="console"
                                                                onClick={() => {
                                                                    navigate.push('/login')
                                                                }}
                                                            >
                                                                {getLabel(labels.login)}
                                                            </Button>
                                                        }
                                                    />
                                            }
                                        </>
                                }

                            </Col>
                        </Row>
                    </Col>
                </Row >
            </Layout >
        </>
    )

}

export default ResetPassword