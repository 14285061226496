import AxiosInstance from '../utils/AxiosInstance'
import { downloadInformByName, getInformById, insertInform, sendEmailLoadInform, sendEmailRateInform, updateInform } from '../utils/urls'

export class InformService {
    put = (props) => {
        const { navigate, inform } = props
        return AxiosInstance(navigate).put(insertInform(), inform,
            {
                headers: { "Content-Type": "multipart/form-data" }
            })
    }
    set = (props) => {
        const { navigate, inform } = props
        return AxiosInstance(navigate).patch(updateInform(), inform)
    }
    get = (props) => {
        const { navigate, inform } = props
        return AxiosInstance(navigate).patch(getInformById(), inform)
    }
    downloadByName = (props) => {
        const { navigate, inform } = props
        return AxiosInstance(navigate).post(downloadInformByName(), inform)
    }
    sendEmailLoad = (props) => {
        const { navigate, inform } = props
        return AxiosInstance(navigate).patch(sendEmailLoadInform(), inform)
    }
    sendEmailRate = (props) => {
        const { navigate, inform } = props
        return AxiosInstance(navigate).patch(sendEmailRateInform(), inform)
    }
}

export default InformService