import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Input, Form, Button, Row, Col, Divider, Image, Layout, Result, Alert } from 'antd'
import CuentaService from "../service/CuentaService"
import { getCulture, getLabel, labels } from "../utils/Internationalization"

const cuentaService = new CuentaService()

const RecuperarPassword = () => {
    const navigate = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [okMsg, setOkMsg] = useState(null)
    const [error, setError] = useState(null)
    const [emailSent, setEmailSent] = useState(true)
    const [form] = Form.useForm();

    const recuperar = async (values) => {
        setEmailSent(false)
        setError(null)
        setOkMsg(null)
        setIsLoading(true)
        const datos = { ...values }
        try {
            await cuentaService.recoveryPassword({ datos })
            setOkMsg(getLabel(labels.passwordSentIndicatedEmail))
            setEmailSent(true)
        } catch (error) {
            const { response } = error
            if (response.status === 500) {
                setError(response.statusText)
            } else if (response.status === 404) {
                setError(getLabel(labels.resourceNotAvailable))
            } else {
                setError(response.data[0].messageText)
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <>
            <Row>
                <Col span={24}>
                    <div className="notification" style={{
                        borderRadius: '0px',
                        textAlign: 'center'
                    }}>
                        <Image alt='' src={`./../assets/logo_${getCulture()}.png`} preview={false} />
                    </div>
                </Col>
            </Row>

            <Layout style={{
                padding: '40px 20px',
                backgroundColor: '#F9F9F9'
            }}>
                <div className='mgeBG' style={{ opacity: '0.3', height: '100vh', position: 'fixed', width: '100%', bottom: '0', right: '0' }}>&nbsp;</div>
                {
                    emailSent === true && okMsg ?
                        <Result
                            status='success'
                            title={okMsg}
                            extra={
                                <Button type="primary" key="console"
                                    onClick={() => {
                                        navigate.push('/login')
                                    }}
                                >
                                    Log in
                                    {getLabel(labels.login)}
                                </Button>
                            }
                        />
                        :
                        <Row justify='center'>
                            <Col xs={24} sm={18} md={16} lg={12} xl={8} xxl={6}>
                                <Divider><span className="mge-darkGreen" style={{ textTransform: 'uppercase', letterSpacing: '1px' }}>
                                    {getLabel(labels.recoverPassword)}
                                </span></Divider>
                                <Form
                                    layout={'vertical'}
                                    form={form}
                                    onFinish={recuperar}
                                >
                                    <Form.Item
                                        name='email'
                                        label={getLabel(labels.email)}
                                        rules={[
                                            {
                                                type: 'email',
                                                message: getLabel(labels.emailNotValid)
                                            },
                                            {
                                                required: true,
                                                message: getLabel(labels.completeEmail),
                                            },
                                        ]}
                                    >
                                        <Input id="email" name="email" type='text' />
                                    </Form.Item>

                                    {
                                        error &&
                                        <Alert
                                            message=''
                                            description={error}
                                            type='warning'
                                            showIcon
                                            closable
                                            banner
                                        />
                                    }

                                    {
                                        okMsg &&
                                        <Alert
                                            message=''
                                            description={okMsg}
                                            type='success'
                                            showIcon
                                            closable
                                            banner
                                        />
                                    }

                                    <div style={{
                                        paddingTop: '20px'
                                    }}>
                                        <Form.Item>
                                            <Button
                                                type='primary'
                                                htmlType='submit'
                                                loading={isLoading}
                                                disabled={isLoading}
                                                block
                                            >
                                                {getLabel(labels.recoverPassword)}
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                }
            </Layout>
        </>
    )
}

export default RecuperarPassword