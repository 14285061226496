import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col, Form, Input, Select, Tag, Button, DatePicker, Space, Radio, Spin, Typography, Popconfirm, Image, Alert, Tooltip } from 'antd'
import * as anticons from '@ant-design/icons'

import { getLabel, labels } from '../../utils/Internationalization'
import { getCatalogos, getCultures, getDatosUsuario, getFormatoFecha } from '../../utils/Utils'

import toast from 'react-hot-toast'
import dayjs from 'dayjs'
import moment from 'moment'

import AlertWait from '../../pages/AlertWait'

import TrainingService from '../../service/TrainingService'
import CountryTrainingService from '../../service/CountryTrainingService'
import CourseService from '../../service/CourseService'
import PostulantService from '../../service/PostulantService'

const trainingService = new TrainingService()
const countryTrainingService = new CountryTrainingService()
const courseService = new CourseService()
const postulantService = new PostulantService()

const Training = () => {

    const navigate = useHistory()
    const { id } = useParams()
    const [formPostulante] = Form.useForm()
    const [load, setLoad] = useState(true)
    const [loadPostulant, setLoadPostulant] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingCountry, setIsLoadingCountry] = useState(false)
    const [isLoadingCourse, setIsLoadingCourse] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isPublishing, setIsPublishing] = useState(false)
    const [isSendingEmail, setIsSendingEmail] = useState(false)
    const [training, setTraining] = useState(null)
    const [postulants, setPostulants] = useState([])
    const [paises, setPaises] = useState([])
    const [courses, setCourses] = useState([])
    const [selectedModality, setSelectedModality] = useState(0)
    const [message, setMessage] = useState({ type: 'warning', message: null })

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const [formCourse] = Form.useForm()

    const disabledDateStart = (current) => {
        if (endDate === null) return
        return current && current >= endDate.startOf('day');
    }

    const disabledDateEnd = (current) => {
        if (startDate === null) return
        return current && current <= startDate.endOf('day');
    }

    const renderModality = ({ catId, catNombre, catValor }) =>
        <Radio.Button
            key={catId}
            value={catId}
            style={{
                height: '100%',
                margin: '0 20px 20px 0',
            }}
            onChange={e => setSelectedModality(catId)}
        >
            <Row>
                <Col style={{
                    padding: '20px 20px',
                    borderRadius: '15px',
                }}>
                    <Image preview={false} src={`./../../assets/${catValor}${catId === selectedModality ? '_W' : ''}.png`} width={55} height={50} />
                </Col>
                <Col style={{
                    padding: '30px 10px',
                    width: '100px'
                }}>
                    {catNombre}
                </Col>
            </Row>
        </Radio.Button>

    const renderApplicants = ({ posId, posEmail, posEnvio, posEstado }) =>
        <Col key={posEmail}>
            <Tag key={posEmail} closeIcon={<anticons.CloseOutlined />} onClose={(e) => { delPostulant({ posId, posEmail }) }}
                closable
                style={{
                    padding: '5px 15px',
                    borderRadius: '15px',
                    fontSize: '1em'
                }}
            >
                {
                    posEstado.catValor === 'EST_ENR' ?
                        <>
                            <i className="fa-solid fa-circle-check"></i>&nbsp;
                        </>
                        :
                        posEstado.catValor === 'EST_INV' || training.entEstado.catValor === 'ACTIVE' ?
                            <>
                                <Tooltip title={getLabel(labels.sendInvitationEmail)}>
                                    <i
                                        className="fa-solid fa-paper-plane"
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={(() => sendEmail({ posId }))}
                                    ></i>
                                </Tooltip>&nbsp;
                            </>
                            :
                            <></>
                }
                {posEmail}
            </Tag>
        </Col>

    const setCountry = async (country) => {
        setIsSaving(true)
        const countryTraining = { ...country, idEntrenamiento: training.entId }
        if (countryTraining.checked) {
            try {
                const { data } = await countryTrainingService.put({ navigate, countryTraining })
                if (data) {
                    fetchCountries()
                } else {
                    toast.error(getLabel(labels.errorOcurred))
                }
            } catch (error) {
                const { response } = error
                toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
            } finally {
                setIsSaving(false)
            }
        } else {
            try {
                const { data } = await countryTrainingService.del({ navigate, countryTraining })
                if (data) {
                    fetchCountries()
                } else {
                    toast.error(getLabel(labels.errorOcurred))
                }
            } catch (error) {
                const { response } = error
                toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
            } finally {
                setIsSaving(false)
            }
        }
    }

    const setCourse = async (course) => {
        setIsSaving(true)
        const newCourse = { ...course, idEntrenamiento: training.entId }
        if (course.checked) {
            try {
                const { data } = await courseService.put({ navigate, course: newCourse })
                if (data) {
                    fetchCourses()
                } else {
                    toast.error(getLabel(labels.errorOcurred))
                }
            } catch (error) {
                const { response } = error
                toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
            } finally {
                setIsSaving(false)
            }
        } else {
            try {
                const { data } = await courseService.del({ navigate, course: newCourse })
                if (data) {
                    fetchCourses()
                } else {
                    toast.error(getLabel(labels.errorOcurred))
                }
            } catch (error) {
                const { response } = error
                toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
            } finally {
                setIsSaving(false)
            }
        }
    }

    const setPostulant = async (values) => {
        const { posEmail } = values
        formPostulante.resetFields()
        if (postulants.filter(p => p.posEmail === posEmail).length > 0) {
            setMessage({
                type: 'warning',
                message: `${getLabel(labels.thenEmail)} ${posEmail} ${getLabel(labels.alreadyExists)}`
            })
            setTimeout(() => setMessage({ type: 'warning', message: null }), 5000)
            return
        }

        setIsSaving(true)
        const postulant = { ...values, idEntrenamiento: training.entId, idInvitadoPor: getDatosUsuario().usuarioId }
        try {
            const { data } = await postulantService.put({ navigate, postulant })
            if (data) {
                setMessage({
                    type: 'success',
                    message: `${getLabel(labels.email)} ${posEmail} ${getLabel(labels.successfullySaved)}`
                })
                setTimeout(() => setMessage({ type: 'warning', message: null }), 5000)
                fetchPostulants()
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsSaving(false)
        }
    }

    const delPostulant = async (values) => {
        setIsSaving(true)
        const postulant = { ...values, idEntrenamiento: training.entId, idInvitadoPor: getDatosUsuario().usuarioId }
        try {
            const { data } = await postulantService.del({ navigate, postulant })
            if (data) {
                setMessage({
                    type: 'success',
                    message: `${getLabel(labels.email)} ${postulant.posEmail} ${getLabel(labels.successfullyDeleted)}`
                })
                setTimeout(() => setMessage({ type: 'warning', message: null }), 5000)
                fetchPostulants()
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsSaving(false)
        }
    }

    const fetchData = async () => {
        setLoad(false)
        setIsLoading(true)
        const training = { entId: id }
        try {
            const { data } = await trainingService.getById({ navigate, training })
            if (data) {
                const training = data.listObject[0]
                setTraining({
                    ...training,
                    countries: training.countryTrainingList.map(c => c.paePais.catId),
                    courses: training.cursoList.map(c => c.curNombre.catId),
                    entFechaIni: moment(dayjs(training.entFechaIni.substring(0, 10), getFormatoFecha()).$d),
                    entFechaFin: moment(dayjs(training.entFechaFin.substring(0, 10), getFormatoFecha()).$d)
                })
                setStartDate(moment(dayjs(training.entFechaIni.substring(0, 10), getFormatoFecha()).$d))
                setEndDate(moment(dayjs(training.entFechaFin.substring(0, 10), getFormatoFecha()).$d))
                setSelectedModality(training.entModalidad.catId)
                setPaises(training.countryTrainingList)
                setCourses(training.cursoList)
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoading(false)
        }
    }

    const fetchCountries = async () => {
        setIsLoadingCountry(true)
        const countryTraining = { idEntrenamiento: id }
        try {
            const { data } = await countryTrainingService.getByTraining({ navigate, countryTraining })
            if (data) {
                const countries = data.listObject
                setPaises(countries)
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoadingCountry(false)
        }
    }

    const fetchCourses = async () => {
        setIsLoadingCourse(true)
        const training = { idEntrenamiento: id }
        try {
            const { data } = await courseService.getByTraining({ navigate, training })
            if (data) {
                const courses = data.listObject
                setCourses(courses)
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoadingCourse(false)
        }
    }

    const fetchPostulants = async () => {
        setLoadPostulant(false)
        const training = { entId: id }
        try {
            const { data } = await trainingService.getById({ navigate, training })
            if (data) {
                const postulants = data.listObject[0].postulanteList
                setPostulants(postulants)
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        }
    }

    const save = async (values) => {
        const training = {
            idRegion: values.entRegion.catId,
            entId: values.entId,
            entCultura: values.entCultura,
            entFechaFin: values.entFechaFin
        }
        setIsSaving(true)
        try {
            const { data } = await trainingService.set({ navigate, training })
            if (data) {
                toast.success(`${getLabel(labels.training)} ${data.listObject[0].entNombre} ${getLabel(labels.successfullyUpdated)}`)
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsSaving(false)
        }
    }

    const sendEmail = async (postulant) => {
        setIsSendingEmail(true)
        try {
            const { data } = await postulantService.sendEmail({ navigate, postulant })
            if (data === false)
                toast.error(getLabel(labels.couldNotSendEmail), { position: 'bottom-center' })
            else
                toast.success(getLabel(labels.emailSentSuccessfully), { position: 'bottom-center' })
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred), { position: 'bottom-center' })
        } finally {
            setIsSendingEmail(false)
        }
    }

    const pub = async () => {
        if (paises.length < 1 || courses.length < 1 || postulants.length < 1) {
            toast.error(getLabel(labels.addCountryCourseApplicant))
            return;
        }
        const modTraining = {
            entId: training.entId,
            entCultura: training.entCultura,
            idEstado: getCatalogos().trainingStatus.filter(s => s.catValor === 'ACTIVE')[0].catId
        }
        setIsPublishing(true)
        try {
            const { data } = await trainingService.pub({ navigate, training: { ...modTraining } })
            if (data) {
                // Notificar postulantes
                if (postulants.length > 0) {
                    const envios = postulants.map(p => {
                        if (p.usuId !== undefined && p.usuId !== null)
                            return async function () {
                                return postulantService.sendEmailEnroll({ navigate, postulant: { posId: p.posId } })
                            }
                        else
                            return async function () {
                                return postulantService.sendEmail({ navigate, postulant: { posId: p.posId } })
                            }
                    })
                    setIsSendingEmail(true)
                    envios
                        .reduce((promise, fn) =>
                            promise.then(fn)
                                .then(r => {
                                    const { data } = r
                                    if (data === false)
                                        toast.error(getLabel(labels.couldNotSendEmail))
                                    else
                                        toast.success(getLabel(labels.emailSentSuccessfully))
                                })
                                .catch(({ response }) => {
                                    toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
                                })
                            , Promise.resolve())
                        .then(_ => {
                            setIsSendingEmail(false)
                            toast.success(`${getLabel(labels.training)} ${data.listObject[0].entNombre} ${getLabel(labels.publishSuccesfully)}`)
                            navigate.push('/training')
                        })
                } else {
                    toast.success(`${getLabel(labels.training)} ${data.listObject[0].entNombre} ${getLabel(labels.publishSuccesfully)}`)
                    navigate.push('/training')
                }
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsPublishing(false)
        }
    }

    useEffect(() => {
        if (load) {
            fetchData()
        }
        if (loadPostulant) {
            fetchPostulants()
        }
    })

    return (
        <>
            {
                isLoading ?
                    <Row style={{ padding: '30px 10px' }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Spin size='large' tip={
                                <Typography.Paragraph style={{ padding: '10px 0' }}>
                                    {getLabel(labels.loading)}...
                                </Typography.Paragraph>
                            } />
                        </Col>
                    </Row>
                    :
                    training && <>
                        <Form
                            form={formCourse}
                            layout='vertical'
                            initialValues={
                                training
                            }
                            onFinish={save}
                            disabled={isSaving || isPublishing || isSendingEmail}
                        >
                            <div className="grid">
                                <div className="col-12">
                                    <div className="mgeCard">
                                        <div className="grid">
                                            <div className="col-4">
                                                <Space direction='horizontal'>
                                                    <Button icon={<i className="fa-solid fa-chevron-left"></i>}
                                                        onClick={() => {
                                                            navigate.push('/training')
                                                        }}
                                                    ></Button>
                                                    <h3 style={{ padding: '0', margin: '0' }} className='mge-darkGreen'>
                                                        {getLabel(labels.edit)} {getLabel(labels.training)}
                                                    </h3>
                                                </Space>
                                            </div>
                                            <div className="col-8" style={{ textAlign: 'right' }}>
                                                <Space>
                                                    <Button
                                                        type='default'
                                                        htmlType='submit'
                                                        loading={isSaving}
                                                        disabled={isSaving || training.entEstado.catValor === 'ACTIVE'}
                                                    >{getLabel(labels.saveDraft)}</Button>
                                                    <Popconfirm title={getLabel(labels.surePublishTraining)} okText={getLabel(labels.yesPublish)} cancelText={getLabel(labels.no)} onConfirm={() => pub()}
                                                        okButtonProps={{
                                                            loading: isPublishing || isSendingEmail,
                                                        }}
                                                    >
                                                        <Button
                                                            type='primary'
                                                            loading={isPublishing || isSendingEmail}
                                                            disabled={(isPublishing || isSendingEmail) || training.entEstado.catValor === 'ACTIVE'}
                                                        >{getLabel(labels.publish)}</Button>
                                                    </Popconfirm>
                                                </Space>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row justify='center'>
                                <Col span={24} style={{ display: 'none' }}>
                                    <Form.Item
                                        name='entId'
                                        label={getLabel(labels.idTraining)}
                                        rules={[
                                            {
                                                required: true,
                                                message: getLabel(labels.completeID),
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
                                    <Row gutter={[25, 25]}>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item
                                                name={['entRegion', 'catId']}
                                                label={getLabel(labels.region)}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: getLabel(labels.selectRegion),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    style={{
                                                        width: '100%',
                                                        display: 'inline-block'
                                                    }}
                                                    fieldNames={{
                                                        label: 'catNombre',
                                                        value: 'catId'
                                                    }}
                                                    showSearch
                                                    options={getCatalogos().regions}
                                                    optionFilterProp="catNombre"
                                                    onChange={(_, o) => {
                                                        if (o.catValor === 'CAT_REG_AND') {
                                                            const entCultura = getCultures().filter(c => c.id === 'es_EC')[0].id
                                                            formCourse.setFieldValue('entCultura', entCultura)
                                                        }
                                                        else if (o.catValor === 'CAT_REG_CAR') {
                                                            const entCultura = getCultures().filter(c => c.id === 'en_US')[0].id
                                                            formCourse.setFieldValue('entCultura', entCultura)
                                                        } else {
                                                            const entCultura = getCultures().filter(c => c.id === 'en_US')[0].id
                                                            formCourse.setFieldValue('entCultura', entCultura)
                                                        }
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                            <Form.Item
                                                name='entCultura'
                                                label={getLabel(labels.language)}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: getLabel(labels.selectLanguage),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled
                                                    style={{
                                                        width: '100%',
                                                        display: 'inline-block'
                                                    }}
                                                    fieldNames={{
                                                        label: 'value',
                                                        value: 'id'
                                                    }}
                                                    options={getCultures()}
                                                    showSearch
                                                    optionFilterProp="value"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[25, 25]}>
                                        <Col span={24}>
                                            <Form.Item
                                                name='countries'
                                                label={getLabel(labels.country)}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: getLabel(labels.selectCountry),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={isLoadingCountry}
                                                    mode='multiple'
                                                    fieldNames={{
                                                        label: 'catNombre',
                                                        value: 'catId'
                                                    }}
                                                    options={
                                                        getCatalogos().paises.map(p => {
                                                            const { catNombre, catDescripcion } = p
                                                            const flag = catDescripcion.toLowerCase()
                                                            return (
                                                                {
                                                                    ...p,
                                                                    catNombre: <>
                                                                        <img
                                                                            src={`https://flagcdn.com/24x18/${flag}.png`}
                                                                            srcSet={`https://flagcdn.com/48x36/${flag}.png 2x,https://flagcdn.com/72x54/${flag}.png 3x`}
                                                                            width="24"
                                                                            height="18"
                                                                            alt={catNombre} />&nbsp;
                                                                        {catNombre}
                                                                    </>
                                                                }
                                                            )
                                                        }
                                                        )
                                                    }
                                                    onSelect={
                                                        (_, option) => setCountry({
                                                            checked: true,
                                                            idPais: option.catId
                                                        })
                                                    }
                                                    onDeselect={
                                                        (_, option) => setCountry({
                                                            checked: false,
                                                            paeId: paises.filter(c => c.paePais.catId === option.catId).length > 0 ? paises.filter(c => c.paePais.catId === option.catId)[0].paeId : 0
                                                        })
                                                    }
                                                >
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[25, 25]}>
                                        <Col span={24}>
                                            <Form.Item
                                                name='entNombre'
                                                label={getLabel(labels.trainingName)}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: getLabel(labels.completeTrainingName),
                                                    },
                                                ]}
                                            >
                                                <Input disabled />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[25, 25]}>
                                        <Col span={24}>
                                            <Form.Item
                                                name={['entModalidad', 'catId']}
                                                label={getLabel(labels.modality)}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: getLabel(labels.selectModality),
                                                    },
                                                ]}
                                                style={{ width: '100%', textAlign: 'center' }}
                                            >
                                                <Radio.Group buttonStyle='solid'
                                                >
                                                    {
                                                        getCatalogos().modalidades.map(m => renderModality(m))
                                                    }
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[25, 25]}>
                                        <Col span={12}>
                                            <Form.Item
                                                name='entFechaIni'
                                                label={getLabel(labels.startDate)}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: getLabel(labels.selectStartDate),
                                                    },
                                                ]}
                                            >
                                                <DatePicker style={{ width: '100%' }} onChange={setStartDate} disabledDate={disabledDateStart} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name='entFechaFin'
                                                label={getLabel(labels.endDate)}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: getLabel(labels.selectEndDate),
                                                    },
                                                ]}
                                            >
                                                <DatePicker style={{ width: '100%' }} onChange={setEndDate} disabledDate={disabledDateEnd} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[25, 25]}>
                                        <Col span={24}>
                                            <Form.Item
                                                name='courses'
                                                label={getLabel(labels.courses)}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: getLabel(labels.selectCourses),
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    disabled={isLoadingCourse}
                                                    mode='multiple'
                                                    showSearch
                                                    fieldNames={{
                                                        label: 'catNombre',
                                                        value: 'catId'
                                                    }}
                                                    options={getCatalogos().cursos}
                                                    optionFilterProp="catNombre"
                                                    onSelect={
                                                        (_, option) => setCourse({
                                                            checked: true,
                                                            idNombreCurso: option.catId
                                                        })
                                                    }
                                                    onDeselect={
                                                        (_, option) => setCourse({
                                                            checked: false,
                                                            curId: courses.filter(c => c.curNombre.catId === option.catId).length > 0 ? courses.filter(c => c.curNombre.catId === option.catId)[0].curId : 0
                                                        })
                                                    }
                                                >
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                        <Row justify='center'>
                            <Col xs={22} sm={22} md={20} lg={18} xl={18} xxl={16}>
                                <Row>
                                    <Form
                                        form={formPostulante}
                                        layout='vertical'
                                        style={{ width: '100%' }}
                                        onFinish={setPostulant}
                                        initialValues={
                                            {
                                                posEmail: ''
                                            }
                                        }
                                        disabled={isSaving || isPublishing || isSendingEmail}
                                    >
                                        <Col span={24}>
                                            <Form.Item
                                                name={['posEmail']}
                                                label={getLabel(labels.applicantsEmails)}
                                                extra={getLabel(labels.pressEnterAddEmail)}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: getLabel(labels.addApplicants),
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: getLabel(labels.emailNotValid)
                                                    }
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            {
                                                message.message &&
                                                <Alert {...message} showIcon closable />
                                            }
                                        </Col>
                                        <Col span={24} style={{ paddingTop: '10px' }}>
                                            {
                                                isSendingEmail ?
                                                    <>
                                                        <AlertWait error='' isLoading={isSendingEmail} />
                                                    </>
                                                    :
                                                    <Row gutter={[15, 15]}>
                                                        {
                                                            postulants && postulants.map(a => renderApplicants(a))
                                                        }
                                                    </Row>
                                            }
                                        </Col>
                                    </Form>
                                </Row>
                            </Col>
                        </Row>
                    </>
            }
        </>
    )
}

export default Training