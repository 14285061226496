import React from "react";
const AppFooter = () => {
  return (
    <div className="layout-footer" style={{ display: "none" }}>
      <div className="grid">
        <div className="col-12">
          <div className="footer-bottom">
            <h4>
              <div className="mge mge-bold">
                <span className="mge-lightGreen">
                  My
                  <br />
                  Green
                </span>
                <br />
                <span className="mge-green">Enterprise</span>
              </div>
            </h4>
            {/* <h6>Copyright Ⓒ {
              new Date().getFullYear()
            }</h6> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
