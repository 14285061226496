import AxiosInstance from "../utils/AxiosInstance";
import { insertCountryTraining, getCountryTrainingByTraining, deleteCountryTraining } from "../utils/urls";
export class CountryTrainingService {
    put = (props) => {
        const { navigate, countryTraining } = props
        return AxiosInstance(navigate).put(insertCountryTraining(), countryTraining)
    }
    getByTraining = (props) => {
        const { navigate, countryTraining } = props
        return AxiosInstance(navigate).post(getCountryTrainingByTraining(), countryTraining)
    }
    del = (props) => {
        const { navigate, countryTraining } = props
        return AxiosInstance(navigate).post(deleteCountryTraining(), countryTraining)
    }
}

export default CountryTrainingService