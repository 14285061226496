import axios from "axios"
import AxiosInstance from "../utils/AxiosInstance"
import { deletePostulant, getPostulantByTraining, getTrainingByPostulant, insertPostulant, sendEmailRegister, validatePostulant, sendEmailEnroll } from "../utils/urls"
import { getCulture } from "../utils/Internationalization"

export class PostulantService {
    put = (props) => {
        const { navigate, postulant } = props
        return AxiosInstance(navigate).put(insertPostulant(), postulant)
    }
    sendEmail = (props) => {
        const { navigate, postulant } = props
        return AxiosInstance(navigate).patch(sendEmailRegister(), postulant)
    }
    sendEmailEnroll = (props) => {
        const { navigate, postulant } = props
        return AxiosInstance(navigate).patch(sendEmailEnroll(), postulant)
    }
    getByTraining = (props) => {
        const { navigate, training } = props
        return AxiosInstance(navigate).post(getPostulantByTraining(), training)
    }
    del = (props) => {
        const { navigate, postulant } = props
        return AxiosInstance(navigate).post(deletePostulant(), postulant)
    }
    getTraining = (props) => {
        const { navigate, postulant } = props
        return AxiosInstance(navigate).post(getTrainingByPostulant(), postulant)
    }
    validate = (props) => {
        const { postulant } = props
        const axiosInstance = axios.create({ headers: { "Accept-Language": getCulture() } })
        return axiosInstance.post(validatePostulant(), postulant)
    }
}

export default PostulantService