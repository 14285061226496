import React, { useState } from 'react'
import { Col, Radio, Row, Typography, Space, Button } from 'antd'
import { getCultures } from '../../utils/Utils'
import { getCulture, getLabel, labels, setCulture } from '../../utils/Internationalization'
import toast from 'react-hot-toast'
const { Title, Paragraph, Text } = Typography

const LanguagePreferences = () => {

    const [value, setValue] = useState(getCulture())

    const renderCulture = ({ id, value }) => <Radio key={id} value={id}>{value}</Radio>

    const onChange = (e) => {
        setValue(e.target.value)
        const culture = { culture: e.target.value }
        setCulture(culture)
        toast.success(getLabel(labels.languagePreferencesSaved))
    }

    return (
        <Row justify='center' gutter={[15, 20]}>
            <Col span={24}>
                <div className='mgeCard' style={{ padding: '15px 20px 10px 20px' }}>
                    <Typography>
                        <Title level={3}>
                            <span className='mge-darkGreen'>
                                {getLabel(labels.languagePreferences)}
                            </span>
                        </Title>
                        <Paragraph>
                            <Text type='secondary'>
                                {getLabel(labels.seePreferredLanguage)}
                            </Text>
                        </Paragraph>
                    </Typography>
                </div>
            </Col>
            <Col span={24}>
                <div style={{ padding: '15px 20px 10px 20px' }}>
                    <Radio.Group onChange={onChange} value={value}>
                        <Space direction='horizontal'>
                            {
                                getCultures().map(c => renderCulture(c))
                            }
                        </Space>
                    </Radio.Group>
                </div>
            </Col>
            <Col span={24}>
                <div style={{ padding: '15px 20px 10px 20px', textAlign: 'end' }}>
                    <Button
                        type='default'
                        onClick={() => {
                            window.history.go(-1)
                        }}
                    >
                        {getLabel(labels.back)}
                    </Button>
                </div>
            </Col>
        </Row>
    )
}

export default LanguagePreferences