import AxiosInstance from '../utils/AxiosInstance'
import { downloadCertificateByName, downloadCertificateByUuid, generateCertificate, sendEmailCertificate } from '../utils/urls'
export class CertificateService {
    generate = (props) => {
        const { navigate, course } = props
        return AxiosInstance(navigate).post(generateCertificate(), course)
    }
    send = (props) => {
        const { navigate, certificate } = props
        return AxiosInstance(navigate).patch(sendEmailCertificate(), certificate)
    }
    downloadByName = (props) => {
        const { navigate, certificate } = props
        return AxiosInstance(navigate).post(downloadCertificateByName(), certificate)
    }
    downloadByUuid = (props) => {
        const { navigate, certificate } = props
        return AxiosInstance(navigate).post(downloadCertificateByUuid(), certificate)
    }
}

export default CertificateService