import AxiosInstance from "../utils/AxiosInstance";
import { deleteCourse, getCourseByTraining, insertCourse } from "../utils/urls";
export class CourseService {
    put = (props) => {
        const { navigate, course } = props
        return AxiosInstance(navigate).put(insertCourse(), course)
    }
    getByTraining = (props) => {
        const { navigate, training } = props
        return AxiosInstance(navigate).post(getCourseByTraining(), training)
    }
    del = (props) => {
        const { navigate, course } = props
        return AxiosInstance(navigate).post(deleteCourse(), course)
    }
}

export default CourseService