import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Typography, Button, Badge, Space, Spin, Result, Card, Checkbox } from 'antd'

import TrainingService from '../../service/TrainingService'
import { getDatosUsuario } from '../../utils/Utils'
import { getLabel, labels } from '../../utils/Internationalization'

const trainingService = new TrainingService()

const Courses = () => {

    const navigate = useHistory()
    const [state, setState] = useState(null)
    const [courses, setCourses] = useState([])
    const [load, setLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(null)

    const renderCourse = ({ entId, entNombre, entFechaIni, entFechaFin, entModalidad, numPostulantes, entEstado, cursoList }) => {
        const color = entEstado.catValor === 'ACTIVE' ? '#54873C' : entEstado.catValor === 'INACTIVE' ? 'red' : '#636466'
        return (
            <Col key={entId} xs={24} sm={12} md={12} lg={12} xl={8} xxl={6}>
                <Card
                    hoverable
                >
                    <Row style={{ lineHeight: '1.8em' }}>
                        <Col span={16}>
                            <Typography.Title level={5}>
                                <span className='mge-darkGreen' style={{ fontWeight: 'bold' }}>
                                    {entNombre}
                                </span>
                            </Typography.Title>
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }}>
                            <Badge color={color} text={entEstado.catNombre} />
                        </Col>
                        <Col span={24}>
                            <Typography.Text>
                                {entModalidad.catNombre}
                            </Typography.Text>
                        </Col>
                        <Col span={24}>
                            <Typography.Text strong>
                                {entFechaIni.substring(0, 10).split('-').reverse().join('/')} - {entFechaFin.substring(0, 10).split('-').reverse().join('/')}
                            </Typography.Text>
                        </Col>
                        <Col span={24}>
                            <Space direction='horizontal'>
                                <Typography.Text type='secondary'>
                                    {cursoList.length} {getLabel(labels.courses)}
                                </Typography.Text>
                                <Typography.Text type='secondary'>
                                    {numPostulantes} {getLabel(labels.applicants)}
                                </Typography.Text>
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>&nbsp;</Col>
                    </Row>
                    <Row gutter={[20]}>
                        <Col span={12}>
                            <Button type='default' block
                                onClick={() => {
                                    navigate.push(`/training/${entId}`)
                                }}
                                style={
                                    {
                                        borderColor: '#70B450',
                                        borderWidth: '2px',
                                        color: '#70B450',
                                        height: '40px'
                                    }
                                }
                            >
                                {
                                    getLabel(labels.view)
                                }
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Button type='default' block
                                onClick={() => {
                                    navigate.push(`/trainingedit/${entId}`)
                                }}
                                style={
                                    {
                                        borderColor: '#70B450',
                                        borderWidth: '2px',
                                        color: '#70B450',
                                        height: '40px'
                                    }
                                }
                            >
                                {getLabel(labels.edit)}
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Col>
        )
    }

    const fetchData = async () => {
        setLoad(false)
        setIsLoading(true)
        setCourses([])
        let trainer = { idUsuario: getDatosUsuario().usuarioId }
        if (state) {
            trainer = { ...trainer, estado: !state }
        }
        try {
            const { data } = await trainingService.getByTraining({ navigate, trainer })
            if (data) {
                setError(null)
                setCourses(data.listObject)
            } else {
                setError(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            setError(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (load) {
            fetchData()
        }
    })

    return (
        <>
            <div className="mgeCard" style={{
                paddingTop: '15px',
                paddingBottom: '15px'
            }}>
                <Row>
                    <Col
                        xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}
                    >
                        <h3 style={{ padding: '0', margin: '0' }} className='mge-darkGreen'>
                            {getLabel(labels.trainings)}
                        </h3>
                    </Col>
                    <Col
                        xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}
                        style={{
                            textAlign: 'right'
                        }}
                    >
                        <Space>
                            <Button
                                type='default'
                                loading={isLoading}
                                disabled={isLoading}
                                onClick={() => {
                                    fetchData()
                                }}>{getLabel(labels.refresh)}</Button>
                            <Button
                                type='primary'
                                disabled={isLoading}
                                onClick={() => {
                                    navigate.push('/trainingnew')
                                }}>{getLabel(labels.newTraining)}</Button>
                        </Space>
                    </Col>
                </Row>
            </div>
            <Row>
                <Col span={24} style={{ textAlign: 'end', paddingTop: '10px', paddingBottom: '10px' }}>
                    <Checkbox checked={state} onChange={(e) => setState(e.target.checked)}>
                        {getLabel(labels.onlyInactive)}
                    </Checkbox>
                </Col>
            </Row>
            {
                isLoading ?
                    <Row style={{ padding: '30px 10px' }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Spin size='large' tip={
                                <Typography.Paragraph style={{ padding: '10px 0' }}>
                                    {getLabel(labels.loading)}...
                                </Typography.Paragraph>
                            } />
                        </Col>
                    </Row>
                    :
                    error ? <>
                        <Result
                            title={error}
                        />
                    </> :
                        <Row gutter={[25, 25]} justify='start'>
                            {
                                courses && courses.map(c => renderCourse(c))
                            }
                        </Row>
            }
        </>
    )
}

export default Courses