import AxiosInstance from './../utils/AxiosInstance'
import { getOpcionesPorPerfilUrl, getActualizarPerfilOpcionUrl } from './../utils/urls'
export default class PerfilOpcionService {
    gets = (props) => {
        const { navigate } = props
        return AxiosInstance(navigate).get(getOpcionesPorPerfilUrl())
    }
    set = (props) => {
        const { navigate, perfilOpcion } = props
        return AxiosInstance(navigate).post(getActualizarPerfilOpcionUrl(), perfilOpcion)
    }
}