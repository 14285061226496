import axios from 'axios'
import { getToken } from './Utils'
import { getCulture, getLabel, labels } from './Internationalization'
import toast from 'react-hot-toast'

const AxiosInstance = (navigate) => {

    const axiosInstance = axios.create({
        headers: {
            "Accept-Language": getCulture()
        }
    })

    axiosInstance.interceptors.request.use(
        (config) => {
            const token = getToken()
            if (token === undefined || token === null || token === "") {
                toast.error(getLabel(labels.sessionExpired))
                navigate.push('/login')
            }
            config.headers['Authorization'] = `Bearer ${token}`
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    );

    axiosInstance.interceptors.response.use(
        (response) => {
            if (response.status === 401 || response.status === 403) {
                localStorage.removeItem('mgeToken')
                localStorage.removeItem('mgeDatosUsuario')
                localStorage.removeItem('mgeTrainings')
                localStorage.removeItem('mgeInformList')
                toast.error(getLabel(labels.sessionExpired))
                navigate.push('/login')
            }
            return response
        },
        (error) => {
            if (error && error.response) {
                const { response } = error
                if (response.status === 401 || response.status === 403) {
                    localStorage.removeItem('mgeToken')
                    localStorage.removeItem('mgeDatosUsuario')
                    localStorage.removeItem('mgeTrainings')
                    localStorage.removeItem('mgeInformList')
                    toast.error(getLabel(labels.sessionExpired))
                    navigate.push('/login')
                }
            }
            return Promise.reject(error)
        }
    )
    return axiosInstance
}

export default AxiosInstance