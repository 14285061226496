import AxiosInstance from "./../utils/AxiosInstance"
import { getOpcionesUrl, getOpcionesPorPadreUrl, getOpcionPorIdUrl, getInsertarOpcionUrl, getActualizarOpcionUrl, getEliminarOpcionUrl } from "./../utils/urls"
export default class OpcionService {
    gets = (props) => {
        const { navigate } = props
        return AxiosInstance(navigate).get(getOpcionesUrl())
    }
    getsPorPadre = (props) => {
        const { navigate, opcion } = props
        return AxiosInstance(navigate).post(getOpcionesPorPadreUrl(), opcion)
    }
    get = (props) => {
        const { navigate, opcion } = props
        return AxiosInstance(navigate).post(getOpcionPorIdUrl(), opcion)
    }
    set = (props) => {
        const { navigate, opcion } = props
        if (opcion.opcId !== undefined && opcion.opcId !== null && opcion.opcId !== '') {
            return AxiosInstance(navigate).patch(getActualizarOpcionUrl(), opcion)
        } else {
            return AxiosInstance(navigate).put(getInsertarOpcionUrl(), opcion)
        }
    }
    del = (props) => {
        const { navigate, opcion } = props
        return AxiosInstance(navigate).post(getEliminarOpcionUrl(), opcion)
    }
}