import AxiosInstance from '../utils/AxiosInstance'
import { deleteBeneficiary, downloadBeneficiariesReport, getBeneficiariesByPostulant, getBeneficiaryById, insertBeneficiary, updateBeneficiary, validateBeneficiary } from '../utils/urls'
export class BeneficiaryService {
    put = (props) => {
        const { navigate, beneficiary } = props
        return AxiosInstance(navigate).put(insertBeneficiary(), beneficiary)
    }
    set = (props) => {
        const { navigate, beneficiary } = props
        return AxiosInstance(navigate).patch(updateBeneficiary(), beneficiary)
    }
    get = (props) => {
        const { navigate, beneficiary } = props
        return AxiosInstance(navigate).post(getBeneficiaryById(), beneficiary)
    }
    getByPostulant = (props) => {
        const { navigate, beneficiary } = props
        return AxiosInstance(navigate).post(getBeneficiariesByPostulant(), beneficiary)
    }
    del = (props) => {
        const { navigate, beneficiary } = props
        return AxiosInstance(navigate).post(deleteBeneficiary(), beneficiary)
    }
    val = (props) => {
        const { navigate, beneficiary } = props
        return AxiosInstance(navigate).patch(validateBeneficiary(), beneficiary)
    }
    downloadBeneficiariesReport = (props) => {
        const { navigate } = props
        return AxiosInstance(navigate).patch(downloadBeneficiariesReport())
    }
}

export default BeneficiaryService