import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Col, Row, Space, Button, Table, Image, Drawer, Typography, Collapse, Popconfirm, Switch, Result, Modal } from 'antd'
import * as anticons from '@ant-design/icons'
import toast from 'react-hot-toast'
import AlertWait from '../../pages/AlertWait'
import { getCatalogos, getDatosUsuario } from '../../utils/Utils'
import { getLabel, labels } from '../../utils/Internationalization'

import PostulantService from '../../service/PostulantService'
import BeneficiaryService from './../../service/BeneficiaryService'
import CertificateService from '../../service/CertificateService'
import Beneficiary from './Beneficiary'

const postulantService = new PostulantService()
const beneficiaryService = new BeneficiaryService()
const certificateService = new CertificateService()

const { Title, Paragraph, Text } = Typography
const { confirm } = Modal

const tableProps = {
    bordered: false,
    loading: false,
    title: '',
    showHeader: true,
    size: 'medium',
    pagination: {
        position: ['none', 'bottomRight']
    }
}
const panelStyle = {
    marginBottom: 20,
    backgroundColor: '#fafafa',
    border: 'none',
    borderRadius: '15px'
}

const Beneficiaries = () => {
    const navigate = useHistory()
    const { operation } = useParams()
    const [load, setLoad] = useState(true)
    const [isOpening, setIsOpening] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingBeneficiaries, setIsLoadingBeneficiaries] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [trainings, setTrainings] = useState([])
    // const [postulant, setPostulant] = useState({})
    const [beneficiaryC1, setBeneficiaryC1] = useState(null)
    const [beneficiaryC2, setBeneficiaryC2] = useState(null)
    const [openC1, setOpenC1] = useState(false)
    const [openC2, setOpenC2] = useState(false)
    const [selectedBeneficiary, setSelectedBeneficiary] = useState({ benId: 0 })
    const [openDeleteBeneficiary, setOpenDeleteBeneficiary] = useState(false);

    const [isGenerating, setIsGenerating] = useState(false)
    const [isSendingCertificate, setIsSendingCertificate] = useState(false)

    const closeBeneficiaryC1 = () => {
        setOpenC1(false)
    }

    const getBeneficiaryC1 = async (t, { benId, idCurso }) => {
        setBeneficiaryC1(null)
        setIsLoadingBeneficiaries(true)
        try {
            const { data } = await beneficiaryService.get({ navigate, beneficiary: { benId } })
            const beneficiary = {
                ...data.listObject[0],
                idTipo: data.listObject[0].benTipo.catId,
                idPais: data.listObject[0].benPais.catId,
                idSexo: data.listObject[0].benSexo.catId,
                idNivelInt: data.listObject[0].benNivelInt.catId,
                editDisabled: t.certificadoList.filter(c => c.idCurso === idCurso).length > 0
            }
            setBeneficiaryC1(beneficiary)
            setOpenC1(true)
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoadingBeneficiaries(false)
        }
    }

    const saveBeneficiaryC1 = async (values) => {
        setIsSaving(true)
        const beneficiary = { ...values }
        try {
            const { data } = (beneficiary.benId !== undefined ? await beneficiaryService.set({ navigate, beneficiary }) : await beneficiaryService.put({ navigate, beneficiary }))
            if (data) fetchData(2)
            closeBeneficiaryC1()
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsSaving(false)
        }
    }

    const newBeneficiary = time => new Promise(resolve => setTimeout(resolve, time * 1000))

    const closeBeneficiaryC2 = () => {
        setOpenC2(false)
    }

    const getBeneficiaryC2 = async (t, { benId, idCurso }) => {
        setBeneficiaryC2(null)
        setIsLoadingBeneficiaries(true)
        try {
            const { data } = await beneficiaryService.get({ navigate, beneficiary: { benId } })
            const beneficiary = {
                ...data.listObject[0],
                idTipo: data.listObject[0].benTipo.catId,
                idPais: data.listObject[0].benPais.catId,
                idSexo: data.listObject[0].benSexo.catId,
                editDisabled: t.certificadoList.filter(c => c.idCurso === idCurso).length > 0
            }
            setBeneficiaryC2(beneficiary)
            setOpenC2(true)
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoadingBeneficiaries(false)
        }
    }

    const saveBeneficiaryC2 = async (values) => {
        setIsSaving(true)
        const beneficiary = { ...values }
        try {
            const { data } = (beneficiary.benId !== undefined ? await beneficiaryService.set({ navigate, beneficiary }) : await beneficiaryService.put({ navigate, beneficiary }))
            if (data) fetchData(2)
            closeBeneficiaryC2()
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsSaving(false)
        }
    }

    const showReconfirmDeleteBeneficiary = () => {
        setOpenDeleteBeneficiary(true)
    }

    const confirmDeleteBeneficiary = async () => {
        setIsDeleting(true)
        try {
            const { data } = await beneficiaryService.del({ navigate, beneficiary: selectedBeneficiary })
            if (data) {
                toast.success(getLabel(labels.beneficiarySuccessfullyRemoved))
                fetchData(2)
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setSelectedBeneficiary({ benId: 0 })
            setIsDeleting(false)
            setOpenDeleteBeneficiary(false)
        }
    }

    const cancelDeleteBeneficiary = () => {
        setOpenDeleteBeneficiary(false)
    }

    const delBeneficiary = async (t, curLimBen, { benId }) => {
        setSelectedBeneficiary({ benId })
        if ((t.beneficiarioList.length - 1) < (curLimBen || 1)) {
            showReconfirmDeleteBeneficiary()
        } else {
            setIsDeleting(true)
            try {
                const { data } = await beneficiaryService.del({ navigate, beneficiary: { benId } })
                if (data) {
                    toast.success(getLabel(labels.beneficiarySuccessfullyRemoved))
                    fetchData(2)
                }
            } catch (error) {
                const { response } = error
                toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
            } finally {
                setSelectedBeneficiary({ benId: 0 })
                setIsDeleting(false)
            }
        }
    }

    const validateBeneficiary = async (values) => {
        setIsSaving(true)
        const beneficiary = { ...values }
        try {
            const { data } = await beneficiaryService.val({ navigate, beneficiary })
            if (data)
                toast.success(getLabel(labels.beneficiarySuccessfullyUpdated))
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsSaving(false)
        }
    }

    const sendCertificate = async ({ cerId }) => {
        setIsSendingCertificate(true)
        try {
            const certificate = { cerId }
            const { data } = await certificateService.send({ navigate, certificate })
            if (data) toast.success(getLabel(labels.certificateSentSuccessfully))
            setLoad(true)
        } catch (_) {
            toast.error(getLabel(labels.certificateSendFailed))
        } finally {
            setIsSendingCertificate(false)
        }
    }

    const generate = async ({ idCurso, idPostulante }) => {
        setIsGenerating(true)
        try {
            const course = { idCurso, idPostulante }
            const { data } = await certificateService.generate({ navigate, course })
            const certificate = data.listObject[0]
            toast.success(getLabel(labels.certificateGeneratedSuccessfully))
            sendCertificate(certificate)
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsGenerating(false)
        }
    }

    const sendBeneficiaries = async ({ idCurso, idPostulante }) => {
        confirm({
            title: getLabel(labels.sendBeneficiaryListTitle),
            icon: <anticons.ExclamationCircleFilled />,
            content: getLabel(labels.sendBeneficiaryListConfirm),
            okText: getLabel(labels.ok),
            cancelText: getLabel(labels.cancel),
            onOk() {
                generate({ idCurso, idPostulante })
            }
        })
    }

    const renderCourse = ({ curId, curNombre, curLimBen }, t, posId, idEntrenamiento) =>
        <Collapse.Panel
            key={`${idEntrenamiento}_${curId}`}
            header={
                <Row gutter={10}>
                    <Col>
                        {
                            operation === 'registry' &&
                            <>
                                {
                                    t && t.beneficiarioList && t.beneficiarioList.filter(b => b.idCurso === curId && b.idPostulante === posId).length >= (curLimBen || 1) ?
                                        <Image src={'./../../assets/accept.png'} preview={false} width={24} height={24} />
                                        :
                                        <Image src={'./../../assets/minus.png'} preview={false} width={24} height={24} />
                                }
                            </>
                        }
                        {
                            operation === 'qualification' &&
                            <>
                                <>
                                    {
                                        t && t.certificadoList && t.certificadoList.filter(c => c.idCurso === curId && c.idPostulante === posId && c.cerEstado.catValor === 'EST_CER_ACT').length > 0 ?
                                            <Image src={'./../../assets/accept.png'} preview={false} width={24} height={24} />
                                            :
                                            <Image src={'./../../assets/minus.png'} preview={false} width={24} height={24} />
                                    }
                                </>
                            </>
                        }
                    </Col>
                    <Col>
                        <Typography>
                            <Text strong style={{ fontSize: '1.1em' }}>
                                {curNombre.catNombre}
                            </Text>
                        </Typography>
                    </Col>
                </Row>
            }
            style={panelStyle}
        >
            {
                operation === 'registry' &&
                <>
                    {
                        t && t.informeList && t.entId && t.entId.cursoList &&
                            t.informeList.filter(w => w.infTipo.catValor === 'TYP_INF_WPL' && w.infEstado.catValor === 'EST_INF_APR').length > 0 ?
                            <>
                                <Row>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Typography>
                                            <Paragraph>
                                                <Text type='secondary' strong>
                                                    {`${getLabel(labels.minimumBeneficiaries)} (${curLimBen || 1})`}
                                                </Text>
                                            </Paragraph>
                                        </Typography>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}
                                        style={{ textAlign: 'right' }}>
                                        <Space>
                                            <Button
                                                type='default'
                                                loading={isLoading || isLoadingBeneficiaries}
                                                disabled={isLoading || isLoadingBeneficiaries || isGenerating || isSendingCertificate}
                                                onClick={() => {
                                                    fetchData(2)
                                                }}>{getLabel(labels.refresh)}</Button>
                                            {
                                                t.certificadoList.filter(c => c.idCurso === curId).length < 1 && <Button
                                                    type='primary'
                                                    loading={isOpening}
                                                    disabled={isLoading || isLoadingBeneficiaries || isGenerating || isSendingCertificate}
                                                    onClick={() => {
                                                        if (curNombre.catValor === 'CUR_NEG_VER1') {
                                                            setIsOpening(true)
                                                            newBeneficiary(0.5).then(() => {
                                                                setBeneficiaryC1(null)
                                                                setBeneficiaryC1({
                                                                    idCurso: curId,
                                                                    idPostulante: posId,
                                                                    benTrabaja: false,
                                                                    idTipo: getCatalogos().typePersons.filter(t => t.catValor === (curNombre.catReferencia.catValor || 'TYP_PER_NAT'))[0].catId,
                                                                    editDisabled: false
                                                                })
                                                                setOpenC1(true)
                                                                setIsOpening(false)
                                                            })
                                                        } else if (curNombre.catValor === 'CUR_NEG_VER2') {
                                                            setIsOpening(true)
                                                            newBeneficiary(0.5).then(() => {
                                                                setBeneficiaryC2(null)
                                                                setBeneficiaryC2({
                                                                    idCurso: curId,
                                                                    idPostulante: posId,
                                                                    benTrabaja: false,
                                                                    idTipo: getCatalogos().typePersons.filter(t => t.catValor === (curNombre.catReferencia.catValor || 'TYP_PER_LEG'))[0].catId,
                                                                    editDisabled: false
                                                                })
                                                                setOpenC2(true)
                                                                setIsOpening(false)
                                                            })
                                                        }
                                                    }}>{getLabel(labels.newBeneficiary)}</Button>
                                            }
                                            {
                                                operation === 'qualification' && t.certificadoList.filter(c => c.idCurso === curId).length < 1 &&
                                                <Button type='primary'
                                                    loading={isGenerating || isSendingCertificate}
                                                    disabled={isGenerating || isSendingCertificate || isLoading || isLoadingBeneficiaries}
                                                    onClick={() => sendBeneficiaries(
                                                        {
                                                            idCurso: curId,
                                                            idPostulante: posId
                                                        })}
                                                >
                                                    {
                                                        isGenerating || isSendingCertificate ? getLabel(labels.sending) : getLabel(labels.send)
                                                    }
                                                </Button>
                                            }
                                        </Space>
                                    </Col>
                                </Row>
                                {
                                    t && t.beneficiarioList &&
                                    <Table
                                        loading={isLoading || isLoadingBeneficiaries}
                                        {...tableProps}
                                        rowKey='benId'
                                        columns={[
                                            {
                                                title: getLabel(labels.idNumber),
                                                dataIndex: 'benDocumento',
                                                render: (_, { benDocumento }) => `${benDocumento}`,
                                                responsive: ['md', 'lg', 'xl', 'xxl']
                                            },
                                            {
                                                title: getLabel(labels.name),
                                                dataIndex: 'benNombres',
                                                render: (_, { benNombres }) => `${benNombres}`
                                            },
                                            {
                                                title: getLabel(labels.country),
                                                dataIndex: 'benPais',
                                                render: (_, { benPais }) =>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            src={`https://flagcdn.com/32x24/${benPais.catDescripcion.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/32x24/${benPais.catDescripcion.toLowerCase()}.png 2x,https://flagcdn.com/96x72/${benPais.catDescripcion.toLowerCase()}.png 3x`}
                                                            width="32"
                                                            height="24"
                                                            alt={benPais.catNombre}></img>
                                                    </div>
                                            },
                                            {
                                                title: getLabel(labels.city),
                                                dataIndex: 'benCiudad',
                                                render: (_, { benCiudad }) => `${benCiudad || ''}`,
                                                responsive: ['sm', 'md', 'lg', 'xl', 'xxl']
                                            },
                                            (
                                                curNombre.catReferencia.catValor === 'TYP_PER_NAT' ?
                                                    {
                                                        title: getLabel(labels.sex),
                                                        dataIndex: 'benSexo',
                                                        render: (_, { benSexo }) => `${benSexo.catNombre}`,
                                                        responsive: ['xl', 'xxl']
                                                    } :
                                                    {
                                                        title: getLabel(labels.jobTitle),
                                                        dataIndex: 'benCargo',
                                                        render: (_, { benCargo }) => `${benCargo}`,
                                                        responsive: ['xl', 'xxl']
                                                    }
                                            ),
                                            (
                                                curNombre.catReferencia.catValor === 'TYP_PER_NAT' ?
                                                    {
                                                        title: getLabel(labels.isWorking),
                                                        dataIndex: 'benTrabaja',
                                                        render: (_, { benTrabaja }) => <>{benTrabaja ? getLabel(labels.yes) : getLabel(labels.no)}</>,
                                                        responsive: ['lg', 'xl', 'xxl']
                                                    } :
                                                    {
                                                        title: getLabel(labels.company),
                                                        dataIndex: 'benNomEmpresa',
                                                        render: (_, { benNomEmpresa }) => `${benNomEmpresa}`,
                                                        responsive: ['lg', 'xl', 'xxl']
                                                    }
                                            ),
                                            (
                                                curNombre.catReferencia.catValor === 'TYP_PER_NAT' ?
                                                    {
                                                        title: getLabel(labels.instructionLevel),
                                                        dataIndex: 'benNivelInt',
                                                        render: (_, { benNivelInt }) => `${benNivelInt.catNombre}`,
                                                        responsive: ['lg', 'xl', 'xxl']
                                                    } :
                                                    {
                                                        title: getLabel(labels.totalPersons),
                                                        dataIndex: 'benNumPer',
                                                        render: (_, { benNumPer }) => `${benNumPer}`,
                                                        responsive: ['lg', 'xl', 'xxl']
                                                    }
                                            ),
                                            {
                                                title: getLabel(labels.actions),
                                                dataIndex: '',
                                                render: (_, record) =>
                                                    <Space>
                                                        <Button type='primary'
                                                            icon={<anticons.FolderOpenFilled />}
                                                            onClick={() => {
                                                                if (curNombre.catValor === 'CUR_NEG_VER1') {
                                                                    getBeneficiaryC1(t, record)
                                                                } else if (curNombre.catValor === 'CUR_NEG_VER2') {
                                                                    getBeneficiaryC2(t, record)
                                                                }
                                                            }}
                                                        />
                                                        {
                                                            operation === 'registry' && t.certificadoList.filter(c => c.idCurso === curId).length < 1 &&
                                                            <>
                                                                <Popconfirm title={getLabel(labels.sureDeleteBeneficiary)} okText={getLabel(labels.yes)} cancelText={getLabel(labels.cancel)} onConfirm={() => delBeneficiary(t, curLimBen, record)}
                                                                    okButtonProps={{
                                                                        loading: isDeleting,
                                                                        disabled: isDeleting
                                                                    }}
                                                                >
                                                                    <Button type='default'
                                                                        danger
                                                                        icon={<anticons.DeleteFilled />}
                                                                        loading={isDeleting}
                                                                        disabled={isDeleting}
                                                                    />
                                                                </Popconfirm>
                                                            </>
                                                        }
                                                        {
                                                            operation === 'qualification' &&
                                                            <Switch
                                                                defaultChecked={record.benValidado}
                                                                onChange={(e) => {
                                                                    validateBeneficiary({ ...record, benValidado: e })
                                                                }}
                                                                loading={isSaving}
                                                                disabled={t.certificadoList.filter(c => c.idCurso === curId).length > 0}
                                                            />
                                                        }
                                                    </Space>
                                            }
                                        ]}
                                        dataSource={t.beneficiarioList.filter(b => b.idCurso === curId)}>
                                    </Table>
                                }
                            </>
                            :
                            <Result
                                key={t.idEntrenamiento}
                                title={getLabel(labels.oneApprovedWorkplanRegisterBeneficiaries)}
                                extra={
                                    <Button type="primary"
                                        onClick={() => {
                                            navigate.push('/workplan')
                                        }}
                                    >
                                        {getLabel(labels.sendWorkplan)}
                                    </Button>
                                }
                            />
                    }
                </>
            }
            {
                operation === 'qualification' &&
                <>
                    {
                        t && t.informeList && t.entId && t.entId.cursoList &&
                            t.informeList.filter(w => w.infTipo.catValor === 'TYP_INF_FIR' && w.infEstado.catValor === 'EST_INF_APR').length > 0 ?
                            <>
                                <Row>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                        <Typography>
                                            <Paragraph>
                                                <Text type='secondary' strong>
                                                    {`${getLabel(labels.minimumBeneficiaries)} (${curLimBen || 1})`}
                                                </Text>
                                            </Paragraph>
                                        </Typography>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}
                                        style={{ textAlign: 'right' }}>
                                        <Space>
                                            <Button
                                                type='default'
                                                loading={isLoading || isLoadingBeneficiaries}
                                                disabled={isLoading || isLoadingBeneficiaries || isGenerating || isSendingCertificate}
                                                onClick={() => {
                                                    fetchData(2)
                                                }}>{getLabel(labels.refresh)}</Button>
                                            {
                                                t.certificadoList.filter(c => c.idCurso === curId).length < 1 && <Button
                                                    type='primary'
                                                    loading={isOpening}
                                                    disabled={isLoading || isLoadingBeneficiaries || isGenerating || isSendingCertificate}
                                                    onClick={() => {
                                                        if (curNombre.catValor === 'CUR_NEG_VER1') {
                                                            setIsOpening(true)
                                                            newBeneficiary(0.5).then(() => {
                                                                setBeneficiaryC1(null)
                                                                setBeneficiaryC1({
                                                                    idCurso: curId,
                                                                    idPostulante: posId,
                                                                    benTrabaja: false,
                                                                    idTipo: getCatalogos().typePersons.filter(t => t.catValor === (curNombre.catReferencia.catValor || 'TYP_PER_NAT'))[0].catId,
                                                                    editDisabled: false
                                                                })
                                                                setOpenC1(true)
                                                                setIsOpening(false)
                                                            })
                                                        } else if (curNombre.catValor === 'CUR_NEG_VER2') {
                                                            setIsOpening(true)
                                                            newBeneficiary(0.5).then(() => {
                                                                setBeneficiaryC2(null)
                                                                setBeneficiaryC2({
                                                                    idCurso: curId,
                                                                    idPostulante: posId,
                                                                    benTrabaja: false,
                                                                    idTipo: getCatalogos().typePersons.filter(t => t.catValor === (curNombre.catReferencia.catValor || 'TYP_PER_LEG'))[0].catId,
                                                                    editDisabled: false
                                                                })
                                                                setOpenC2(true)
                                                                setIsOpening(false)
                                                            })
                                                        }
                                                    }}>{getLabel(labels.newBeneficiary)}</Button>
                                            }
                                            {
                                                operation === 'qualification' && t.certificadoList.filter(c => c.idCurso === curId).length < 1 &&
                                                <Button type='primary'
                                                    loading={isGenerating || isSendingCertificate}
                                                    disabled={isGenerating || isSendingCertificate || isLoading || isLoadingBeneficiaries}
                                                    onClick={() => sendBeneficiaries(
                                                        {
                                                            idCurso: curId,
                                                            idPostulante: posId
                                                        })}
                                                >
                                                    {
                                                        isGenerating || isSendingCertificate ? getLabel(labels.sending) : getLabel(labels.send)
                                                    }
                                                </Button>
                                            }
                                        </Space>
                                    </Col>
                                </Row>
                                {
                                    t && t.beneficiarioList &&
                                    <Table
                                        loading={isLoading || isLoadingBeneficiaries}
                                        {...tableProps}
                                        rowKey='benId'
                                        columns={[
                                            {
                                                title: getLabel(labels.idNumber),
                                                dataIndex: 'benDocumento',
                                                render: (_, { benDocumento }) => `${benDocumento}`,
                                                responsive: ['md', 'lg', 'xl', 'xxl']
                                            },
                                            {
                                                title: getLabel(labels.name),
                                                dataIndex: 'benNombres',
                                                render: (_, { benNombres }) => `${benNombres}`
                                            },
                                            {
                                                title: getLabel(labels.country),
                                                dataIndex: 'benPais',
                                                render: (_, { benPais }) =>
                                                    <div style={{ textAlign: 'center' }}>
                                                        <img
                                                            src={`https://flagcdn.com/32x24/${benPais.catDescripcion.toLowerCase()}.png`}
                                                            srcSet={`https://flagcdn.com/32x24/${benPais.catDescripcion.toLowerCase()}.png 2x,https://flagcdn.com/96x72/${benPais.catDescripcion.toLowerCase()}.png 3x`}
                                                            width="32"
                                                            height="24"
                                                            alt={benPais.catNombre}></img>
                                                    </div>
                                            },
                                            {
                                                title: getLabel(labels.city),
                                                dataIndex: 'benCiudad',
                                                render: (_, { benCiudad }) => `${benCiudad || ''}`,
                                                responsive: ['sm', 'md', 'lg', 'xl', 'xxl']
                                            },
                                            (
                                                curNombre.catReferencia.catValor === 'TYP_PER_NAT' ?
                                                    {
                                                        title: getLabel(labels.sex),
                                                        dataIndex: 'benSexo',
                                                        render: (_, { benSexo }) => `${benSexo.catNombre}`,
                                                        responsive: ['xl', 'xxl']
                                                    } :
                                                    {
                                                        title: getLabel(labels.jobTitle),
                                                        dataIndex: 'benCargo',
                                                        render: (_, { benCargo }) => `${benCargo}`,
                                                        responsive: ['xl', 'xxl']
                                                    }
                                            ),
                                            (
                                                curNombre.catReferencia.catValor === 'TYP_PER_NAT' ?
                                                    {
                                                        title: getLabel(labels.isWorking),
                                                        dataIndex: 'benTrabaja',
                                                        render: (_, { benTrabaja }) => <>{benTrabaja ? getLabel(labels.yes) : getLabel(labels.no)}</>,
                                                        responsive: ['lg', 'xl', 'xxl']
                                                    } :
                                                    {
                                                        title: getLabel(labels.company),
                                                        dataIndex: 'benNomEmpresa',
                                                        render: (_, { benNomEmpresa }) => `${benNomEmpresa}`,
                                                        responsive: ['lg', 'xl', 'xxl']
                                                    }
                                            ),
                                            (
                                                curNombre.catReferencia.catValor === 'TYP_PER_NAT' ?
                                                    {
                                                        title: getLabel(labels.instructionLevel),
                                                        dataIndex: 'benNivelInt',
                                                        render: (_, { benNivelInt }) => `${benNivelInt.catNombre}`,
                                                        responsive: ['lg', 'xl', 'xxl']
                                                    } :
                                                    {
                                                        title: getLabel(labels.totalPersons),
                                                        dataIndex: 'benNumPer',
                                                        render: (_, { benNumPer }) => `${benNumPer}`,
                                                        responsive: ['lg', 'xl', 'xxl']
                                                    }
                                            ),
                                            {
                                                title: getLabel(labels.actions),
                                                dataIndex: '',
                                                render: (_, record) =>
                                                    <Space>
                                                        <Button type='primary'
                                                            icon={<anticons.FolderOpenFilled />}
                                                            onClick={() => {
                                                                if (curNombre.catValor === 'CUR_NEG_VER1') {
                                                                    getBeneficiaryC1(t, record)
                                                                } else if (curNombre.catValor === 'CUR_NEG_VER2') {
                                                                    getBeneficiaryC2(t, record)
                                                                }
                                                            }}
                                                        />
                                                        {
                                                            operation === 'registry' && t.certificadoList.filter(c => c.idCurso === curId).length < 1 &&
                                                            <>
                                                                <Popconfirm title={getLabel(labels.sureDeleteBeneficiary)} okText={getLabel(labels.yes)} cancelText={getLabel(labels.cancel)} onConfirm={() => delBeneficiary(t, curLimBen, record)}
                                                                    okButtonProps={{
                                                                        loading: isDeleting,
                                                                        disabled: isDeleting
                                                                    }}
                                                                >
                                                                    <Button type='default'
                                                                        danger
                                                                        icon={<anticons.DeleteFilled />}
                                                                        loading={isDeleting}
                                                                        disabled={isDeleting}
                                                                    />
                                                                </Popconfirm>
                                                            </>
                                                        }
                                                        {
                                                            operation === 'qualification' &&
                                                            <Switch
                                                                defaultChecked={record.benValidado}
                                                                onChange={(e) => {
                                                                    validateBeneficiary({ ...record, benValidado: e })
                                                                }}
                                                                loading={isSaving}
                                                                disabled={t.certificadoList.filter(c => c.idCurso === curId).length > 0}
                                                            />
                                                        }
                                                    </Space>
                                            }
                                        ]}
                                        dataSource={t.beneficiarioList.filter(b => b.idCurso === curId)}>
                                    </Table>
                                }
                            </>
                            :
                            <Result
                                key={t.idEntrenamiento}
                                title={getLabel(labels.oneApprovedFinalReportQualifyBeneficiaries)}
                                extra={
                                    <Button type="primary"
                                        onClick={() => {
                                            navigate.push('/finalreport')
                                        }}
                                    >
                                        {getLabel(labels.sendFinalReport)}
                                    </Button>
                                }
                            />
                    }
                </>
            }
        </Collapse.Panel>

    const fetchData = async (typeLoad) => {
        setLoad(false)
        if (typeLoad) {
            if (typeLoad === 1) {
                setIsLoading(true)
            } else if (typeLoad === 2) {
                setIsLoadingBeneficiaries(true)
            }
        } else {
            setIsLoading(true)
        }
        try {
            const postulant = { idUsuario: getDatosUsuario().usuarioId }
            const { data } = await postulantService.getTraining({ navigate, postulant })
            setTrainings(data.listObject)
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            if (typeLoad) {
                if (typeLoad === 1) {
                    setIsLoading(false)
                } else if (typeLoad === 2) {
                    setIsLoadingBeneficiaries(false)
                }
            } else {
                setIsLoading(false)
            }
        }
    }

    useEffect(() => {
        if (load) fetchData()
    })

    return (
        <>
            <div style={
                {
                    backgroundColor: '#ffffff',
                    padding: '15px 20px 6px 20px',
                    borderRadius: '15px',
                    marginBottom: '20px'
                }
            }>
                <Row>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Typography>
                            <Title type='secondary' level={3}>
                                {
                                    operation === 'registry' ? getLabel(labels.registryOperation) : getLabel(labels.qualificationOperation)
                                }
                            </Title>
                        </Typography>
                    </Col>
                </Row>
            </div>
            <Row justify='center'>
                {
                    isLoading ?
                        <AlertWait message={`${getLabel(labels.loading)}...`} isLoading={isLoading} />
                        :
                        <Col span={24}>
                            {
                                operation === 'registry' && trainings && trainings.map(t =>
                                    t.entId && t.entId.cursoList && t.entId.cursoList.map(c =>
                                        <Collapse
                                            key={`${t.idEntrenamiento}_${c.curId}`}
                                            accordion
                                            bordered={false}
                                            expandIconPosition='end'
                                            style={{
                                                backgroundColor: 'transparent'
                                            }}
                                        >
                                            {
                                                renderCourse(c, t, t.posId, t.idEntrenamiento)
                                            }
                                        </Collapse>
                                    )
                                )
                            }
                            {
                                operation === 'qualification' && trainings && trainings.map(t =>
                                    t.entId && t.entId.cursoList && t.entId.cursoList.map(c =>
                                        <Collapse
                                            key={`${t.idEntrenamiento}_${c.curId}`}
                                            accordion
                                            bordered={false}
                                            expandIconPosition='end'
                                            style={{
                                                backgroundColor: 'transparent'
                                            }}
                                        >
                                            {
                                                renderCourse(c, t, t.posId, t.idEntrenamiento)
                                            }
                                        </Collapse>
                                    )
                                )
                            }
                        </Col>
                }
            </Row>
            <Drawer title={getLabel(labels.beneficiaryTitle)} placement="right" onClose={closeBeneficiaryC1} open={openC1}
                extra={
                    <Space>
                        <Button onClick={closeBeneficiaryC1}
                            disabled={isSaving}
                        >{getLabel(labels.cancel)}</Button>
                    </Space>
                }
            >
                {
                    beneficiaryC1 && <Beneficiary type={1} beneficiary={beneficiaryC1} saveBeneficiary={saveBeneficiaryC1} closeBeneficiary={closeBeneficiaryC1} isSaving={isSaving} />
                }
            </Drawer>
            <Drawer title={getLabel(labels.beneficiaryTitle)} placement="right" onClose={closeBeneficiaryC2} open={openC2}
                extra={
                    <Space>
                        <Button onClick={closeBeneficiaryC2}
                            disabled={isSaving}
                        >{getLabel(labels.cancel)}</Button>
                    </Space>
                }
            >
                {
                    beneficiaryC2 && <Beneficiary type={2} beneficiary={beneficiaryC2} saveBeneficiary={saveBeneficiaryC2} closeBeneficiary={closeBeneficiaryC2} isSaving={isSaving} />
                }
            </Drawer>
            <Modal
                title={getLabel(labels.deleteBeneficiaryTitle)}
                open={openDeleteBeneficiary}
                onOk={confirmDeleteBeneficiary}
                confirmLoading={isDeleting}
                onCancel={cancelDeleteBeneficiary}
                okText={getLabel(labels.yes)}
                cancelText={getLabel(labels.cancel)}
            >
                <p>
                    {
                        getLabel(labels.reConfirmDeleteBeneficiary)
                    }
                </p>
            </Modal>
        </>
    )
}

export default Beneficiaries