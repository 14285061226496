import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Tabs, Button, Row, Col, Image, Form, Input, Typography, Space, Spin, InputNumber, Descriptions, message, Collapse } from 'antd'
import * as apiURLs from '../../utils/urls'
import { getCulture, getLabel, labels } from '../../utils/Internationalization'
import { getInformStatus, getSortInforms, getToken, getTypeContent } from '../../utils/Utils'
import $ from 'jquery'
import { saveAs } from 'file-saver'
import toast from 'react-hot-toast'

import PostulantService from '../../service/PostulantService'
const postulantService = new PostulantService()

const panelStyle = {
    marginBottom: 20,
    backgroundColor: '#fafafa',
    border: 'none',
    borderRadius: '15px'
}

const TrainingApplicant = () => {

    const navigate = useHistory()
    const { id, idTraining } = useParams()
    const [load, setLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [postulant, setPostulante] = useState(null)
    const [courses, setCourses] = useState(null)
    const [attendanceValue, setAttendanceValue] = useState(0)
    const [isDownloading, setIsDownloading] = useState(false)

    const renderInform = ({ infId, infFechaReg, infFechaSAprRec, infNombre, infEstado, infObservacion, infRutaPlan, evidenciaList, infTipo }) =>
        <Row
            key={infId}
            className='mgeCard'
            style={{
                backgroundColor: '#f0f0f0',
                padding: '10px 20px',
                marginBottom: '10px',
            }}>
            <Descriptions
                title={
                    <Row gutter={10} style={{ paddingBottom: '10px' }}>
                        <Col>
                            <Image src={`./../../assets/${infEstado.catValor === 'EST_INF_ENV' ? 'send.png' : infEstado.catValor === 'EST_INF_APR' ? 'accept.png' : infEstado.catValor === 'EST_INF_REC' ? 'cancel.png' : 'minus.png'}`} preview={false} width={24} height={24} />
                        </Col>
                        <Col>
                            <Typography.Text
                                strong
                                style={{
                                    fontWeight: 'normal',
                                }}
                            >
                                {infNombre}
                            </Typography.Text>
                        </Col>
                    </Row>
                }
                size='small'
                layout='vertical'
                extra={
                    <>
                        <Button type='default'
                            onClick={() => downloadInform({ infNombre, infRutaPlan })}
                            loading={isDownloading}
                            disabled={isDownloading}
                        >
                            {getLabel(labels.download)}
                        </Button>
                    </>
                }
                column={
                    {
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 6,
                        xxl: 6,
                    }
                }
            >

                <Descriptions.Item label={
                    <Typography.Text type='secondary' strong>{getLabel(labels.registrationDate)}</Typography.Text>
                }
                >
                    {infFechaReg.substring(0, 10)}
                </Descriptions.Item>

                {
                    evidenciaList && evidenciaList.length > 0 ?
                        <>
                            <Descriptions.Item label={
                                <Typography.Text type='secondary' strong>{getLabel(labels.evidences)}</Typography.Text>
                            }
                            >
                                <a rel="noreferred noreferrer" target='_blank' href={`${evidenciaList[0].eviUrl}`}>
                                    {
                                        evidenciaList[0].eviUrl.length > 15 ?
                                            `${evidenciaList[0].eviUrl.substring(0, 15)}...`
                                            : evidenciaList[0].eviUrl
                                    }
                                </a>
                            </Descriptions.Item>
                        </>
                        :
                        <>
                        </>
                }

                {
                    infFechaSAprRec &&
                    <Descriptions.Item label={
                        <Typography.Text type='secondary' strong>{getLabel(labels.reviewDate)}</Typography.Text>
                    }
                    >
                        {infFechaSAprRec.substring(0, 10)}
                    </Descriptions.Item>
                }

                {
                    infObservacion &&
                    <Descriptions.Item
                        style={{
                            whiteSpace: 'pre-wrap'
                        }}
                        label={
                            <Typography.Text type='secondary' strong>{getLabel(labels.observation)}</Typography.Text>
                        }
                    >
                        {infObservacion}
                    </Descriptions.Item>
                }

            </Descriptions>

        </Row>

    const renderCourse = ({ curId, curNombre, infTipo }) =>
        <Collapse.Panel
            key={curId}
            header={
                <Row gutter={10}>
                    <Col>
                        {
                            postulant.informeList && postulant.informeList.filter(i => i.infTipo.catValor === infTipo.catValor && i.idCurso === curId).length > 0 ?
                                <Image src={`./../../assets/${getInformStatus(postulant.informeList.filter(i => i.infTipo.catValor === infTipo.catValor && i.idCurso === curId))}`} preview={false} width={24} height={24} />
                                :
                                <Image src='./../../assets/minus.png' preview={false} width={24} height={24} />
                        }
                    </Col>
                    <Col>
                        <Typography.Text strong style={{ fontSize: '1.1em' }}>
                            {
                                infTipo.catValor === 'TYP_INF_WPL' ? getLabel(labels.workPlan) : infTipo.catValor === 'TYP_INF_FIR' ? getLabel(labels.finalReport) : ''
                            }
                            &nbsp;- {curNombre.catNombre}
                        </Typography.Text>
                    </Col>
                </Row>
            }
            style={panelStyle}
        >
            {
                postulant.informeList && getSortInforms(postulant.informeList.filter(i => i.infTipo.catValor === infTipo.catValor && i.idCurso === curId)).map(i => renderInform(i))
            }
        </Collapse.Panel>

    const downloadCertificate = async ({ cerUuid, cerNombre }) => {
        const metaData = getTypeContent(cerNombre)

        var xhrOverride = new XMLHttpRequest();
        xhrOverride.responseType = 'arraybuffer';

        setIsDownloading(true)
        $.ajax({
            async: true,
            crossDomain: true,
            url: apiURLs.downloadCertificateByUuid(),
            method: 'POST',
            headers: {
                Accept: "*/*",
                "Accept-Language": getCulture(),
                Authorization: `Bearer ${getToken()}`,
                "Content-Type": "application/json"
            },
            data: JSON.stringify({ cerUuid }),
            xhr: function () {
                return xhrOverride;
            }
        }).then(function (response) {
            setIsDownloading(false)
            const blob = new Blob([response], { type: metaData })
            saveAs(blob, cerNombre)
        }).catch(() => setIsDownloading(false));
    }

    const renderCertificate = ({ cerEstado, cerFechaCad, cerUuid, cerId, cerNombre, idCurso }) =>
        <div
            key={cerId}
            style={
                {
                    backgroundColor: '#ffffff',
                    padding: '15px',
                    borderRadius: '15px',
                    marginBottom: '20px'
                }
            }>
            <Row>
                <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={12}>
                    <Row gutter={10}>
                        <Col>
                            <Image src={`./../../assets/${cerEstado.catValor === 'EST_CER_ACT' ? 'accept.png' : 'cancel.png'}`} preview={false} width={24} height={24} />
                        </Col>
                        <Col>
                            <Typography.Text strong style={{ fontSize: '1.1em' }}>
                                {getLabel(labels.certificate)}&nbsp;-&nbsp;
                                {
                                    courses && courses.filter(c => c.curId === idCurso)[0].curNombre.catNombre
                                }
                            </Typography.Text>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={6}>
                    <Row gutter={10}>
                        <Col>
                            <Typography.Text strong style={{ fontSize: '1.1em' }}>
                                {getLabel(labels.expires)}: {cerFechaCad.substring(0, 10)}
                            </Typography.Text>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={6}>
                    <Row gutter={10} justify='end'>
                        <Col>
                            <Button type='primary'
                                loading={isDownloading}
                                disabled={isDownloading}
                                onClick={() => downloadCertificate({ cerUuid, cerNombre })}
                            >{getLabel(labels.download)}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>

    const downloadInform = ({ infNombre, infRutaPlan }) => {

        const metaData = getTypeContent(infNombre)

        var xhrOverride = new XMLHttpRequest();
        xhrOverride.responseType = 'arraybuffer';

        setIsDownloading(true)
        $.ajax({
            async: true,
            crossDomain: true,
            url: apiURLs.downloadInformByName(),
            method: 'POST',
            headers: {
                Accept: "*/*",
                "Accept-Language": getCulture(),
                Authorization: `Bearer ${getToken()}`,
                "Content-Type": "application/json"
            },
            data: JSON.stringify({
                infNombre,
                infRutaPlan
            }),
            xhr: function () {
                return xhrOverride;
            }
        }).then(function (response) {
            setIsDownloading(false)
            const blob = new Blob([response], { type: metaData })
            saveAs(blob, infNombre)
        }).catch(() => setIsDownloading(false));
    }

    const fetchData = async () => {
        setLoad(false)
        setIsLoading(true)
        const training = { idEntrenamiento: idTraining, posId: id }
        try {
            const { data } = await postulantService.getByTraining({ navigate, training })
            if (data) {
                const courses = data.listObject[0].entId.cursoList
                const postulant = data.listObject[0]
                if (postulant.asistenciaList.length > 0) {
                    const attendanceValue = postulant.asistenciaList[postulant.asistenciaList.length - 1].asiValor || 0
                    setAttendanceValue(attendanceValue)
                }
                setPostulante(postulant)
                setCourses(courses)
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            message.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (load)
            fetchData()
    })

    return (
        <>
            {
                isLoading ?
                    <Row style={{ padding: '30px 10px' }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Spin size='large' tip={
                                <Typography.Paragraph style={{ padding: '10px 0' }}>
                                    {getLabel(labels.loading)}...
                                </Typography.Paragraph>
                            } />
                        </Col>
                    </Row>
                    :
                    postulant && <>
                        <div className="grid">
                            <div className="col-12">
                                <div className="mgeCard">
                                    <div className="grid">
                                        <div className="col-12">
                                            <Space direction='horizontal'>
                                                <Button icon={<i className="fa-solid fa-chevron-left"></i>}
                                                    onClick={() => {
                                                        window.history.go(-1)
                                                    }}
                                                ></Button>
                                                <h3 style={{ padding: '0', margin: '0' }} className='mge-darkGreen'>
                                                    {
                                                        `${postulant.usuId.nombres || 'Postulante'} ${postulant.usuId.apellidos || ''}`
                                                    }
                                                </h3>
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Tabs centered items={[
                            {
                                key: 1,
                                label: <Typography.Text style={{ fontSize: '1.1em', letterSpacing: '1px' }}>{getLabel(labels.information)}</Typography.Text>,
                                children: <>
                                    <Row gutter={[25, 25]} justify='center'>
                                        <Col xs={22} sm={20} md={6} lg={6} xl={6} xxl={4}>
                                            <Row gutter={[15, 15]} style={{ textAlign: 'center' }}>
                                                <Col span={24}>
                                                    {
                                                        postulant.usuId.imagen ?
                                                            <Image src={
                                                                `/images/${postulant.usuId.imagen.split('/')[postulant.usuId.imagen.split('/').length - 1]}`
                                                            } width={128} height={128} />
                                                            :
                                                            <Image src='./../assets/photo_profile.png' width={128} height={128} />
                                                    }
                                                </Col>
                                                <Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'left' }}>
                                                    <Typography.Paragraph>
                                                        <Typography.Text strong>
                                                            {getLabel(labels.profile)}:
                                                        </Typography.Text>
                                                        <br />{getLabel(labels.applicant)}
                                                    </Typography.Paragraph>
                                                </Col>
                                                <Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'left' }}>
                                                    <Typography.Paragraph>
                                                        <Typography.Text strong>
                                                            {getLabel(labels.invitedBy)}:
                                                        </Typography.Text>
                                                        <br />
                                                        {
                                                            postulant.posInvitadoPor ?
                                                                `${postulant.posInvitadoPor.nombres || ''} ${postulant.posInvitadoPor.apellidos || ''}`
                                                                : <></>
                                                        }
                                                    </Typography.Paragraph>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={22} sm={20} md={14} lg={14} xl={14} xxl={10}>
                                            <Form
                                                initialValues={
                                                    { ...postulant.usuId, fechanac: postulant.usuId.fechanac.substring(0, 10) }
                                                }
                                                layout='vertical'
                                            >
                                                <Row gutter={[25, 5]}>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name={'nombres'}
                                                            label={getLabel(labels.firstName)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='apellidos'
                                                            label={getLabel(labels.lastName)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name={['sexo', 'catNombre']}
                                                            label={getLabel(labels.sex)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='fechanac'
                                                            label={getLabel(labels.dateBirth)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name={['pais', 'catNombre']}
                                                            label={getLabel(labels.country)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name={['ciudad']}
                                                            label={getLabel(labels.city)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='codigotel'
                                                            label={getLabel(labels.countryCode)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='celular'
                                                            label={getLabel(labels.phoneNumber)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='documento'
                                                            label={getLabel(labels.idNumber)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='lugartrabajo'
                                                            label={getLabel(labels.currentyWorking)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='email'
                                                            label={getLabel(labels.email)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='email2'
                                                            label={getLabel(labels.additionalEmail)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </>
                            }, {
                                key: 2,
                                label: <Typography.Text style={{ fontSize: '1.1em', letterSpacing: '1px' }}>{getLabel(labels.progress)}</Typography.Text>,
                                children: <>
                                    <div style={
                                        {
                                            backgroundColor: '#ffffff',
                                            padding: '15px',
                                            borderRadius: '15px',
                                            marginBottom: '20px'
                                        }
                                    }>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <Row gutter={10}>
                                                    <Col>
                                                        <img alt='' src={`./../../assets/${attendanceValue >= 80 ? 'accept.png' : 'cancel.png'}`} height={24} width={24} />
                                                    </Col>
                                                    <Col>
                                                        <Typography.Title level={5}>
                                                            {getLabel(labels.attendance)}
                                                        </Typography.Title>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                <Row gutter={10} justify='end'>
                                                    <Col style={{ textAlign: 'end' }}>
                                                        <Space>
                                                            <InputNumber
                                                                defaultValue={0}
                                                                value={attendanceValue}
                                                                readOnly
                                                                min={0}
                                                                max={100}
                                                                formatter={(value) => `${value}%`}
                                                                parser={(value) => value.replace('%', '')}
                                                                onChange={(e) => setAttendanceValue(e)} />
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Col span={24}>
                                            <Collapse
                                                accordion
                                                bordered={false}
                                                expandIconPosition='end'
                                                style={{
                                                    backgroundColor: 'transparent'
                                                }}
                                            >
                                                {
                                                    courses && courses.map(c => renderCourse({ ...c, infTipo: { catValor: 'TYP_INF_WPL' } }))
                                                }
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24}>
                                            <Collapse
                                                accordion
                                                bordered={false}
                                                expandIconPosition='end'
                                                style={{
                                                    backgroundColor: 'transparent'
                                                }}
                                            >
                                                {
                                                    courses && courses.map(c => renderCourse({ ...c, infTipo: { catValor: 'TYP_INF_FIR' } }))
                                                }
                                            </Collapse>
                                        </Col>
                                    </Row>
                                    {
                                        postulant && postulant.informeList && postulant.certificadoList &&
                                        <>
                                            {
                                                postulant.informeList.filter(w => w.infTipo.catValor === 'TYP_INF_FIR' &&
                                                    w.infEstado.catValor === 'EST_INF_APR'
                                                ).length > 0
                                                &&
                                                <>
                                                    {
                                                        postulant.certificadoList && postulant.certificadoList.map(c => renderCertificate(c))
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }]
                        }>
                        </Tabs>
                    </>
            }
        </>
    )
}

export default TrainingApplicant