import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Typography, Button, Result, Space, Table, Row, Col, Divider, Drawer, Form, message, Input, Popconfirm, Checkbox, InputNumber } from 'antd'
import PerfilService from '../../service/PerfilService'
import { getLabel, labels } from '../../utils/Internationalization'

const perfilService = new PerfilService()

const Perfiles = () => {

  const navigate = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [perfiles, setPerfiles] = useState([])
  const [perfilesSeleccionados, setPerfilesSeleccionados] = useState([])
  const [perfil, setPerfil] = useState({})
  const [error, setError] = useState(null)
  const [perfilAbierto, setPerfilAbierto] = useState(false);
  const [formPerfil] = Form.useForm();

  const tableProps = {
    bordered: false,
    loading: false,
    title: '',
    showHeader: true,
    rowSelection: {},
    size: 'medium',
    pagination: {
      position: ['none', 'none']
    }
  };

  const columnas = [
    {
      title: getLabel(labels.name),
      dataIndex: 'unique',
      render: (e) => <Link to={`/perfil/${e.key}/${e.label}`}>{e.label}</Link>
    },
    {
      title: getLabel(labels.level),
      dataIndex: 'perNivel',
      responsive: ['xl']
    },
    {
      title: getLabel(labels.state),
      dataIndex: 'perEstado',
      render: (e) => <> {e ? getLabel(labels.active) : getLabel(labels.inactive)} </>
    }
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setPerfilesSeleccionados(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  };

  const onChangePerfil = (e) => {
    setPerfil({ ...perfil, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value })
  }
  const onChangePerfilNivel = (e) => {
    setPerfil({ ...perfil, perNivel: e })
  }

  useEffect(() => {
    if (isLoading) {
      const fetchData = async () => {
        try {
          const { data } = await perfilService.gets({ navigate })
          if (data) {
            const _perfiles = data.listObject.map(c => ({ ...c, unique: { key: c.perId, label: c.perNombre } }))
            setPerfiles(_perfiles)
            setError(null)
            setIsLoading(false)
          } else {
            setIsLoading(false)
            setError(getLabel(labels.errorOcurred))
          }
        } catch (error) {
          setIsLoading(false)
          setError(getLabel(labels.errorOcurred))
        }
      }
      fetchData()
    }
  }, [isLoading, navigate])

  const fetchPerfiles = () => {
    setIsLoading(true)
  }

  const getNuevoPerfil = () => ({
    perEstado: true,
    perNivel: "",
    perNombre: ""
  })

  const abrirPerfil = (c) => {
    setPerfil({
      ...c
    })
    setPerfilAbierto(true);
  }

  const cerrarPerfil = () => {
    setPerfilAbierto(false);
  };

  const guardarPerfil = async () => {
    setIsSaving(true)
    try {
      const { data } = await perfilService.set({ navigate, perfil })
      setIsSaving(false)
      if (data) {
        setPerfil(data.listObject[0])
        message.success(getLabel(labels.profileSavedSuccessfully))
        setIsLoading(true)
      }
    } catch (error) {
      setIsSaving(false)
      const { response } = error
      setError(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    }
  }

  const eliminarPerfiles = (e) => {
    const requests = []
    perfilesSeleccionados.forEach(c => {
      requests.push(perfilService.del({ navigate, perfil: { ...c } }))
    })
    setIsDeleting(true)
    Promise.all(requests)
      .then((results) => {
        setPerfilesSeleccionados([])
        setIsDeleting(false)
        setIsLoading(true)
      })
      .catch(error => {
        setIsSaving(false)
        const { response } = error
        const msgError = response.status === 409 ? response.data[0].messageText : response.data
        message.error(getLabel(labels.errorOcurred), `${msgError}`)
      })
  }

  const eliminarPerfil = async () => {
    setIsDeleting(true)
    try {
      const { data } = await perfilService.del({ navigate, perfil })
      setIsDeleting(false)
      if (data === true) {
        setIsLoading(true)
        message.success(getLabel(labels.profileDeletedSuccessfully))
        cerrarPerfil()
      } else {
        message.error(getLabel(labels.errorOcurred))
      }
    } catch (error) {
      setIsDeleting(false)
      const { response } = error
      setError(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
    }
  }

  if (error) {
    return (
      <Result
        status="warning"
        title={''}
        subTitle={''}
        extra={
          <>
            <h5>{error}</h5>
            <Link to={'/'}>{getLabel(labels.backHome)}</Link>
          </>
        }
      />
    )
  }

  return (
    <>
      <Row gutter={[10, 10]}>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Row justify='start'>
            <Typography.Title
              level={4}
              style={{
                margin: 0,
              }}
            >
              {getLabel(labels.profilesManagement)}
            </Typography.Title>
          </Row>
        </Col>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <Row justify='end'>
            <Space>
              <Button type='default' onClick={(e) => abrirPerfil(getNuevoPerfil())}>
                {getLabel(labels.new)}
              </Button>
              {
                perfilesSeleccionados && perfilesSeleccionados.length > 0 &&
                <Popconfirm title={getLabel(labels.sureDeleteProfiles)} okText={getLabel(labels.yes)} cancelText={getLabel(labels.no)} onConfirm={() => eliminarPerfiles()}
                  okButtonProps={{
                    loading: isDeleting,
                  }}
                >
                  <Button type="danger"
                    disabled={isLoading || isDeleting}
                    loading={isDeleting}
                  >
                    {isDeleting ? getLabel(labels.deleting) : getLabel(labels.delete)}
                  </Button>
                </Popconfirm>
              }
              <Button type="primary" onClick={fetchPerfiles} loading={isLoading}>{getLabel(labels.refresh)}</Button>
            </Space>
          </Row>
        </Col>
      </Row>
      <Divider></Divider>
      <Table
        {...tableProps}
        rowKey='perId'
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columnas}
        dataSource={perfiles}
        loading={isLoading}
      />

      <Drawer
        title="Profile"
        placement="right"
        closable={true}
        onClose={cerrarPerfil}
        open={perfilAbierto}
      >
        <Form
          layout={'vertical'}
          form={formPerfil}
          initialValues={{
            layout: 'vertical',
          }}
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={getLabel(labels.name)}>
                <Input id="perNombre" name="perNombre" type='text' value={perfil.perNombre} onChange={onChangePerfil} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={getLabel(labels.level)}>
                <InputNumber id="perNivel" name="perNivel" min={1} max={50} value={perfil.perNivel} onChange={onChangePerfilNivel} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={getLabel(labels.state)}>
                <Checkbox id='perEstado' name='State' onChange={onChangePerfil} checked={perfil.perEstado}>
                  {
                    perfil.perEstado ? getLabel(labels.active) : getLabel(labels.inactive)
                  }
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item>
                <Space>
                  <Button type="primary" onClick={(e) => guardarPerfil(e)}
                    disabled={isSaving || isDeleting}
                    loading={isSaving}
                  >
                    {isSaving ? getLabel(labels.saving) : getLabel(labels.save)}
                  </Button>
                  {
                    perfil && perfil.perId &&
                    <Popconfirm title={getLabel(labels.sureDeletProfile)} okText={getLabel(labels.yes)} cancelText={getLabel(labels.no)} onConfirm={() => eliminarPerfil()}
                      okButtonProps={{
                        loading: isDeleting,
                      }}
                    >
                      <Button type="danger"
                        disabled={isLoading || isDeleting || isSaving}
                        loading={isDeleting}
                      >
                        {isDeleting ? getLabel(labels.deleting) : getLabel(labels.delete)}
                      </Button>
                    </Popconfirm>
                  }
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>

    </>
  )
}

export default Perfiles