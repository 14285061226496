import AxiosInstance from '../utils/AxiosInstance'
import { getAttendanceById, insertAttendance, updateAttendance } from '../utils/urls'
export class AttendanceService {
    put = (props) => {
        const { navigate, attendance } = props
        return AxiosInstance(navigate).put(insertAttendance(), attendance)
    }
    get = (props) => {
        const { navigate, attendance } = props
        return AxiosInstance(navigate).post(getAttendanceById(), attendance)
    }
    set = (props) => {
        const { navigate, attendance } = props
        return AxiosInstance(navigate).patch(updateAttendance(), attendance)
    }
}

export default AttendanceService