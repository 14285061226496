import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Typography, Button, Spin, Image, Result, Collapse, Space } from 'antd'
import $ from 'jquery'
import { saveAs } from 'file-saver'
import { getDatosUsuario, getToken, getTypeContent } from '../../utils/Utils'
import * as apiURLs from '../../utils/urls'
import toast from 'react-hot-toast'

import PostulantService from '../../service/PostulantService'
import { getCulture, getLabel, labels } from '../../utils/Internationalization'

const postulantService = new PostulantService()

const panelStyle = {
    marginBottom: 20,
    backgroundColor: '#fafafa',
    border: 'none',
    borderRadius: '15px'
}

const Certificate = () => {

    const navigate = useHistory()
    const [trainings, setTrainings] = useState([])
    const [load, setLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)

    const downloadCertificate = async ({ cerUuid, cerNombre }) => {
        const metaData = getTypeContent(cerNombre)
        var xhrOverride = new XMLHttpRequest();
        xhrOverride.responseType = 'arraybuffer';
        setIsDownloading(true)
        $.ajax({
            async: true,
            crossDomain: true,
            url: apiURLs.downloadCertificateByUuid(),
            method: 'POST',
            headers: {
                Accept: "*/*",
                "Accept-Language": getCulture(),
                Authorization: `Bearer ${getToken()}`,
                "Content-Type": "application/json"
            },
            data: JSON.stringify({ cerUuid }),
            xhr: function () {
                return xhrOverride;
            }
        }).then(function (response) {
            setIsDownloading(false)
            const blob = new Blob([response], { type: metaData })
            saveAs(blob, cerNombre)
        }).catch(() => setIsDownloading(false));
    }

    const renderCertificate = (t, { cerEstado, cerFechaCad, cerId, cerNombre, idCurso, cerUuid }) =>
        <div
            key={`${t.idEntrenamiento}_${cerId}`}
            style={
                {
                    backgroundColor: '#ffffff',
                    padding: '15px',
                    borderRadius: '15px',
                    marginBottom: '20px'
                }
            }>
            <Row>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row gutter={10}>
                        <Col>
                            <Image src={`./../../assets/${cerEstado.catValor === 'EST_CER_ACT' ? 'accept.png' : 'cancel.png'}`} preview={false} width={24} height={24} />
                        </Col>
                        <Col>
                            <Typography.Text strong style={{ fontSize: '1.1em' }}>
                                <Space direction='horizontal'>
                                    <span>
                                        {getLabel(labels.certificate)}
                                    </span>
                                    <span>
                                        {getLabel(labels.expires)}: {cerFechaCad.substring(0, 10)}
                                    </span>
                                </Space>
                            </Typography.Text>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                    <Row gutter={10} justify='end'>
                        <Col>
                            <Button type='primary'
                                loading={isDownloading}
                                disabled={isDownloading}
                                onClick={() => downloadCertificate({ cerUuid, cerNombre })}
                            >{getLabel(labels.download)}</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>

    const renderTraining = (t) => <Collapse
        key={t.idEntrenamiento}
        accordion
        bordered={false}
        expandIconPosition='end'
        style={{
            backgroundColor: 'transparent'
        }}
    >
        {
            t.entId.cursoList && t.entId.cursoList.map(c =>
                <Collapse.Panel
                    key={`${t.idEntrenamiento}_${c.curId}`}
                    header={
                        <Row gutter={10}>
                            <Col>
                                {
                                    t.certificadoList && t.certificadoList.filter(ce => ce.cerEstado.catValor === 'EST_CER_ACT' && ce.idCurso === c.curId).length > 0 ?
                                        <Image src='./../../assets/accept.png' preview={false} width={24} height={24} />
                                        :
                                        <Image src='./../../assets/minus.png' preview={false} width={24} height={24} />
                                }
                            </Col>
                            <Col>
                                <Typography.Text strong style={{ fontSize: '1.1em' }}>
                                    {c.curNombre.catNombre}
                                </Typography.Text>
                            </Col>
                        </Row>
                    }
                    style={panelStyle}
                >
                    {
                        t && t.informeList && t.certificadoList &&

                            t.informeList.filter(w => w.infTipo.catValor === 'TYP_INF_FIR' && w.infEstado.catValor === 'EST_INF_APR').length > 0
                            &&
                            t.certificadoList.length > 0
                            ?
                            <>
                                {
                                    t.certificadoList && t.certificadoList.map(c => renderCertificate(t, c))
                                }
                            </>
                            :
                            <Result
                                key={t.idEntrenamiento}
                                title={getLabel(labels.seeYourCertificateFinalReportBeneficiary)}
                                extra={
                                    <Button
                                        type="primary"
                                        onClick={
                                            () => {
                                                t.informeList.filter(w => w.infTipo.catValor === 'TYP_INF_FIR' && w.infEstado.catValor === 'EST_INF_APR').length < 1 ?
                                                    navigate.push('/finalreport') : navigate.push('/beneficiaries/qualification')
                                            }
                                        }
                                    >
                                        {
                                            t.informeList.filter(w => w.infTipo.catValor === 'TYP_INF_FIR' && w.infEstado.catValor === 'EST_INF_APR').length < 1 ?
                                                getLabel(labels.sendFinalReport) : getLabel(labels.sendListBeneficiaries)
                                        }
                                    </Button>
                                }
                            />
                    }

                </Collapse.Panel>
            )
        }
    </Collapse>

    const fetchData = async () => {
        setLoad(false)
        setIsLoading(true)
        try {
            const postulant = { idUsuario: getDatosUsuario().usuarioId }
            const { data } = await postulantService.getTraining({ navigate, postulant })
            setTrainings(data.listObject)
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (load) fetchData()
    })

    return (
        <>
            <div style={
                {
                    backgroundColor: '#ffffff',
                    padding: '15px 20px 6px 20px',
                    borderRadius: '15px',
                    marginBottom: '20px'
                }
            }>
                <Row>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Typography>
                            <Typography.Title type='secondary' level={3}>
                                {
                                    getLabel(labels.certificates)
                                }
                            </Typography.Title>
                        </Typography>
                    </Col>
                </Row>
            </div>
            {
                isLoading ?
                    <Row style={{ padding: '30px 10px' }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Spin size='large' tip={
                                <Typography.Paragraph style={{ padding: '10px 0' }}>
                                    {getLabel(labels.loading)}...
                                </Typography.Paragraph>
                            } />
                        </Col>
                    </Row>
                    :
                    trainings && trainings.map(t => renderTraining(t))
            }
        </>
    )
}

export default Certificate