import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Col, Row, Table, Space, Typography, Card, Form, Input, Spin, Image } from 'antd'

import { getLabel, labels } from '../../utils/Internationalization'

import TrainingService from '../../service/TrainingService'
import toast from 'react-hot-toast'
import { getInformStatus } from '../../utils/Utils'
const trainingService = new TrainingService()

const tableProps = {
    bordered: false,
    loading: false,
    title: '',
    showHeader: true,
    size: 'medium',
    pagination: {
        position: ['none', 'bottomRight']
    }
};

const Applicants = () => {
    const navigate = useHistory()
    const { id } = useParams()
    const [course, setCourse] = useState(null)
    const [applicants, setApplicants] = useState([])
    const [load, setLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)

    const fetchData = async () => {
        setLoad(false)
        setIsLoading(true)
        const training = { entId: id }
        try {
            const { data } = await trainingService.getById({ navigate, training })
            if (data) {
                setCourse(data.listObject[0])
                setApplicants(data.listObject[0].postulanteList.filter(a => a.posEstado.catValor === 'EST_ENR'))
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoading(false)
        }
    }

    const search = ({ criteria }) => {
        const searchCriteria = criteria.toUpperCase()
        const postulanteList = criteria ? applicants.filter(p =>
            p.posEmail.toUpperCase().indexOf(searchCriteria) >= 0
            || p.usuId.documento.toUpperCase().indexOf(searchCriteria) >= 0
            || p.usuId.nombres.toUpperCase().indexOf(searchCriteria) >= 0
            || p.usuId.apellidos.toUpperCase().indexOf(searchCriteria) >= 0
            || p.usuId.pais.catNombre.toUpperCase().indexOf(searchCriteria) >= 0
            || p.usuId.ciudad.toUpperCase().indexOf(searchCriteria) >= 0
            || p.usuId.sexo.catNombre.toUpperCase().indexOf(searchCriteria) >= 0
        ) : applicants
        const filteredCourse = { ...course, postulanteList }
        setCourse(filteredCourse)
    }

    useEffect(() => {
        if (load) {
            fetchData()
        }
    })

    return (
        <>
            {
                isLoading ?
                    <Row style={{ padding: '30px 10px' }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Spin size='large' tip={
                                <Typography.Paragraph style={{ padding: '10px 0' }}>
                                    {getLabel(labels.loading)}
                                </Typography.Paragraph>
                            } />
                        </Col>
                    </Row>
                    :
                    course && <>
                        <Row>
                            <Col span={24}>
                                <Card bordered={false}>
                                    <Row>
                                        <Col>
                                            <Space direction='horizontal'>
                                                <Button icon={<i className="fa-solid fa-chevron-left"></i>}
                                                    onClick={() => {
                                                        navigate.push('/training')
                                                    }}
                                                ></Button>
                                                <h3 style={{ padding: '0', margin: '0' }} className='mge-darkGreen'>
                                                    {course.entNombre}
                                                </h3>
                                            </Space>
                                        </Col>
                                    </Row>
                                    <Row
                                        gutter={[15, 0]}
                                        justify='space-around'
                                    >
                                        <Col style={{ padding: '10px 10px 0 10px' }}>
                                            <i className="fa-solid fa-laptop"></i>&nbsp;{course.entModalidad.catNombre}
                                        </Col>
                                        <Col style={{ padding: '10px 10px 0 10px' }}>
                                            <i className="fa-solid fa-calendar-days"></i>&nbsp;{course.entFechaIni.substring(0, 10)}-{course.entFechaFin.substring(0, 10)}
                                        </Col>
                                        <Col style={{ padding: '10px 10px 0 10px' }}>
                                            <i className="fa-solid fa-graduation-cap"></i>&nbsp;{course.numPostulantes}&nbsp;{getLabel(labels.applicants)}
                                        </Col>
                                        <Col style={{ padding: '5px 10px 0 10px' }}>
                                            <i className="fa-solid fa-earth-americas"></i>{` ${course.entRegion.catDescripcion}`}
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ padding: '15px 0' }}>
                                <Form
                                    initialValues={{
                                        criteria: ''
                                    }}
                                    onFinish={search}
                                    layout='inline'
                                >
                                    <Form.Item
                                        name={['criteria']}
                                        label={getLabel(labels.searchExpression)}
                                    >
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type='default' htmlType='submit'>{getLabel(labels.search)}</Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                        {
                            course.postulanteList && <>
                                <Table
                                    {...tableProps}
                                    rowKey='posId'
                                    columns={[
                                        {
                                            title: '',
                                            dataIndex: 'posId',
                                            render: (_, { usuId }) => usuId && usuId.imagen ?
                                                <Image src={
                                                    `/images/${usuId.imagen.split('/')[usuId.imagen.split('/').length - 1]}`
                                                } width={32} height={32} />
                                                :
                                                <img alt='' src='./assets/user.png' width={32} height={32} />
                                        },
                                        {
                                            title: getLabel(labels.name),
                                            dataIndex: 'usuId',
                                            defaultSortOrder: 'ascend',
                                            sorter: (a, b) => {
                                                if (a.nombresApellidos > b.nombresApellidos) {
                                                    return 1
                                                }
                                                if (a.nombresApellidos < b.nombresApellidos) {
                                                    return -1
                                                }
                                                return 0
                                            },
                                            render: (_, { posEmail, usuId }) => usuId ? `${usuId.nombres} ${usuId.apellidos}` : posEmail
                                        },
                                        {
                                            title: getLabel(labels.country),
                                            dataIndex: 'country',
                                            render: (_, { usuId }) => usuId ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <img
                                                        src={`https://flagcdn.com/32x24/${usuId.pais.catDescripcion.toLowerCase()}.png`}
                                                        srcSet={`https://flagcdn.com/32x24/${usuId.pais.catDescripcion.toLowerCase()}.png 2x,https://flagcdn.com/96x72/${usuId.pais.catDescripcion.toLowerCase()}.png 3x`}
                                                        width="32"
                                                        height="24"
                                                        alt={usuId.pais.catNombre}></img>
                                                </div>
                                                : <></>,
                                            responsive: ['md', 'lg', 'xl', 'xxl']
                                        },
                                        {
                                            title: getLabel(labels.attendance),
                                            dataIndex: 'attendance',
                                            render: (_, { asistenciaList }) => asistenciaList && asistenciaList.length > 0 ?
                                                <div style={{ textAlign: 'center' }}>
                                                    <img alt='' src={`./../../assets/${(asistenciaList[asistenciaList.length - 1].asiValor || 0) >= 80 ? 'accept.png' : 'cancel.png'}`} height={24} width={24} />
                                                </div> :
                                                <div style={{ textAlign: 'center' }}>
                                                    <Image src='./../../assets/minus.png' preview={false} width={24} height={24} />
                                                </div>,
                                            responsive: ['xl', 'xxl']
                                        },
                                        {
                                            title: getLabel(labels.workPlan),
                                            dataIndex: 'workPlan',
                                            render: (_, { informeList }) =>
                                                <Row style={{ textAlign: 'center' }} gutter={[0, 5]}>
                                                    {
                                                        course && course.cursoList.map(c => (
                                                            <Col key={c.curId} span={24}>
                                                                {
                                                                    `${c.curNombre.catValor.substr(0, 1)}${c.curNombre.catValor.substr(c.curNombre.catValor.length - 1, 1)} `
                                                                }
                                                                {
                                                                    informeList && informeList.filter(i => i.idCurso === c.curId && i.infTipo.catValor === 'TYP_INF_WPL').length > 0 ?
                                                                        <Image src={`./../../assets/${getInformStatus(informeList.filter(i => i.idCurso === c.curId && i.infTipo.catValor === 'TYP_INF_WPL'))}`} preview={false} width={24} height={24} />
                                                                        :
                                                                        <Image src='./../../assets/minus.png' preview={false} width={24} height={24} />
                                                                }
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>,
                                            responsive: ['xl', 'xxl']
                                        },
                                        {
                                            title: getLabel(labels.finalReport),
                                            dataIndex: 'finalReport',
                                            render: (_, { informeList }) =>
                                                <Row style={{ textAlign: 'center' }} gutter={[0, 5]}>
                                                    {
                                                        course && course.cursoList.map(c => (
                                                            <Col key={c.curId} span={24}>
                                                                {
                                                                    `${c.curNombre.catValor.substr(0, 1)}${c.curNombre.catValor.substr(c.curNombre.catValor.length - 1, 1)} `
                                                                }
                                                                {
                                                                    informeList && informeList.filter(i => i.idCurso === c.curId && i.infTipo.catValor === 'TYP_INF_FIR').length > 0 ?
                                                                        <Image src={`./../../assets/${getInformStatus(informeList.filter(i => i.idCurso === c.curId && i.infTipo.catValor === 'TYP_INF_FIR'))}`} preview={false} width={24} height={24} />
                                                                        :
                                                                        <Image src='./../../assets/minus.png' preview={false} width={24} height={24} />
                                                                }
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>,
                                            responsive: ['lg', 'xl', 'xxl']
                                        },
                                        {
                                            title: getLabel(labels.certificate),
                                            dataIndex: 'certificate',
                                            render: (_, { certificadoList }) =>
                                                <Row style={{ textAlign: 'center' }} gutter={[0, 5]}>
                                                    {
                                                        course && course.cursoList.map(tc => (
                                                            <Col key={tc.curId} span={24}>
                                                                {
                                                                    `${tc.curNombre.catValor.substr(0, 1)}${tc.curNombre.catValor.substr(tc.curNombre.catValor.length - 1, 1)} `
                                                                }
                                                                {
                                                                    certificadoList && certificadoList.filter(c => c.idCurso === tc.curId && c.cerEstado.catValor === 'EST_CER_ACT').length > 0 ?
                                                                        <Image src={`./../../assets/${certificadoList.filter(c => c.idCurso === tc.curId && c.cerEstado.catValor === 'EST_CER_ACT')[certificadoList.filter(c => c.idCurso === tc.curId && c.cerEstado.catValor === 'EST_CER_ACT').length - 1].cerEstado.catValor === 'EST_CER_ACT' ? 'accept.png' : 'cancel.png'}`} preview={false} width={24} height={24} />
                                                                        :
                                                                        <Image src='./../../assets/minus.png' preview={false} width={24} height={24} />
                                                                }
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>,
                                            responsive: ['lg', 'xl', 'xxl']
                                        },
                                        {
                                            title: getLabel(labels.actions),
                                            dataIndex: '',
                                            render: (_, record) => <div style={{ textAlign: 'center' }}>
                                                <Button type='primary' href={`/#/applicants/${record.posId}/${id}`}>{getLabel(labels.view)}</Button>
                                            </div>
                                        }
                                    ]}
                                    dataSource={course.postulanteList.filter(p => p.posEstado.catValor === 'EST_ENR').map(p => ({ ...p, nombresApellidos: `${p.usuId.nombres} ${p.usuId.apellidos}` }))}>
                                </Table>
                            </>
                        }
                    </>
            }
        </>
    )
}

export default Applicants