export const getCulture = () => {
    const domainCulture = window.location.hostname.indexOf('mygreenenterprise') > 0 ? 'en_US' : 'es_EC'
    return (localStorage.getItem('mgeCulture') && JSON.parse(localStorage.getItem('mgeCulture')).culture) || domainCulture
}
export const setCulture = (c) => localStorage.setItem('mgeCulture', JSON.stringify(c))
export const labels = {
    welcomeBack: 'welcomeBack',
    loginAccess: 'loginAccess',
    emailLogin: 'emailLogin',
    passwordLogin: 'passwordLogin',
    login: 'login',
    enterEmail: 'enterEmail',
    enterPassword: 'enterPassword',
    emailNotValid: 'emailNotValid',
    forgotPassword: 'forgotPassword',
    recoverPassword: 'recoverPassword',
    email: 'email',
    completeEmail: 'completeEmail',
    resourceNotAvailable: 'resourceNotAvailable',
    resetPassword: 'resetPassword',
    view: 'view',
    edit: 'edit',
    errorOcurred: 'errorOcurred',
    trainings: 'trainings',
    training: 'training',
    refresh: 'refresh',
    newTraining: 'newTraining',
    onlyInactive: 'onlyInactive',
    loading: 'loading',
    courses: 'courses',
    applicants: 'applicants',
    searchExpression: 'searchExpression',
    search: 'search',
    name: 'name',
    country: 'country',
    attendance: 'attendance',
    workPlan: 'workPlan',
    finalReport: 'finalReport',
    certificate: 'certificate',
    certificates: 'certificates',
    actions: 'actions',
    registrationDate: 'registrationDate',
    evidences: 'evidences',
    download: 'download',
    reviewDate: 'reviewDate',
    observation: 'observation',
    comments: 'comments',
    sureApproveThe: 'sureApproveThe',
    sureRejectThe: 'sureRejectThe',
    yesApprove: 'yesApprove',
    yesReject: 'yesReject',
    approve: 'approve',
    reject: 'reject',
    expires: 'expires',
    attendanceSaved: 'attendanceSaved',
    emailSent: 'emailSent',
    emailSendFailed: 'emailSendFailed',
    information: 'information',
    rol: 'rol',
    applicant: 'applicant',
    invitedBy: 'invitedBy',
    firstName: 'firstName',
    lastName: 'lastName',
    sex: 'sex',
    dateBirth: 'dateBirth',
    city: 'city',
    countryCode: 'countryCode',
    phoneNumber: 'phoneNumber',
    idNumber: 'idNumber',
    currentyWorking: 'currentyWorking',
    additionalEmail: 'additionalEmail',
    progress: 'progress',
    sendInvitationEmail: 'sendInvitationEmail',
    thenEmail: 'thenEmail',
    alreadyExists: 'alreadyExists',
    successfullyCreated: 'successfullyCreated',
    successfullyAdded: 'successfullyAdded',
    successfullyRemoved: 'successfullyRemoved',
    successfullySaved: 'successfullySaved',
    successfullyDeleted: 'successfullyDeleted',
    successfullyUpdated: 'successfullyUpdated',
    couldNotSendEmail: 'couldNotSendEmail',
    emailSentSuccessfully: 'emailSentSuccessfully',
    addCountryCourseApplicant: 'addCountryCourseApplicant',
    publishSuccesfully: 'publishSuccesfully',
    saveDraft: 'saveDraft',
    surePublishTraining: 'surePublishTraining',
    yesPublish: 'yesPublish',
    publish: 'publish',
    idTraining: 'idTraining',
    completeID: 'completeID',
    region: 'region',
    selectRegion: 'selectRegion',
    language: 'language',
    selectLanguage: 'selectLanguage',
    selectCountry: 'selectCountry',
    trainingName: 'trainingName',
    completeTrainingName: 'completeTrainingName',
    modality: 'modality',
    selectModality: 'selectModality',
    startDate: 'startDate',
    selectStartDate: 'selectStartDate',
    endDate: 'endDate',
    selectEndDate: 'selectEndDate',
    selectCourses: 'selectCourses',
    applicantsEmails: 'applicantsEmails',
    pressEnterAddEmail: 'pressEnterAddEmail',
    addApplicants: 'addApplicants',
    notPossibleAddApplicant: 'notPossibleAddApplicant',
    myAccount: 'myAccount',
    signOut: 'signOut',
    accountUpdatedSuccessfully: 'accountUpdatedSuccessfully',
    uploadFailed: 'uploadFailed',
    uploadProfilePicture: 'uploadProfilePicture',
    selectPhoto: 'selectPhoto',
    completeFirstName: 'completeFirstName',
    completeLastName: 'completeLastName',
    selectSex: 'selectSex',
    selectDateBirth: 'selectDateBirth',
    uniqueIdentificationCountry: 'uniqueIdentificationCountry',
    completeCity: 'completeCity',
    selectCountryCode: 'selectCountryCode',
    completePhoneNumber: 'completePhoneNumber',
    completeNameInstitution: 'completeNameInstitution',
    ifYesCompleteNameInstitution: 'ifYesCompleteNameInstitution',
    completeAdditionalEmail: 'completeAdditionalEmail',
    additionalEmailNotValid: 'additionalEmailNotValid',
    state: 'state',
    selectState: 'selectState',
    pleaseWait: 'pleaseWait',
    passwordResetSuccessfully: 'passwordResetSuccessfully',
    completePassword: 'completePassword',
    password: 'password',
    newPassword: 'newPassword',
    confirmNewPassword: 'confirmNewPassword',
    completeNewPassword: 'completeNewPassword',
    resetting: 'resetting',
    passwordHasMore8Characters: 'passwordHasMore8Characters',
    passwordHasSpecialCharacters: 'passwordHasSpecialCharacters',
    passwordHasNumber: 'passwordHasNumber',
    passwordHasCapitalLetter: 'passwordHasCapitalLetter',
    passwordsMatch: 'passwordsMatch',
    uploadYourWorkplan: 'uploadYourWorkplan',
    file: 'file',
    selectFile: 'selectFile',
    uploading: 'uploading',
    sending: 'sending',
    send: 'send',
    uploadSuccessfully: 'uploadSuccessfully',
    workplanTemplate: 'workplanTemplate',
    languagePreferences: 'languagePreferences',
    seePreferredLanguage: 'seePreferredLanguage',
    back: 'back',
    languagePreferencesSaved: 'languagePreferencesSaved',
    ok: 'ok',
    reset: 'reset',
    save: 'save',
    accept: 'accept',
    yes: 'yes',
    no: 'no',
    cancel: 'cancel',
    minimumBeneficiaries: 'minimumBeneficiaries',
    beneficiaryTitle: 'beneficiaryTitle',
    beneficiariesTitle: 'beneficiariesTitle',
    deleteBeneficiaryTitle: 'deleteBeneficiaryTitle',
    reConfirmDeleteBeneficiary: 'reConfirmDeleteBeneficiary',
    sendBeneficiaryListTitle: 'sendBeneficiaryListTitle',
    sendBeneficiaryListConfirm: 'sendBeneficiaryListConfirm',
    beneficiarySuccessfullyRemoved: 'beneficiarySuccessfullyRemoved',
    beneficiarySuccessfullyUpdated: 'beneficiarySuccessfullyUpdated',
    certificateSentSuccessfully: 'certificateSentSuccessfully',
    certificateSendFailed: 'certificateSendFailed',
    certificateGeneratedSuccessfully: 'certificateGeneratedSuccessfully',
    newBeneficiary: 'newBeneficiary',
    jobTitle: 'jobTitle',
    isWorking: 'isWorking',
    company: 'company',
    instructionLevel: 'instructionLevel',
    totalPersons: 'totalPersons',
    sureDeleteBeneficiary: 'sureDeleteBeneficiary',
    registryOperation: 'registryOperation',
    qualificationOperation: 'qualificationOperation',
    oneApprovedWorkplanRegisterBeneficiaries: 'oneApprovedWorkplanRegisterBeneficiaries',
    sendWorkplan: 'sendWorkplan',
    oneApprovedFinalReportQualifyBeneficiaries: 'oneApprovedFinalReportQualifyBeneficiaries',
    sendFinalReport: 'sendFinalReport',
    completeInstructionLevel: 'completeInstructionLevel',
    saving: 'saving',
    companyID: 'companyID',
    completeCompanyID: 'completeCompanyID',
    completeCompany: 'completeCompany',
    completeJobTitle: 'completeJobTitle',
    totalCollaborators: 'totalCollaborators',
    completeTotalCollaborators: 'completeTotalCollaborators',
    men: 'men',
    women: 'women',
    numberMen: 'numberMen',
    numberWomen: 'numberWomen',
    uploadFinalReport: 'uploadFinalReport',
    copyEvidenceLink: 'copyEvidenceLink',
    url: 'url',
    evidenceSendedSuccessfully: 'evidenceSendedSuccessfully',
    finalReportTemplate: 'finalReportTemplate',
    thereMustLeast: 'thereMustLeast',
    registeredBeneficiariesUploadFinalReport: 'registeredBeneficiariesUploadFinalReport',
    registerBeneficiaries: 'registerBeneficiaries',
    seeYourCertificateFinalReportBeneficiary: 'seeYourCertificateFinalReportBeneficiary',
    sendListBeneficiaries: 'sendListBeneficiaries',
    users: 'users',
    trainerOfTrainers: 'trainerOfTrainers',
    trainer: 'trainer',
    sessionExpired: 'sessionExpired',
    trainingsNotFound: 'trainingsNotFound',
    backHome: 'backHome',
    notEnrolled: 'notEnrolled',
    usersManagement: 'usersManagement',
    new: 'new',
    userUpdatedSuccessfully: 'userUpdatedSuccessfully',
    user: 'user',
    userCreatedSuccessfully: 'userCreatedSuccessfully',
    createYourPassword: 'createYourPassword',
    confirmYourPassword: 'confirmYourPassword',
    profile: 'profile',
    selectProfile: 'selectProfile',
    profilesManagement: 'profilesManagement',
    level: 'level',
    active: 'active',
    inactive: 'inactive',
    profileSavedSuccessfully: 'profileSavedSuccessfully',
    profileDeletedSuccessfully: 'profileDeletedSuccessfully',
    sureDeleteProfiles: 'sureDeleteProfiles',
    delete: 'delete',
    deleting: 'deleting',
    sureDeleteProfile: 'sureDeleteProfile',
    option: 'option',
    options: 'options',
    of: 'of',
    resultsFound: 'resultsFound',
    filtered: 'filtered',
    sureDownloadUsersReport: 'sureDownloadUsersReport',
    sureDownloadBeneficiariesReport: 'sureDownloadBeneficiariesReport',
    passwordSentIndicatedEmail: 'passwordSentIndicatedEmail',
    passwordSuccessfullyChanged: 'passwordSuccessfullyChanged',
    resettingPassword: 'resettingPassword',
    pageNotExist: 'pageNotExist',
    pageVisitedNotExist: 'pageVisitedNotExist',
    sendNotification: 'sendNotification',
    accountCreatedSuccessfully: 'accountCreatedSuccessfully',
    welcomeMyGreenEnterprise: 'welcomeMyGreenEnterprise',
    selectProfilePicture: 'selectProfilePicture',
    completeInformationActiveAccount: 'completeInformationActiveAccount',
    register: 'register',
    accountPreviouslyCreated: 'accountPreviouslyCreated',
    uploadSignature: 'uploadSignature',
    signature: 'signature',
    signatureUploaded: 'signatureUploaded',
    signatureAlreadyUploaded: 'signatureAlreadyUploaded',
    loginAs: 'loginAs',
    theSum: 'theSum',
    mustEqual: 'mustEqual',
    signatureMustBePNG: 'signatureMustBePNG',
}
const contents = [
    { id: 'welcomeBack', labels: [{ culture: 'en_US', label: 'Welcome back' }, { culture: 'es_EC', label: 'Bienvenido' }] },
    { id: 'loginAccess', labels: [{ culture: 'en_US', label: 'Log in to access your account' }, { culture: 'es_EC', label: 'Ingresa para acceder a tu cuenta' }] },
    { id: 'emailLogin', labels: [{ culture: 'en_US', label: 'Log in with your email' }, { culture: 'es_EC', label: 'Correo' }] },
    { id: 'passwordLogin', labels: [{ culture: 'en_US', label: 'Enter your password' }, { culture: 'es_EC', label: 'Contraseña' }] },
    { id: 'login', labels: [{ culture: 'en_US', label: 'Log in' }, { culture: 'es_EC', label: 'Ingresar' }] },
    { id: 'enterEmail', labels: [{ culture: 'en_US', label: 'Enter you email' }, { culture: 'es_EC', label: 'Ingresa el correo' }] },
    { id: 'enterPassword', labels: [{ culture: 'en_US', label: 'Enter your password' }, { culture: 'es_EC', label: 'Ingresa la contraseña' }] },
    { id: 'emailNotValid', labels: [{ culture: 'en_US', label: 'Email is not valid' }, { culture: 'es_EC', label: 'Correo no válido' }] },
    { id: 'forgotPassword', labels: [{ culture: 'en_US', label: 'Forgot your password?' }, { culture: 'es_EC', label: '¿Olvidaste tu contraseña?' }] },
    { id: 'recoverPassword', labels: [{ culture: 'en_US', label: 'Recover password' }, { culture: 'es_EC', label: 'Recuperar Contraseña' }] },
    { id: 'email', labels: [{ culture: 'en_US', label: 'Email' }, { culture: 'es_EC', label: 'Correo' }] },
    { id: 'completeEmail', labels: [{ culture: 'en_US', label: 'Complete your email' }, { culture: 'es_EC', label: 'Completa el correo' }] },
    { id: 'resourceNotAvailable', labels: [{ culture: 'en_US', label: 'The requested resource is not available or cannot be found.' }, { culture: 'es_EC', label: 'El recurso solicitado no está disponible o no existe.' }] },
    { id: 'resetPassword', labels: [{ culture: 'en_US', label: 'Reset password' }, { culture: 'es_EC', label: 'Restablecer contraseña' }] },
    { id: 'view', labels: [{ culture: 'en_US', label: 'View' }, { culture: 'es_EC', label: 'Ver' }] },
    { id: 'edit', labels: [{ culture: 'en_US', label: 'Edit' }, { culture: 'es_EC', label: 'Editar' }] },
    { id: 'errorOcurred', labels: [{ culture: 'en_US', label: 'An error ocurred' }, { culture: 'es_EC', label: 'Ocurrió un error' }] },
    { id: 'trainings', labels: [{ culture: 'en_US', label: 'Trainings' }, { culture: 'es_EC', label: 'Formaciones' }] },
    { id: 'training', labels: [{ culture: 'en_US', label: 'Training' }, { culture: 'es_EC', label: 'Formación' }] },
    { id: 'refresh', labels: [{ culture: 'en_US', label: 'Refresh' }, { culture: 'es_EC', label: 'Actualizar' }] },
    { id: 'newTraining', labels: [{ culture: 'en_US', label: 'New Training' }, { culture: 'es_EC', label: 'Nueva Formación' }] },
    { id: 'onlyInactive', labels: [{ culture: 'en_US', label: 'Inactive only' }, { culture: 'es_EC', label: 'Solo inactivos' }] },
    { id: 'loading', labels: [{ culture: 'en_US', label: 'Loading' }, { culture: 'es_EC', label: 'Cargando' }] },
    { id: 'courses', labels: [{ culture: 'en_US', label: 'Courses' }, { culture: 'es_EC', label: 'Cursos' }] },
    { id: 'applicants', labels: [{ culture: 'en_US', label: 'Applicants' }, { culture: 'es_EC', label: 'Postulantes' }] },
    { id: 'searchExpression', labels: [{ culture: 'en_US', label: 'Search expression' }, { culture: 'es_EC', label: 'Criterio de Búsqueda' }] },
    { id: 'search', labels: [{ culture: 'en_US', label: 'Search' }, { culture: 'es_EC', label: 'Buscar' }] },
    { id: 'name', labels: [{ culture: 'en_US', label: 'Name' }, { culture: 'es_EC', label: 'Nombre' }] },
    { id: 'country', labels: [{ culture: 'en_US', label: 'Country' }, { culture: 'es_EC', label: 'País' }] },
    { id: 'attendance', labels: [{ culture: 'en_US', label: 'Attendance' }, { culture: 'es_EC', label: 'Asistencia' }] },
    { id: 'workPlan', labels: [{ culture: 'en_US', label: 'Work Plan' }, { culture: 'es_EC', label: 'Plan de Trabajo' }] },
    { id: 'finalReport', labels: [{ culture: 'en_US', label: 'Final Report' }, { culture: 'es_EC', label: 'Informe Final' }] },
    { id: 'certificate', labels: [{ culture: 'en_US', label: 'Certificate' }, { culture: 'es_EC', label: 'Certificado' }] },
    { id: 'certificates', labels: [{ culture: 'en_US', label: 'Certificates' }, { culture: 'es_EC', label: 'Certificados' }] },
    { id: 'actions', labels: [{ culture: 'en_US', label: 'Actions' }, { culture: 'es_EC', label: 'Acciones' }] },
    { id: 'registrationDate', labels: [{ culture: 'en_US', label: 'Registration Date' }, { culture: 'es_EC', label: 'Fecha de Registro' }] },
    { id: 'evidences', labels: [{ culture: 'en_US', label: 'Evidences' }, { culture: 'es_EC', label: 'Evidencias' }] },
    { id: 'download', labels: [{ culture: 'en_US', label: 'Download' }, { culture: 'es_EC', label: 'Descargar' }] },
    { id: 'reviewDate', labels: [{ culture: 'en_US', label: 'Review Date' }, { culture: 'es_EC', label: 'Fecha de Revisión' }] },
    { id: 'observation', labels: [{ culture: 'en_US', label: 'Observation' }, { culture: 'es_EC', label: 'Observación' }] },
    { id: 'comments', labels: [{ culture: 'en_US', label: 'Comments' }, { culture: 'es_EC', label: 'Comentarios' }] },
    { id: 'sureApproveThe', labels: [{ culture: 'en_US', label: 'Are you sure to approve the' }, { culture: 'es_EC', label: '¿Seguro(a) que deseas aprobar el' }] },
    { id: 'sureRejectThe', labels: [{ culture: 'en_US', label: 'Are you sure to reject the' }, { culture: 'es_EC', label: '¿Seguro(a) que deseas rechazar el' }] },
    { id: 'yesApprove', labels: [{ culture: 'en_US', label: 'Yes, approve' }, { culture: 'es_EC', label: 'Sí, aprobar' }] },
    { id: 'yesReject', labels: [{ culture: 'en_US', label: 'Yes, reject' }, { culture: 'es_EC', label: 'Sí, rechazar' }] },
    { id: 'approve', labels: [{ culture: 'en_US', label: 'Approve' }, { culture: 'es_EC', label: 'Aprobar' }] },
    { id: 'reject', labels: [{ culture: 'en_US', label: 'Reject' }, { culture: 'es_EC', label: 'Rechazar' }] },
    { id: 'expires', labels: [{ culture: 'en_US', label: 'Expires' }, { culture: 'es_EC', label: 'Vence' }] },
    { id: 'attendanceSaved', labels: [{ culture: 'en_US', label: 'Attendance Successfully Saved' }, { culture: 'es_EC', label: 'Asistencia guardada exitosamente' }] },
    { id: 'emailSent', labels: [{ culture: 'en_US', label: 'Email Sent Successfully' }, { culture: 'es_EC', label: 'Email enviado existosamente' }] },
    { id: 'information', labels: [{ culture: 'en_US', label: 'Information' }, { culture: 'es_EC', label: 'Información' }] },
    { id: 'rol', labels: [{ culture: 'en_US', label: 'Rol' }, { culture: 'es_EC', label: 'Rol' }] },
    { id: 'applicant', labels: [{ culture: 'en_US', label: 'Applicant' }, { culture: 'es_EC', label: 'Postulante' }] },
    { id: 'invitedBy', labels: [{ culture: 'en_US', label: 'Invited by' }, { culture: 'es_EC', label: 'Invitado por' }] },
    { id: 'firstName', labels: [{ culture: 'en_US', label: 'First Name' }, { culture: 'es_EC', label: 'Nombres' }] },
    { id: 'lastName', labels: [{ culture: 'en_US', label: 'Last Name' }, { culture: 'es_EC', label: 'Apellidos' }] },
    { id: 'sex', labels: [{ culture: 'en_US', label: 'Sex' }, { culture: 'es_EC', label: 'Sexo' }] },
    { id: 'dateBirth', labels: [{ culture: 'en_US', label: 'Date Birth' }, { culture: 'es_EC', label: 'Fecha de Nacimiento' }] },
    { id: 'city', labels: [{ culture: 'en_US', label: 'City' }, { culture: 'es_EC', label: 'Ciudad' }] },
    { id: 'countryCode', labels: [{ culture: 'en_US', label: 'Country Code' }, { culture: 'es_EC', label: 'Código de País' }] },
    { id: 'phoneNumber', labels: [{ culture: 'en_US', label: 'Phone Number' }, { culture: 'es_EC', label: 'Número de Teléfono' }] },
    { id: 'idNumber', labels: [{ culture: 'en_US', label: 'ID Number' }, { culture: 'es_EC', label: 'Número de Identificación' }] },
    { id: 'currentyWorking', labels: [{ culture: 'en_US', label: 'Currenty Working' }, { culture: 'es_EC', label: 'Esta trabajando actualmente' }] },
    { id: 'additionalEmail', labels: [{ culture: 'en_US', label: 'Additional Email' }, { culture: 'es_EC', label: 'Correo Adicional' }] },
    { id: 'progress', labels: [{ culture: 'en_US', label: 'Progress' }, { culture: 'es_EC', label: 'Progreso' }] },
    { id: 'sendInvitationEmail', labels: [{ culture: 'en_US', label: 'Send invitation email' }, { culture: 'es_EC', label: 'Enviar el correo electrónico de invitación' }] },
    { id: 'thenEmail', labels: [{ culture: 'en_US', label: 'Then email' }, { culture: 'es_EC', label: 'El correo' }] },
    { id: 'alreadyExists', labels: [{ culture: 'en_US', label: 'already exists' }, { culture: 'es_EC', label: 'ya se encuentra registrado' }] },
    { id: 'successfullyCreated', labels: [{ culture: 'en_US', label: 'succesfully created' }, { culture: 'es_EC', label: 'creado exitosamente' }] },
    { id: 'successfullyAdded', labels: [{ culture: 'en_US', label: 'successfully added' }, { culture: 'es_EC', label: 'agregado exitosamente' }] },
    { id: 'successfullyRemoved', labels: [{ culture: 'en_US', label: 'successfully removed' }, { culture: 'es_EC', label: 'eliminado exitosamente' }] },
    { id: 'successfullySaved', labels: [{ culture: 'en_US', label: 'successfully saved' }, { culture: 'es_EC', label: 'guardado exitosamente' }] },
    { id: 'successfullyDeleted', labels: [{ culture: 'en_US', label: 'successfully deleted' }, { culture: 'es_EC', label: 'eliminado exitosamente' }] },
    { id: 'successfullyUpdated', labels: [{ culture: 'en_US', label: 'successfully updated' }, { culture: 'es_EC', label: 'actualizado exitosamente' }] },
    { id: 'couldNotSendEmail', labels: [{ culture: 'en_US', label: 'Could not send email' }, { culture: 'es_EC', label: 'No se puedo enviar el correo electrónico' }] },
    { id: 'emailSentSuccessfully', labels: [{ culture: 'en_US', label: 'Email sent successfully' }, { culture: 'es_EC', label: 'Correo electrónico enviado existosamente' }] },
    { id: 'addCountryCourseApplicant', labels: [{ culture: 'en_US', label: 'Add at least one country, one course and one applicant' }, { culture: 'es_EC', label: 'Agregue al menos un país, un curso y un postulante' }] },
    { id: 'publishSuccesfully', labels: [{ culture: 'en_US', label: 'publish succesfully' }, { culture: 'es_EC', label: 'publicado exitosamente' }] },
    { id: 'saveDraft', labels: [{ culture: 'en_US', label: 'Save Draft' }, { culture: 'es_EC', label: 'Guardar borrador' }] },
    { id: 'surePublishTraining', labels: [{ culture: 'en_US', label: 'Are you sure to publish the training?' }, { culture: 'es_EC', label: '¿Seguro(a) que deseas publicar la formación?' }] },
    { id: 'yesPublish', labels: [{ culture: 'en_US', label: 'Yes, publish' }, { culture: 'es_EC', label: 'Sí, publicar' }] },
    { id: 'publish', labels: [{ culture: 'en_US', label: 'Publish' }, { culture: 'es_EC', label: 'Publicar' }] },
    { id: 'idTraining', labels: [{ culture: 'en_US', label: 'Id Training' }, { culture: 'es_EC', label: 'ID de Formación' }] },
    { id: 'completeID', labels: [{ culture: 'en_US', label: 'Complete ID' }, { culture: 'es_EC', label: 'Complete el ID' }] },
    { id: 'region', labels: [{ culture: 'en_US', label: 'Region' }, { culture: 'es_EC', label: 'Región' }] },
    { id: 'selectRegion', labels: [{ culture: 'en_US', label: 'Select region' }, { culture: 'es_EC', label: 'Seleccione la región' }] },
    { id: 'language', labels: [{ culture: 'en_US', label: 'Language' }, { culture: 'es_EC', label: 'Idioma' }] },
    { id: 'selectLanguage', labels: [{ culture: 'en_US', label: 'Select language' }, { culture: 'es_EC', label: 'Seleccione el idioma' }] },
    { id: 'selectCountry', labels: [{ culture: 'en_US', label: 'Select country' }, { culture: 'es_EC', label: 'Seleccione el país' }] },
    { id: 'trainingName', labels: [{ culture: 'en_US', label: 'Training name' }, { culture: 'es_EC', label: 'Nombre de la formación' }] },
    { id: 'completeTrainingName', labels: [{ culture: 'en_US', label: 'Complete training name' }, { culture: 'es_EC', label: 'Complete el nombre la formación' }] },
    { id: 'modality', labels: [{ culture: 'en_US', label: 'Modality' }, { culture: 'es_EC', label: 'Modalidad' }] },
    { id: 'selectModality', labels: [{ culture: 'en_US', label: 'Select modality' }, { culture: 'es_EC', label: 'Seleccione la modalidad' }] },
    { id: 'startDate', labels: [{ culture: 'en_US', label: 'Start Date' }, { culture: 'es_EC', label: 'Fecha de Inicio' }] },
    { id: 'selectStartDate', labels: [{ culture: 'en_US', label: 'Select start date' }, { culture: 'es_EC', label: 'Seleccione la fecha de inicio' }] },
    { id: 'endDate', labels: [{ culture: 'en_US', label: 'End Date' }, { culture: 'es_EC', label: 'Fecha Final' }] },
    { id: 'selectEndDate', labels: [{ culture: 'en_US', label: 'Select end date' }, { culture: 'es_EC', label: 'Seleccione la fecha final' }] },
    { id: 'selectCourses', labels: [{ culture: 'en_US', label: 'Select courses' }, { culture: 'es_EC', label: 'Seleccione los cursos' }] },
    { id: 'applicantsEmails', labels: [{ culture: 'en_US', label: 'Applicants emails' }, { culture: 'es_EC', label: 'Correos electrónicos de los postulantes' }] },
    { id: 'pressEnterAddEmail', labels: [{ culture: 'en_US', label: 'Press enter to add email' }, { culture: 'es_EC', label: 'Presione enter para agregar el correo electrónico' }] },
    { id: 'addApplicants', labels: [{ culture: 'en_US', label: 'Add applicants' }, { culture: 'es_EC', label: 'Agregue al menos un postulante' }] },
    { id: 'notPossibleAddApplicant', labels: [{ culture: 'en_US', label: 'It was not possible to add the applicant' }, { culture: 'es_EC', label: 'No fue posible agregar el postulante' }] },
    { id: 'myAccount', labels: [{ culture: 'en_US', label: 'My Account' }, { culture: 'es_EC', label: 'Mi Cuenta' }] },
    { id: 'signOut', labels: [{ culture: 'en_US', label: 'Sign out' }, { culture: 'es_EC', label: 'Cerrar sesión' }] },
    { id: 'accountUpdatedSuccessfully', labels: [{ culture: 'en_US', label: 'Account updated successfully' }, { culture: 'es_EC', label: 'Cuenta actualizada exitosamente' }] },
    { id: 'uploadFailed', labels: [{ culture: 'en_US', label: 'Upload failed' }, { culture: 'es_EC', label: 'Carga fallida' }] },
    { id: 'uploadProfilePicture', labels: [{ culture: 'en_US', label: 'Upload your profile picture' }, { culture: 'es_EC', label: 'Sube tu foto de perfil' }] },
    { id: 'selectPhoto', labels: [{ culture: 'en_US', label: 'Select a Photo' }, { culture: 'es_EC', label: 'Seleccione una foto' }] },
    { id: 'completeFirstName', labels: [{ culture: 'en_US', label: 'Complete First Name' }, { culture: 'es_EC', label: 'Complete los nombres' }] },
    { id: 'completeLastName', labels: [{ culture: 'en_US', label: 'Complete Last Name' }, { culture: 'es_EC', label: 'Complete los apellidos' }] },
    { id: 'selectSex', labels: [{ culture: 'en_US', label: 'Select sex' }, { culture: 'es_EC', label: 'Selecciona el sexo' }] },
    { id: 'selectDateBirth', labels: [{ culture: 'en_US', label: 'Select date of birth' }, { culture: 'es_EC', label: 'Selecciona la fecha de nacimiento' }] },
    { id: 'uniqueIdentificationCountry', labels: [{ culture: 'en_US', label: 'This is the unique identification number issued by the country of residence' }, { culture: 'es_EC', label: 'Este es el número de identificación único emitido por el país de residencia' }] },
    { id: 'completeCity', labels: [{ culture: 'en_US', label: 'Complete city' }, { culture: 'es_EC', label: 'Complete la ciudad' }] },
    { id: 'selectCountryCode', labels: [{ culture: 'en_US', label: 'Select country code' }, { culture: 'es_EC', label: 'Seleccione el código de país' }] },
    { id: 'completePhoneNumber', labels: [{ culture: 'en_US', label: 'Complete the phone number' }, { culture: 'es_EC', label: 'Complete el número de teléfono' }] },
    { id: 'completeNameInstitution', labels: [{ culture: 'en_US', label: 'Please write the complete name of the institution' }, { culture: 'es_EC', label: 'Por favor escriba el nombre completo de la institución' }] },
    { id: 'ifYesCompleteNameInstitution', labels: [{ culture: 'en_US', label: 'If yes, please write the complete name of the institution' }, { culture: 'es_EC', label: 'En caso afirmativo, escriba el nombre completo de la institución' }] },
    { id: 'completeAdditionalEmail', labels: [{ culture: 'en_US', label: 'Complete the additional email' }, { culture: 'es_EC', label: 'Complete el correo electrónico adicional' }] },
    { id: 'additionalEmailNotValid', labels: [{ culture: 'en_US', label: 'Additional email is not valid' }, { culture: 'es_EC', label: 'El correo electrónico adicional no es válido' }] },
    { id: 'state', labels: [{ culture: 'en_US', label: 'State' }, { culture: 'es_EC', label: 'Estado' }] },
    { id: 'selectState', labels: [{ culture: 'en_US', label: 'Select state' }, { culture: 'es_EC', label: 'Seleccione el estado' }] },
    { id: 'pleaseWait', labels: [{ culture: 'en_US', label: 'Please Wait' }, { culture: 'es_EC', label: 'Espere por favor' }] },
    { id: 'passwordResetSuccessfully', labels: [{ culture: 'en_US', label: 'Password reset successfully' }, { culture: 'es_EC', label: 'Restablecimiento de contraseña exitoso' }] },
    { id: 'completePassword', labels: [{ culture: 'en_US', label: 'Complete the password' }, { culture: 'es_EC', label: 'Complete la contraseña' }] },
    { id: 'password', labels: [{ culture: 'en_US', label: 'Password' }, { culture: 'es_EC', label: 'Contraseña' }] },
    { id: 'newPassword', labels: [{ culture: 'en_US', label: 'New password' }, { culture: 'es_EC', label: 'Nueva contraseña' }] },
    { id: 'confirmNewPassword', labels: [{ culture: 'en_US', label: 'Confirm new password' }, { culture: 'es_EC', label: 'Confirme la nueva contraseña' }] },
    { id: 'completeNewPassword', labels: [{ culture: 'en_US', label: 'Complete then new password' }, { culture: 'es_EC', label: 'Complete la nueva contraseña' }] },
    { id: 'resetting', labels: [{ culture: 'en_US', label: 'Resetting' }, { culture: 'es_EC', label: 'Reestableciendo' }] },
    { id: 'passwordHasMore8Characters', labels: [{ culture: 'en_US', label: 'Password has more than 8 characters' }, { culture: 'es_EC', label: 'La contraseña tiene más de 8 caracteres' }] },
    { id: 'passwordHasSpecialCharacters', labels: [{ culture: 'en_US', label: 'Password has special characters' }, { culture: 'es_EC', label: 'La contraseña tiene caracteres especiales' }] },
    { id: 'passwordHasNumber', labels: [{ culture: 'en_US', label: 'Password has a number' }, { culture: 'es_EC', label: 'La contraseña tiene un número' }] },
    { id: 'passwordHasCapitalLetter', labels: [{ culture: 'en_US', label: 'Password has a capital letter' }, { culture: 'es_EC', label: 'La contraseña tiene una letra mayúscula' }] },
    { id: 'passwordsMatch', labels: [{ culture: 'en_US', label: 'Passwords match' }, { culture: 'es_EC', label: 'Las contraseñas coinciden' }] },
    { id: 'uploadYourWorkplan', labels: [{ culture: 'en_US', label: 'Upload your workplan' }, { culture: 'es_EC', label: 'Sube tu plan de trabajo' }] },
    { id: 'file', labels: [{ culture: 'en_US', label: 'File' }, { culture: 'es_EC', label: 'Archivo' }] },
    { id: 'selectFile', labels: [{ culture: 'en_US', label: 'Select File' }, { culture: 'es_EC', label: 'Seleccionar archivo' }] },
    { id: 'uploading', labels: [{ culture: 'en_US', label: 'Uploading' }, { culture: 'es_EC', label: 'Subiendo' }] },
    { id: 'sending', labels: [{ culture: 'en_US', label: 'Sending' }, { culture: 'es_EC', label: 'Enviando' }] },
    { id: 'send', labels: [{ culture: 'en_US', label: 'Send' }, { culture: 'es_EC', label: 'Enviar' }] },
    { id: 'uploadSuccessfully', labels: [{ culture: 'en_US', label: 'Upload successfully' }, { culture: 'es_EC', label: 'Carga realizada exitosamente' }] },
    { id: 'workplanTemplate', labels: [{ culture: 'en_US', label: 'Workplan Template' }, { culture: 'es_EC', label: 'Plantilla de plan de trabajo' }] },
    { id: 'languagePreferences', labels: [{ culture: 'en_US', label: 'Language preferences' }, { culture: 'es_EC', label: 'Preferencias de idioma' }] },
    { id: 'seePreferredLanguage', labels: [{ culture: 'en_US', label: 'See buttons, titles, and other texts on webapp in your preferred language' }, { culture: 'es_EC', label: 'Vea botones, títulos y otros textos en la aplicación web en su idioma preferido' }] },
    { id: 'back', labels: [{ culture: 'en_US', label: 'Back' }, { culture: 'es_EC', label: 'Regresar' }] },
    { id: 'languagePreferencesSaved', labels: [{ culture: 'en_US', label: 'Language preferences saved successfully' }, { culture: 'es_EC', label: 'Las preferencias de idioma se guardaron correctamente' }] },
    { id: 'ok', labels: [{ culture: 'en_US', label: 'OK' }, { culture: 'es_EC', label: 'OK' }] },
    { id: 'reset', labels: [{ culture: 'en_US', label: 'Reset' }, { culture: 'es_EC', label: 'Limpiar' }] },
    { id: 'save', labels: [{ culture: 'en_US', label: 'Save' }, { culture: 'es_EC', label: 'Guardar' }] },
    { id: 'accept', labels: [{ culture: 'en_US', label: 'Accept' }, { culture: 'es_EC', label: 'Aceptar' }] },
    { id: 'yes', labels: [{ culture: 'en_US', label: 'Yes' }, { culture: 'es_EC', label: 'Si' }] },
    { id: 'no', labels: [{ culture: 'en_US', label: 'No' }, { culture: 'es_EC', label: 'No' }] },
    { id: 'cancel', labels: [{ culture: 'en_US', label: 'Cancel' }, { culture: 'es_EC', label: 'Cancelar' }] },
    { id: 'minimumBeneficiaries', labels: [{ culture: 'en_US', label: 'Minimum Beneficiaries' }, { culture: 'es_EC', label: 'Mínimo de Beneficiarios' }] },
    { id: 'beneficiaryTitle', labels: [{ culture: 'en_US', label: 'Beneficiary' }, { culture: 'es_EC', label: 'Beneficiario' }] },
    { id: 'beneficiariesTitle', labels: [{ culture: 'en_US', label: 'Beneficiaries' }, { culture: 'es_EC', label: 'Beneficiarios' }] },
    { id: 'deleteBeneficiaryTitle', labels: [{ culture: 'en_US', label: 'Delete Beneficiary' }, { culture: 'es_EC', label: 'Eliminar Beneficiario' }] },
    { id: 'reConfirmDeleteBeneficiary', labels: [{ culture: 'en_US', label: 'If you do not meet the minimum number of registered beneficiaries, your final report will be disabled. Continue anyway?' }, { culture: 'es_EC', label: 'Si no cumple con el mínimo de beneficiarios registrados se deshabilitara su informe final. ¿Continuar de todos modos?' }] },
    { id: 'sendBeneficiaryListTitle', labels: [{ culture: 'en_US', label: 'Send Beneficiary List' }, { culture: 'es_EC', label: 'Enviar Lista de Beneficiarios' }] },
    { id: 'sendBeneficiaryListConfirm', labels: [{ culture: 'en_US', label: 'Check beneficiary qualification/participation. Once sent, further editing will not be allowed and the certificate will be generated. Are you sure you want to send the list of beneficiaries?' }, { culture: 'es_EC', label: 'Verifique la calificación/participación de los beneficiarios. Una vez enviado, no se permitirán más ediciones y se generará el certificado. ¿Estás seguro de que quieres enviar la lista de beneficiarios?' }] },
    { id: 'beneficiarySuccessfullyRemoved', labels: [{ culture: 'en_US', label: 'Beneficiary successfully removed' }, { culture: 'es_EC', label: 'Beneficiario eliminado con éxito' }] },
    { id: 'beneficiarySuccessfullyUpdated', labels: [{ culture: 'en_US', label: 'Beneficiary successfully updated' }, { culture: 'es_EC', label: 'Beneficiario actualizado exitosamente' }] },
    { id: 'certificateSentSuccessfully', labels: [{ culture: 'en_US', label: 'Certificate sent successfully' }, { culture: 'es_EC', label: 'Certificado enviado exitosamente' }] },
    { id: 'certificateSendFailed', labels: [{ culture: 'en_US', label: 'Certificate send failed' }, { culture: 'es_EC', label: 'Error al enviar el certificado' }] },
    { id: 'certificateGeneratedSuccessfully', labels: [{ culture: 'en_US', label: 'Certificate generated successfully' }, { culture: 'es_EC', label: 'Certificado generado exitosamente' }] },
    { id: 'newBeneficiary', labels: [{ culture: 'en_US', label: 'New Beneficiary' }, { culture: 'es_EC', label: 'Nuevo beneficiario' }] },
    { id: 'jobTitle', labels: [{ culture: 'en_US', label: 'Position' }, { culture: 'es_EC', label: 'Cargo' }] },
    { id: 'isWorking', labels: [{ culture: 'en_US', label: 'Is Working' }, { culture: 'es_EC', label: 'Está trabajando' }] },
    { id: 'company', labels: [{ culture: 'en_US', label: 'Enterprise Name' }, { culture: 'es_EC', label: 'Empresa' }] },
    { id: 'instructionLevel', labels: [{ culture: 'en_US', label: 'Instruction Level' }, { culture: 'es_EC', label: 'Nivel de instrucción' }] },
    { id: 'totalPersons', labels: [{ culture: 'en_US', label: 'Nro. of Employees' }, { culture: 'es_EC', label: 'Nro. de Empleados' }] },
    { id: 'actions', labels: [{ culture: 'en_US', label: 'Actions' }, { culture: 'es_EC', label: 'Acciones' }] },
    { id: 'sureDeleteBeneficiary', labels: [{ culture: 'en_US', label: 'Are you sure you want to delete the beneficiary?' }, { culture: 'es_EC', label: '¿Estás seguro de que deseas eliminar al beneficiario?' }] },
    { id: 'registryOperation', labels: [{ culture: 'en_US', label: 'Beneficiary Registry' }, { culture: 'es_EC', label: 'Registro de Beneficiarios' }] },
    { id: 'qualificationOperation', labels: [{ culture: 'en_US', label: 'Beneficiary Qualification' }, { culture: 'es_EC', label: 'Calificación de Beneficiarios' }] },
    { id: 'oneApprovedWorkplanRegisterBeneficiaries', labels: [{ culture: 'en_US', label: 'There must be at least one approved workplan to register beneficiaries' }, { culture: 'es_EC', label: 'Debe haber al menos un plan de trabajo aprobado para registrar beneficiarios' }] },
    { id: 'sendWorkplan', labels: [{ culture: 'en_US', label: 'Send Workplan' }, { culture: 'es_EC', label: 'Enviar plan de trabajo' }] },
    { id: 'oneApprovedFinalReportQualifyBeneficiaries', labels: [{ culture: 'en_US', label: 'There must be at least one approved final report to qualify beneficiaries' }, { culture: 'es_EC', label: 'Debe haber al menos un informe final aprobado para calificar a los beneficiarios' }] },
    { id: 'sendFinalReport', labels: [{ culture: 'en_US', label: 'Send Final Report' }, { culture: 'es_EC', label: 'Enviar informe final' }] },
    { id: 'completeInstructionLevel', labels: [{ culture: 'en_US', label: 'Complete the Instruction Level' }, { culture: 'es_EC', label: 'Completa el nivel de instrucción' }] },
    { id: 'saving', labels: [{ culture: 'en_US', label: 'Saving' }, { culture: 'es_EC', label: 'Guardando' }] },
    { id: 'companyID', labels: [{ culture: 'en_US', label: 'Enterprise ID' }, { culture: 'es_EC', label: 'ID de Empresa' }] },
    { id: 'completeCompanyID', labels: [{ culture: 'en_US', label: 'Complete the Enterprise ID' }, { culture: 'es_EC', label: 'Complete el ID de Empresa' }] },
    { id: 'completeCompany', labels: [{ culture: 'en_US', label: 'Complete the Enterprise Name' }, { culture: 'es_EC', label: 'Complete la Empresa' }] },
    { id: 'completeJobTitle', labels: [{ culture: 'en_US', label: 'Complete the position' }, { culture: 'es_EC', label: 'Complete el cargo' }] },
    { id: 'totalCollaborators', labels: [{ culture: 'en_US', label: 'Nro. of Employees' }, { culture: 'es_EC', label: 'Nro. de Empleados' }] },
    { id: 'completeTotalCollaborators', labels: [{ culture: 'en_US', label: 'Complete Nro. of Employees' }, { culture: 'es_EC', label: 'Completa el Nro. de Empleados' }] },
    { id: 'men', labels: [{ culture: 'en_US', label: 'Men' }, { culture: 'es_EC', label: 'Hombres' }] },
    { id: 'women', labels: [{ culture: 'en_US', label: 'Women' }, { culture: 'es_EC', label: 'Mujeres' }] },
    { id: 'numberMen', labels: [{ culture: 'en_US', label: 'Number of men' }, { culture: 'es_EC', label: 'Número de hombres' }] },
    { id: 'numberWomen', labels: [{ culture: 'en_US', label: 'Number of women' }, { culture: 'es_EC', label: 'Número de mujeres' }] },
    { id: 'uploadFinalReport', labels: [{ culture: 'en_US', label: 'Upload your final report' }, { culture: 'es_EC', label: 'Sube tu informe final' }] },
    { id: 'copyEvidenceLink', labels: [{ culture: 'en_US', label: 'Copy evidence link' }, { culture: 'es_EC', label: 'Copiar enlace de evidencia' }] },
    { id: 'url', labels: [{ culture: 'en_US', label: 'URL' }, { culture: 'es_EC', label: 'URL' }] },
    { id: 'evidenceSendedSuccessfully', labels: [{ culture: 'en_US', label: 'Evidence sended successfully' }, { culture: 'es_EC', label: 'Evidencia enviada exitosamente' }] },
    { id: 'finalReportTemplate', labels: [{ culture: 'en_US', label: 'Final Report Template' }, { culture: 'es_EC', label: 'Plantilla de informe final' }] },
    { id: 'thereMustLeast', labels: [{ culture: 'en_US', label: 'There must be at least' }, { culture: 'es_EC', label: 'Debe haber al menos' }] },
    { id: 'registeredBeneficiariesUploadFinalReport', labels: [{ culture: 'en_US', label: 'registered beneficiaries to upload your final report' }, { culture: 'es_EC', label: 'beneficiarios registrados para cargar su informe final' }] },
    { id: 'registerBeneficiaries', labels: [{ culture: 'en_US', label: 'Register Beneficiaries' }, { culture: 'es_EC', label: 'Registrar beneficiarios' }] },
    { id: 'seeYourCertificateFinalReportBeneficiary', labels: [{ culture: 'en_US', label: 'You will see your certificate once your final report is approved and you submit your beneficiary list' }, { culture: 'es_EC', label: 'Verá su certificado una vez que se apruebe su informe final y envíe su lista de beneficiarios.' }] },
    { id: 'sendListBeneficiaries', labels: [{ culture: 'en_US', label: 'Send list of beneficiaries' }, { culture: 'es_EC', label: 'Enviar lista de beneficiarios' }] },
    { id: 'users', labels: [{ culture: 'en_US', label: 'Users' }, { culture: 'es_EC', label: 'Usuarios' }] },
    { id: 'trainerOfTrainers', labels: [{ culture: 'en_US', label: 'Trainer of trainers' }, { culture: 'es_EC', label: 'Formador de formadores' }] },
    { id: 'trainer', labels: [{ culture: 'en_US', label: 'Trainer' }, { culture: 'es_EC', label: 'Formador' }] },
    { id: 'sessionExpired', labels: [{ culture: 'en_US', label: 'The session has expired. Please log in again.' }, { culture: 'es_EC', label: 'La sesión ha expirado. Por favor inicia sesión nuevamente.' }] },
    { id: 'trainingsNotFound', labels: [{ culture: 'en_US', label: 'Trainings not found' }, { culture: 'es_EC', label: 'Formaciones no encontradas' }] },
    { id: 'backHome', labels: [{ culture: 'en_US', label: 'Back to home' }, { culture: 'es_EC', label: 'Volver al inicio' }] },
    { id: 'notEnrolled', labels: [{ culture: 'en_US', label: 'Not enrolled' }, { culture: 'es_EC', label: 'No enrolado' }] },
    { id: 'usersManagement', labels: [{ culture: 'en_US', label: 'Users Management' }, { culture: 'es_EC', label: 'Gestión de Usuarios' }] },
    { id: 'new', labels: [{ culture: 'en_US', label: 'New' }, { culture: 'es_EC', label: 'Nuevo' }] },
    { id: 'userUpdatedSuccessfully', labels: [{ culture: 'en_US', label: 'User updated successfully' }, { culture: 'es_EC', label: 'Usuario actualizado exitosamente' }] },
    { id: 'user', labels: [{ culture: 'en_US', label: 'User' }, { culture: 'es_EC', label: 'Usuario' }] },
    { id: 'userCreatedSuccessfully', labels: [{ culture: 'en_US', label: 'User created successfully' }, { culture: 'es_EC', label: 'Usuario creado exitosamente' }] },
    { id: 'createYourPassword', labels: [{ culture: 'en_US', label: 'Create password' }, { culture: 'es_EC', label: 'Cree la contraseña' }] },
    { id: 'confirmYourPassword', labels: [{ culture: 'en_US', label: 'Confirm password' }, { culture: 'es_EC', label: 'Confirme la contraseña' }] },
    { id: 'profile', labels: [{ culture: 'en_US', label: 'Profile' }, { culture: 'es_EC', label: 'Perfil' }] },
    { id: 'selectProfile', labels: [{ culture: 'en_US', label: 'Select profile' }, { culture: 'es_EC', label: 'Seleccionar el perfil' }] },
    { id: 'profilesManagement', labels: [{ culture: 'en_US', label: 'Profiles Management' }, { culture: 'es_EC', label: 'Gestión de Perfiles' }] },
    { id: 'level', labels: [{ culture: 'en_US', label: 'Level' }, { culture: 'es_EC', label: 'Nivel' }] },
    { id: 'active', labels: [{ culture: 'en_US', label: 'Active' }, { culture: 'es_EC', label: 'Activo' }] },
    { id: 'inactive', labels: [{ culture: 'en_US', label: 'Inactivo' }, { culture: 'es_EC', label: 'Inactivo' }] },
    { id: 'profileSavedSuccessfully', labels: [{ culture: 'en_US', label: 'Profile saved successfully' }, { culture: 'es_EC', label: 'Perfil guardado exitosamente' }] },
    { id: 'profileDeletedSuccessfully', labels: [{ culture: 'en_US', label: 'Profile deleted successfully' }, { culture: 'es_EC', label: 'Perfil eliminado exitosamente' }] },
    { id: 'sureDeleteProfiles', labels: [{ culture: 'en_US', label: 'Are you sure you want to delete the selected profiles?' }, { culture: 'es_EC', label: '¿Seguro(a) que deseas eliminar los perfiles seleccionados?' }] },
    { id: 'delete', labels: [{ culture: 'en_US', label: 'Delete' }, { culture: 'es_EC', label: 'Eliminar' }] },
    { id: 'deleting', labels: [{ culture: 'en_US', label: 'Deleting' }, { culture: 'es_EC', label: 'Eliminando' }] },
    { id: 'sureDeleteProfile', labels: [{ culture: 'en_US', label: 'Are you sure you want to delete the profile?' }, { culture: 'es_EC', label: '¿Seguro(a) que deseas eliminar el perfil?' }] },
    { id: 'option', labels: [{ culture: 'en_US', label: 'Option' }, { culture: 'es_EC', label: 'Opción' }] },
    { id: 'options', labels: [{ culture: 'en_US', label: 'Options' }, { culture: 'es_EC', label: 'Opciones' }] },
    { id: 'of', labels: [{ culture: 'en_US', label: 'of' }, { culture: 'es_EC', label: 'de' }] },
    { id: 'resultsFound', labels: [{ culture: 'en_US', label: 'results found' }, { culture: 'es_EC', label: 'resultados encontrados' }] },
    { id: 'filtered', labels: [{ culture: 'en_US', label: 'Filtered' }, { culture: 'es_EC', label: 'Filtrados' }] },
    { id: 'sureDownloadUsersReport', labels: [{ culture: 'en_US', label: 'Do you want to download the report of all Users?' }, { culture: 'es_EC', label: '¿Desea descargar el reporte de todos los Usuarios?' }] },
    { id: 'sureDownloadBeneficiariesReport', labels: [{ culture: 'en_US', label: 'Do you want to download the report of all Beneficiaries?' }, { culture: 'es_EC', label: '¿Desea descargar el reporte de todos los Beneficiaros?' }] },
    { id: 'passwordSentIndicatedEmail', labels: [{ culture: 'en_US', label: 'Your password was sent to the indicated email. Check your inbox.' }, { culture: 'es_EC', label: 'Su contraseña fue enviada al correo electrónico indicado. Revise tu correo.' }] },
    { id: 'passwordSuccessfullyChanged', labels: [{ culture: 'en_US', label: 'Password has been successfully changed. Please log in again.' }, { culture: 'es_EC', label: 'La contraseña se ha cambiado correctamente. Por favor inicia sesión nuevamente.' }] },
    { id: 'resettingPassword', labels: [{ culture: 'en_US', label: 'Resetting password' }, { culture: 'es_EC', label: 'Reestableciendo contraseña' }] },
    { id: 'pageNotExist', labels: [{ culture: 'en_US', label: 'Page does not exist' }, { culture: 'es_EC', label: 'La página no existe' }] },
    { id: 'pageVisitedNotExist', labels: [{ culture: 'en_US', label: 'Sorry, the page you visited does not exist' }, { culture: 'es_EC', label: 'Lo sentimos, la página que visitaste no existe.' }] },
    { id: 'sendNotification', labels: [{ culture: 'en_US', label: 'Send notification' }, { culture: 'es_EC', label: 'Enviar notificación' }] },
    { id: 'accountCreatedSuccessfully', labels: [{ culture: 'en_US', label: 'Your account has been created successfully. Please log in.' }, { culture: 'es_EC', label: 'Su cuenta ha sido creada con éxito. Por favor Iniciar sesión.' }] },
    { id: 'welcomeMyGreenEnterprise', labels: [{ culture: 'en_US', label: 'Welcome to My Green Enterprise' }, { culture: 'es_EC', label: 'Bienvenido a Mi Negocio Verde' }] },
    { id: 'selectProfilePicture', labels: [{ culture: 'en_US', label: 'Select your profile picture' }, { culture: 'es_EC', label: 'Selecciona tu foto de perfil' }] },
    { id: 'completeInformationActiveAccount', labels: [{ culture: 'en_US', label: 'Complete the following information to active your account' }, { culture: 'es_EC', label: 'Complete la siguiente información para activar su cuenta' }] },
    { id: 'register', labels: [{ culture: 'en_US', label: 'Register' }, { culture: 'es_EC', label: 'Registro' }] },
    { id: 'accountPreviouslyCreated', labels: [{ culture: 'en_US', label: 'Your account has been previously created' }, { culture: 'es_EC', label: 'Tu cuenta ha sido creada previamente' }] },
    { id: 'uploadSignature', labels: [{ culture: 'en_US', label: 'Upload signature' }, { culture: 'es_EC', label: 'Subir firma' }] },
    { id: 'signature', labels: [{ culture: 'en_US', label: 'Firma' }, { culture: 'es_EC', label: 'Signature' }] },
    { id: 'signatureUploaded', labels: [{ culture: 'en_US', label: 'Signature Uploaded' }, { culture: 'es_EC', label: 'Firma Cargada' }] },
    { id: 'signatureAlreadyUploaded', labels: [{ culture: 'en_US', label: 'An uploaded signature already exists' }, { culture: 'es_EC', label: 'Ya existe una firma cargada' }] },
    { id: 'loginAs', labels: [{ culture: 'en_US', label: 'Login as' }, { culture: 'es_EC', label: 'Ingresar como' }] },
    { id: 'theSum', labels: [{ culture: 'en_US', label: 'The sum of' }, { culture: 'es_EC', label: 'La suma de' }] },
    { id: 'mustEqual', labels: [{ culture: 'en_US', label: 'must be equal to the' }, { culture: 'es_EC', label: 'debe ser igual al' }] },
    { id: 'signatureMustBePNG', labels: [{ culture: 'en_US', label: 'The signature must be a png file' }, { culture: 'es_EC', label: 'La firma debe ser un archivo .png' }] },
]
export const getLabel = (id) => contents.filter(c => c.id === id)[0].labels.filter(l => l.culture === getCulture())[0].label || 'Label undefined'
