import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Typography, Button, Space, Upload, Spin, Image, Descriptions, Collapse, Form, Input } from 'antd'
import * as anticons from '@ant-design/icons'
import * as apiURLs from '../../utils/urls'
import { getCulture, getLabel, labels } from '../../utils/Internationalization'
import { getCatalogos, getDatosUsuario, getInformStatus, getSortInforms, getToken, getTypeContent, setTrainingInforms } from '../../utils/Utils'
import $ from 'jquery'
import { saveAs } from 'file-saver'

import PostulantService from '../../service/PostulantService'
import InformService from './../../service/InformService'
import toast from 'react-hot-toast'

const postulantService = new PostulantService()
const informService = new InformService()

const panelStyle = {
    marginBottom: 20,
    backgroundColor: '#fafafa',
    border: 'none',
    borderRadius: '15px'
}

const WorkPlan = () => {

    const navigate = useHistory()
    const [trainings, setTrainings] = useState([])
    const [load, setLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)

    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false)
    const [sending, setSending] = useState(false)
    const [selectedWorkplan, setSelectedWorkplan] = useState([])

    const props = {
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
        fileList,
    }

    const downloadWorkplanTemplate = () => {
        const fileName = 'WorkplanTemplate.docx'
        const metaData = getTypeContent(fileName)
        var xhrOverride = new XMLHttpRequest();
        xhrOverride.responseType = 'arraybuffer';
        setIsDownloading(true)
        $.ajax({
            async: true,
            crossDomain: true,
            url: apiURLs.downloadWorkplan(),
            method: 'GET',
            headers: {
                Accept: "*/*",
                "Accept-Language": getCulture(),
                Authorization: `Bearer ${getToken()}`
            },
            xhr: function () {
                return xhrOverride;
            }
        }).then(function (response) {
            setIsDownloading(false)
            const blob = new Blob([response], { type: metaData })
            saveAs(blob, fileName)
        });
    }

    const downloadInform = ({ infNombre, infRutaPlan }) => {

        const metaData = getTypeContent(infNombre)

        var xhrOverride = new XMLHttpRequest();
        xhrOverride.responseType = 'arraybuffer';

        setIsDownloading(true)
        $.ajax({
            async: true,
            crossDomain: true,
            url: apiURLs.downloadInformByName(),
            method: 'POST',
            headers: {
                Accept: "*/*",
                "Accept-Language": getCulture(),
                Authorization: `Bearer ${getToken()}`,
                "Content-Type": "application/json"
            },
            data: JSON.stringify({
                infNombre,
                infRutaPlan
            }),
            xhr: function () {
                return xhrOverride;
            }
        }).then(function (response) {
            setIsDownloading(false)
            const blob = new Blob([response], { type: metaData })
            saveAs(blob, infNombre)
        });
    }

    const renderWorkplan = ({ infId, infFechaReg, infFechaSAprRec, infNombre, infEstado, infObservacion, infRutaPlan, evidenciaList, idCurso }) =>
        <div
            key={infId}
            style={
                {
                    backgroundColor: '#ffffff',
                    padding: '15px',
                    borderRadius: '15px',
                    marginBottom: '20px'
                }
            }>
            <Row>
                <Col xs={14} sm={18} md={18} lg={18} xl={18} xxl={18}>
                    <Row gutter={10}>
                        <Col>
                            <Image src={`./../../assets/${infEstado.catValor === 'EST_INF_ENV' ? 'send.png' : infEstado.catValor === 'EST_INF_APR' ? 'accept.png' : infEstado.catValor === 'EST_INF_REC' ? 'cancel.png' : 'minus.png'}`} preview={false} width={24} height={24} />
                        </Col>
                        <Col>
                            <Typography.Title level={5}>
                                {infNombre}
                            </Typography.Title>
                        </Col>
                    </Row>
                </Col>
                <Col xs={10} sm={6} md={6} lg={6} xl={6} xxl={6}>
                    <Row gutter={10} justify='end'>
                        <Col style={{ textAlign: 'end' }}>
                            <Space>
                                <Button type='default'
                                    onClick={() => downloadInform({ infNombre, infRutaPlan })}
                                    loading={isDownloading}
                                    disabled={isDownloading}
                                >
                                    {getLabel(labels.download)}
                                </Button>
                                <Button type='text'
                                    onClick={
                                        (e) => setSelectedWorkplan(
                                            selectedWorkplan.filter(i => i === infId).length > 0 ?
                                                selectedWorkplan.filter(i => i !== infId)
                                                :
                                                [...selectedWorkplan, infId]
                                        )
                                    }
                                    icon={
                                        selectedWorkplan.filter(i => i === infId).length < 1 ? <anticons.ArrowDownOutlined /> : <anticons.ArrowUpOutlined />
                                    }
                                />
                            </Space>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {
                selectedWorkplan.filter(i => i === infId).length > 0
                &&
                <Descriptions
                    size='small'
                    layout='vertical'
                    column={
                        {
                            xs: 1,
                            sm: 2,
                            md: 4,
                            lg: 4,
                            xl: 6,
                            xxl: 6,
                        }
                    }
                >
                    <Descriptions.Item label={
                        <Typography.Text type='secondary' strong>{getLabel(labels.registrationDate)}</Typography.Text>
                    }
                    >
                        {infFechaReg.substring(0, 10)}
                    </Descriptions.Item>

                    {
                        evidenciaList && evidenciaList.length > 0 ?
                            <>
                                <Descriptions.Item label={
                                    <Typography.Text type='secondary' strong>{getLabel(labels.evidences)}</Typography.Text>
                                }
                                >
                                    <a rel="noreferred noreferrer" target='_blank' href={`${evidenciaList[0].eviUrl}`}>
                                        {evidenciaList[0].eviUrl}
                                    </a>
                                </Descriptions.Item>
                            </>
                            :
                            <>
                            </>
                    }

                    {
                        infFechaSAprRec &&
                        <Descriptions.Item label={
                            <Typography.Text type='secondary' strong>{getLabel(labels.reviewDate)}</Typography.Text>
                        }
                        >
                            {infFechaSAprRec.substring(0, 10)}
                        </Descriptions.Item>
                    }

                    {
                        infObservacion &&
                        <Descriptions.Item
                            style={{
                                whiteSpace: 'pre-wrap'
                            }}
                            label={
                                <Typography.Text type='secondary' strong>{getLabel(labels.observation)}</Typography.Text>
                            }
                        >
                            {infObservacion}
                        </Descriptions.Item>
                    }

                </Descriptions>
            }
        </div>

    const renderCourse = (idEntrenamiento, posId, { curId, curNombre }) =>
        <Collapse.Panel
            key={`${idEntrenamiento}_${curId}`}
            header={
                <Row gutter={10}>
                    <Col>
                        {trainings.filter(t => t.idEntrenamiento === idEntrenamiento)[0].informeList && trainings.filter(t => t.idEntrenamiento === idEntrenamiento)[0].informeList.filter(w => w.infTipo.catValor === 'TYP_INF_WPL' && w.idCurso === curId).length > 0 ?
                            <Image src={`./../../assets/${getInformStatus(trainings.filter(t => t.idEntrenamiento === idEntrenamiento)[0].informeList.filter(w => w.infTipo.catValor === 'TYP_INF_WPL' && w.idCurso === curId))}`} preview={false} width={24} height={24} />
                            :
                            <Image src='./../../assets/minus.png' preview={false} width={24} height={24} />
                        }
                    </Col>
                    <Col>
                        <Typography.Text strong style={{ fontSize: '1.1em' }}>
                            {curNombre.catNombre}
                        </Typography.Text>
                    </Col>
                </Row>
            }
            style={panelStyle}
        >
            {
                trainings.filter(t => t.idEntrenamiento === idEntrenamiento)[0].informeList && getSortInforms(trainings.filter(t => t.idEntrenamiento === idEntrenamiento)[0].informeList.filter(w => w.infTipo.catValor === 'TYP_INF_WPL' && w.idCurso === curId)).map(w => renderWorkplan(w))
            }
            {
                trainings.filter(t => t.idEntrenamiento === idEntrenamiento)[0] && trainings.filter(t => t.idEntrenamiento === idEntrenamiento)[0].informeList && <>
                    {
                        trainings.filter(t => t.idEntrenamiento === idEntrenamiento)[0].informeList.filter(w => w.infTipo.catValor === 'TYP_INF_WPL' && w.idCurso === curId).length < 1
                            ||
                            (
                                trainings.filter(t => t.idEntrenamiento === idEntrenamiento)[0].informeList.filter(w => w.infTipo.catValor === 'TYP_INF_WPL' && w.idCurso === curId)[trainings.filter(t => t.idEntrenamiento === idEntrenamiento)[0].informeList.filter(w => w.infTipo.catValor === 'TYP_INF_WPL' && w.idCurso === curId).length - 1].infEstado.catValor === 'EST_INF_REC'
                            )
                            ?
                            <Form
                                onFinish={handleUpload}
                                initialValues={
                                    { curId, posId }
                                }
                                autoComplete='off'
                                layout='vertical'
                            >
                                <Row>
                                    <Col span={24}>
                                        <Typography.Title level={5}>
                                            {getLabel(labels.uploadYourWorkplan)}
                                        </Typography.Title>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ display: 'none' }}
                                            name='curId'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            style={{ display: 'none' }}
                                            name='posId'>
                                            <Input />
                                        </Form.Item>
                                        <Form.Item
                                            name='file'
                                            label={getLabel(labels.file)}
                                            rules={[
                                                {
                                                    required: true
                                                }
                                            ]}
                                        >
                                            <Upload
                                                multiple={false}
                                                {...props}
                                            >
                                                <Button
                                                    icon={<anticons.UploadOutlined />}
                                                    disabled={
                                                        fileList.length > 0
                                                    }
                                                >{getLabel(labels.selectFile)}</Button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Button
                                            type="primary"
                                            htmlType='submit'
                                            loading={uploading || sending}
                                            style={{
                                                marginTop: 16,
                                            }}
                                        >
                                            {uploading ? getLabel(labels.uploading) : sending ? getLabel(labels.sending) : getLabel(labels.send)}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                            : <>
                            </>
                    }
                </>
            }
        </Collapse.Panel>

    const sendLoadEmail = async ({ infId }) => {
        setSending(true)
        try {
            const inform = { infId }
            const { data } = await informService.sendEmailLoad({ navigate, inform })
            if (data) toast.success(getLabel(labels.emailSentSuccessfully))
            setLoad(true)
        } catch (_) {
            toast.error(getLabel(labels.emailSendFailed))
        } finally {
            setSending(false)
        }
    }

    const handleUpload = async (values) => {
        const { file, curId, posId } = values
        const inform = new FormData();
        inform.append('file', file.file)
        inform.append('idTipo', getCatalogos().reportType.filter(t => t.catValor === 'TYP_INF_WPL')[0].catId)
        inform.append('idCurso', curId)
        inform.append('idPostulante', posId)
        setUploading(true)
        try {
            const { data } = await informService.put({ navigate, inform })
            const informe = data.listObject[0]
            sendLoadEmail(informe)
            toast.success(getLabel(labels.uploadSuccessfully))
        } catch (_) {
            toast.error(getLabel(labels.uploadFailed))
        } finally {
            setUploading(false)
            setFileList([])
        }
    }

    const fetchData = async () => {
        setLoad(false)
        setIsLoading(true)
        try {
            const postulant = { idUsuario: getDatosUsuario().usuarioId }
            const { data } = await postulantService.getTraining({ navigate, postulant })
            const trainings = data.listObject
            setTrainings(trainings)
            setTrainingInforms(trainings)
            const training = data.listObject[0]
            setSelectedWorkplan(training.informeList.filter(w => w.infTipo.catValor === 'TYP_INF_WPL').map(i => i.infId))
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (load) fetchData()
    })

    return (
        <>
            <Row>
                <Col span={24}>
                    <div style={
                        {
                            backgroundColor: '#ffffff',
                            padding: '15px 20px 6px 20px',
                            borderRadius: '15px',
                            marginBottom: '20px'
                        }
                    }>
                        <Row>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Row>
                                    <Col>
                                        <Typography.Title level={3} type='secondary'>
                                            {getLabel(labels.workplanTemplate)}
                                        </Typography.Title>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                                <Row justify='end'>
                                    <Space direction='horizontal'>
                                        <Col>
                                            <Button type='default'
                                                style={
                                                    {
                                                        borderColor: '#70B450',
                                                        borderWidth: '2px',
                                                        color: '#70B450',
                                                        height: '35px'
                                                    }
                                                }
                                                onClick={() => downloadWorkplanTemplate()}
                                                loading={isDownloading}
                                                disabled={isDownloading}
                                            >
                                                {getLabel(labels.download)}
                                            </Button>
                                        </Col>
                                    </Space>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Col>
                {
                    isLoading ?
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Spin size='large' tip={
                                <Typography.Paragraph style={{ padding: '10px 0' }}>
                                    {getLabel(labels.loading)}...
                                </Typography.Paragraph>
                            } />
                        </Col>
                        :
                        <Col span={24}>
                            {
                                trainings && trainings.map(t => <Collapse
                                    key={t.idEntrenamiento}
                                    accordion
                                    bordered={false}
                                    expandIconPosition='end'
                                    style={{
                                        backgroundColor: 'transparent'
                                    }}
                                >
                                    {
                                        t.entId && t.entId.cursoList && t.entId.cursoList.map(c => renderCourse(t.idEntrenamiento, t.posId, c))
                                    }
                                </Collapse>
                                )
                            }
                        </Col>
                }
            </Row>
        </>
    )
}

export default WorkPlan