import React, { useEffect, useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import { Col, Row, Button, Result, Form, Input, Spin, Typography, Image, Space, Tabs, Badge, Card, Alert } from 'antd'

import { getLabel, labels } from '../../utils/Internationalization'

import UsuarioService from '../../service/UsuarioService'
// import PostulantService from '../../service/PostulantService'
import TrainingService from '../../service/TrainingService'
import toast from 'react-hot-toast'

const usuarioService = new UsuarioService()
const trainingService = new TrainingService()
// const postulantService = new PostulantService()

const UserInfo = () => {

    const navigate = useHistory()
    const { id } = useParams()
    const [load, setLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [usuario, setUsuario] = useState(null)
    const [trainings, setTrainings] = useState([])
    const [error, setError] = useState(null)

    const renderTraining = ({ entEstado, entFechaFin, entFechaIni, entId, entNombre }) =>
        <Card
            key={entId}
            bordered={false}
            style={{ marginBottom: '10px' }}

        >
            <Row>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ paddingTop: '4px' }}>
                    <Typography.Text strong>
                        {entNombre}
                    </Typography.Text>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'center', paddingTop: '4px' }}>
                    <Typography.Text strong>
                        <Badge color={entEstado.catValor === 'ACTIVE' ? '#54873C' : entEstado.catValor === 'INACTIVE' ? 'red' : '#636466'} text={entEstado.catNombre} />
                    </Typography.Text>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ paddingTop: '4px' }}>
                    <Typography.Text strong>
                        {entFechaIni.substring(0, 10).split('-').reverse().join('/')} - {entFechaFin.substring(0, 10).split('-').reverse().join('/')}
                    </Typography.Text>
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} xxl={6} style={{ textAlign: 'center' }}>
                    <Button type='primary'
                        onClick={() => {
                            navigate.push(`/traininginfo/${entId}`)
                        }}
                    >
                        {getLabel(labels.view)}
                    </Button>
                </Col>
            </Row>
        </Card>

    const fetchTrainings = async () => {
        const trainer = { idUsuario: id }
        // const postulant = { idUsuario: id }
        try {
            // const { data } = parseInt(profile) !== 5 ? await trainingService.getByTraining({ navigate, trainer }) : await postulantService.getTraining({ navigate, postulant })
            const { data } = await trainingService.getByTraining({ navigate, trainer })
            if (data) {
                setTrainings(data.listObject)
            } else {
                toast.error(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            const { response } = error
            toast.error(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        } finally {
            setIsLoading(false)
        }
    }

    const fetchData = async () => {
        setLoad(false)
        setError(null)
        setIsLoading(true)
        try {
            const { data } = await usuarioService.get({ navigate, usuario: { usuId: id } })
            if (data) {
                const usuario = data.listObject[0]
                const newUsuario = { ...usuario, fechanac: usuario.fechanac.substring(0, 10) }
                setUsuario(newUsuario)
                fetchTrainings()
            } else {
                setIsLoading(false)
                setError(getLabel(labels.errorOcurred))
            }
        } catch (error) {
            setIsLoading(false)
            const { response } = error
            setError(response.status === 409 ? response.data[0].messageText : getLabel(labels.errorOcurred))
        }
    }

    useEffect(() => {
        if (load) fetchData()
    })

    if (error) {
        return (
            <Result
                status="warning"
                title={''}
                subTitle={''}
                extra={
                    <>
                        <h5>{error}</h5>
                        <Link to={'/dashboard'}>{getLabel(labels.backHome)}</Link>
                    </>
                }
            />
        )
    }

    return (
        <>
            {
                isLoading ?
                    <Row style={{ padding: '30px 10px' }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Spin size='large' tip={
                                <Typography.Paragraph style={{ padding: '10px 0' }}>
                                    {getLabel(labels.loading)}...
                                </Typography.Paragraph>
                            } />
                        </Col>
                    </Row>
                    :
                    usuario && <>
                        <div className="grid">
                            <div className="col-12">
                                <div className="mgeCard">
                                    <div className="grid">
                                        <div className="col-12">
                                            <Space direction='horizontal'>
                                                <Button icon={<i className="fa-solid fa-chevron-left"></i>}
                                                    onClick={() => {
                                                        navigate.push(`/dashboard`)
                                                    }}
                                                ></Button>
                                                <h3 style={{ padding: '0', margin: '0' }} className='mge-darkGreen'>
                                                    {
                                                        `${usuario.nombres || 'Postulante'} ${usuario.apellidos || ''}`
                                                    }
                                                </h3>
                                            </Space>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Tabs centered items={[
                            {
                                key: 1,
                                label: <Typography.Text style={{ fontSize: '1.1em', letterSpacing: '1px' }}>{getLabel(labels.information)}</Typography.Text>,
                                children: <>
                                    <Row gutter={[25, 25]} justify='center'>
                                        <Col xs={22} sm={20} md={6} lg={6} xl={6} xxl={4}>
                                            <Row gutter={[15, 15]} style={{ textAlign: 'center' }}>
                                                <Col span={24}>
                                                    {
                                                        usuario.imagen ?
                                                            <Image src={
                                                                `/images/${usuario.imagen.split('/')[usuario.imagen.split('/').length - 1]}`
                                                            } width={128} height={128} />
                                                            :
                                                            <Image src='./../assets/photo_profile.png' width={128} height={128} />
                                                    }
                                                </Col>
                                                <Col xs={12} sm={12} md={24} lg={24} xl={24} xxl={24} style={{ textAlign: 'left' }}>
                                                    <Typography.Paragraph>
                                                        <Typography.Text strong>
                                                            {getLabel(labels.profile)}:
                                                        </Typography.Text>
                                                        <br />
                                                        {
                                                            usuario.perfiles && usuario.perfiles[0].perNombre
                                                        }
                                                    </Typography.Paragraph>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={22} sm={20} md={14} lg={14} xl={14} xxl={10}>
                                            <Form
                                                initialValues={usuario}
                                                layout='vertical'
                                            >
                                                <Row gutter={[25, 5]}>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='nombres'
                                                            label={getLabel(labels.firstName)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='apellidos'
                                                            label={getLabel(labels.lastName)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name={['sexo', 'catNombre']}
                                                            label={getLabel(labels.sex)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='fechanac'
                                                            label={getLabel(labels.dateBirth)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name={['pais', 'catNombre']}
                                                            label={getLabel(labels.country)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name={['ciudad']}
                                                            label={getLabel(labels.city)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='codigotel'
                                                            label={getLabel(labels.countryCode)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='celular'
                                                            label={getLabel(labels.phoneNumber)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='documento'
                                                            label={getLabel(labels.idNumber)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='lugartrabajo'
                                                            label={getLabel(labels.currentyWorking)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='email'
                                                            label={getLabel(labels.email)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={12}>
                                                        <Form.Item
                                                            name='email2'
                                                            label={getLabel(labels.additionalEmail)}
                                                        >
                                                            <Input readOnly />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Form>
                                        </Col>
                                    </Row>
                                </>
                            }, {
                                key: 2,
                                label: <Typography.Text style={{ fontSize: '1.1em', letterSpacing: '1px' }}>{getLabel(labels.trainings)}</Typography.Text>,
                                children: <>
                                    {
                                        trainings && trainings.length > 0 ?
                                            <>
                                                {
                                                    trainings.map(t => renderTraining(t))
                                                }
                                            </>
                                            :
                                            <Alert
                                                description={getLabel(labels.trainingsNotFound)}
                                                type="info"
                                                showIcon
                                                style={{ borderRadius: '12px', border: 'none' }}
                                            />
                                    }
                                </>
                            }]
                        }>
                        </Tabs>
                    </>
            }
        </>
    )
}

export default UserInfo