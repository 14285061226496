import React, { useEffect, useState } from "react";
import { Route, withRouter, useLocation } from "react-router-dom";
import App from "./App";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import RecuperarPassword from "./pages/RecuperarPassword";
import ResetPassword from "./pages/ResetPassword";

export const ctx = React.createContext();

const AppWrapper = (props) => {
  let location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [colorScheme, setColorScheme] = useState("light");

  switch (props.location.pathname) {
    case "/login":
      return (
        <Route
          path="/login"
          render={() => <Login colorScheme={colorScheme} />}
        />
      );
    case "/signup":
      return (
        <Route
          path="/signup"
          render={() => <Signup colorScheme={colorScheme} />}
        />
      );
    case "/recover":
      return (
        <Route
          path="/recover"
          render={() => <RecuperarPassword colorScheme={colorScheme} />}
        />
      );
    case "/reset":
      return (
        <Route
          path="/reset"
          render={() => <ResetPassword colorScheme={colorScheme} />}
        />
      );
    default:
      return <App setColorScheme={setColorScheme} />;
  }
};

export default withRouter(AppWrapper);
